import React, { Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';

function PackagesSwiper({ faqs }) {
  return (
    <div className="py-12 flex flex-col md:gap-12 gap-6">
      {faqs && Array.isArray(faqs) && faqs.length > 0 && (
        <Fragment>
          <div className="text-center">
            <div className="text-3xl font-bold">FAQs</div>
          </div>

          <div className=" rounded-md md:px-10 px-3 md:py-4 py-2">
            {faqs.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="text-md font-bold"
                >
                  {item.question}
                </AccordionSummary>
                <AccordionDetails className="text-sm">
                  {item.answer}
                </AccordionDetails>
              </Accordion>
            ))}

            <div className="flex justify-center mt-8">
              <Link
                to={routesName.faqs}
                className="text-sm text-[var(--pink)] border border-1 border-[var(--pink)] text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                Read More
              </Link>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default PackagesSwiper;
