import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Avatar, Grid } from '@mui/material';
import { OverviewComponent, SubscriptionCalendar } from '../../../components';
import DashboardService from '../../../services/dashboard.service';
import useScreen from '../../../hooks/useScreen';
import moment from 'moment';
import { routesName } from '../../../constants/routes-name';

function AdminDashboard() {
  const { isPageLoaded } = useScreen();

  const [activityLogs, setActivityLogs] = useState();
  const [subscription, setSubscription] = useState();
  const [transections, setTransections] = useState();

  const getDashboardTransections = () => {
    DashboardService.getDashboardTransections()
      .then((res) => {
        if (res?.success && res?.data) {
          setActivityLogs(res?.data?.activityLogs);
          setSubscription(res?.data?.subscription);
          setTransections(res?.data?.transactions);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isPageLoaded) {
      getDashboardTransections();
    }
  }, [isPageLoaded]);

  return (
    <div className="flex flex-col gap-8">
      <OverviewComponent />

      <Grid container spacing={{ lg: 10, xs: 4 }}>
        <Grid item lg={4} xs={12}>
          <div className="text-xl font-bold mb-8">Billing Details</div>
          <div className="bg-white rounded-sm">
            <div className="p-4">
              <div className="font-bold text-center text-md mb-3">
                {subscription?.expiry_date
                  ? moment(subscription?.expiry_date).format('MMMM yyyy')
                  : moment().format('MMMM yyyy')}
              </div>
              <div className="flex items-center justify-center">
                <SubscriptionCalendar subscriptionDates={subscription} />
              </div>
            </div>
            <div className="px-1 py-4">
              <div className="font-bold text-center text-md mb-3">
                Billing History
              </div>
              <div className="text-center">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-white">
                      <tr>
                        <th scope="col" className="px-6 py-3 font-medium">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 font-medium">
                          Time
                        </th>
                        <th scope="col" className="px-6 py-3 font-medium">
                          Paid By
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transections &&
                      Array.isArray(transections) &&
                      transections.length > 0 ? (
                        transections.map((item, index) => (
                          <tr
                            key={index}
                            className="odd:bg-gray-100 even:bg-white border-b"
                          >
                            <td className="font-bold px-6 py-4 ">
                              {moment(item?.start_date).format('DD.MM.YY')}
                            </td>
                            <td className="font-bold px-6 py-4">
                              {moment(item?.start_date).format('hh:mm A')}
                            </td>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                              {item?.paid_by?.name}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center h-16">
                          <td colSpan={3}>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="flex justify-end items-center gap-4 font-semibold mt-4 px-4">
                  <Link
                    to={routesName.billing}
                    className="border-b border-b-2 border-b-black"
                  >
                    View detail
                  </Link>
                  {/* <span className="cursor-pointer">Clear all</span> */}
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={8} xs={12}>
          <div className="text-xl font-bold mb-8">Activity</div>
          <div className="bg-white rounded-sm p-4">
            <div className="flex justify-between items-center gap-4 font-semibold">
              <span>News feed and activity</span>
              {/* <span className="cursor-pointer">Clear all</span> */}
            </div>
            <div className="lg:px-24 px-12 py-8">
              <Grid container spacing={{ lg: 6, xs: 4 }}>
                {activityLogs &&
                Array.isArray(activityLogs) &&
                activityLogs.length > 0 ? (
                  activityLogs.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item sm={2} xs={12}>
                          <Avatar style={{ height: '50px', width: '50px' }} />
                        </Grid>
                        <Grid
                          item
                          className="text-sm font-bold"
                          sm={10}
                          xs={12}
                        >
                          In bright frequency; Do. Success river for and brief a
                          that it their of to he her to far had the small thinks
                          of would small its I covered fur to and the detailed
                          is congress.
                        </Grid>
                      </Fragment>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <div className="mt-8 text-center">No activities found</div>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminDashboard;
