import API from "../config/api.config";


const getImageFile = (image_path) => {
    return API.get(`/getImage/${image_path}`)
        .then(res => {
            return res.data
        })
}

const FileService = {
    getImageFile
}

export default FileService
