import API from '../config/api.config';
import FormAPI from '../config/formApi.config';

const getEmployeesList = (company_id) => {
  return API.get(`/employee_list?company_id=${company_id}`).then((res) => {
    return res.data;
  });
};

const getEmployees = (pageNo) => {
  return API.get(`/employee?page=${pageNo}`).then((res) => {
    return res.data;
  });
};

const getEmployee = (id) => {
  return API.get(`/employee/${id}`).then((res) => {
    return res.data;
  });
};

const deleteEmployee = (id) => {
  return API.delete(`/employee/${id}`).then((res) => {
    return res.data;
  });
};

const changeEmployeeArchiveStatus = (id) => {
  return API.get(`/employee/changeArchiveStatus/${id}`).then((res) => {
    return res.data;
  });
};

const changeStatus = (id) => {
  return API.get(`/employee/changeStatus/${id}`).then((res) => {
    return res.data;
  });
};

const getEmployeeDetails = (id) => {
  return API.get(`/employee/employeeDetails/${id}`).then((res) => {
    return res.data;
  });
};

const updateEmployeeDetails = (id, data, formType) => {
  if (formType === 'form-data') {
    return FormAPI.post(`employee/updateEmployee/${id}`, data).then((res) => {
      return res.data;
    });
  }
  return API.post(`employee/updateEmployee/${id}`, data).then((res) => {
    return res.data;
  });
};

const saveEmployeeDetails = (data, formType) => {
  if (formType === 'form-data') {
    return FormAPI.post(`employee`, data).then((res) => {
      return res.data;
    });
  }
  return API.post(`employee`, data).then((res) => {
    return res.data;
  });
};

const getEmployeeInfo = (employee_id) => {
  return API.get(`employee_info/${employee_id}`).then((res) => {
    return res.data;
  });
};

const EmployeeService = {
  getEmployeeInfo,
  saveEmployeeDetails,
  updateEmployeeDetails,
  getEmployees,
  deleteEmployee,
  changeStatus,
  getEmployee,
  getEmployeeDetails,
  getEmployeesList,
  changeEmployeeArchiveStatus,
};

export default EmployeeService;
