import { createSlice } from '@reduxjs/toolkit';

const appDataSlice = createSlice({
  name: 'appData',
  initialState: {
    overallCounts: null,
  },
  reducers: {
    saveOverallCounts: (state, action) => {
      state.overallCounts = action.payload;
    },
  },
});

export const { saveOverallCounts } = appDataSlice.actions;

export default appDataSlice.reducer;
