import API from '../config/api.config';

const getBusinessCards = (pageNo) => {
  return API.get(`/business_card?page=${pageNo}`).then((res) => {
    return res.data;
  });
};

const changeStatus = (id) => {
  return API.get(`/business_card/changeStatus/${id}`).then((res) => {
    return res.data;
  });
};

const getCardDetails = (id) => {
  return API.get(`/business_card/${id}`).then((res) => {
    return res.data;
  });
};

const getPublicCardDetail = (guid) => {
  return API.get(`/card/${guid}`).then((res) => {
    return res.data;
  });
};

const deleteCard = (id) => {
  return API.delete(`/business_card/${id}`).then((res) => {
    return res.data;
  });
};

const updateCard = (id, data) => {
  return API.put(`/business_card/${id}`, data).then((res) => {
    return res.data;
  });
};

const saveCard = (id, data) => {
  return API.post(`/business_card/${id}`, data).then((res) => {
    return res.data;
  });
};

const CardService = {
  updateCard,
  getPublicCardDetail,
  getBusinessCards,
  changeStatus,
  getCardDetails,
  deleteCard,
  saveCard,
};

export default CardService;
