import API from '../config/api.config';
import FormAPI from '../config/formApi.config';

const saveCardDetail = (data) => {
  return API.post('/user/saveCard', data).then((res) => {
    return res.data;
  });
};

const getCardsDetails = (user_id) => {
  return API.get(`/getCardsDetails/${user_id}`).then((res) => {
    return res.data;
  });
};

const storeCard = (data) => {
  return API.post('/ccCard', data).then((res) => {
    return res.data;
  });
};

const editCard = (id) => {
  return API.get(`/ccCard/${id}`).then((res) => {
    return res.data;
  });
};

const updateCard = (id, data) => {
  return API.put(`/ccCard/${id}`, data).then((res) => {
    return res.data;
  });
};

const deleteCard = (id) => {
  return API.delete(`/ccCard/${id}`).then((res) => {
    return res.data;
  });
};

const makeCardDefault = (id, user_id) => {
  return API.get(`/ccCard/makeDefault/${id}/${user_id}`).then((res) => {
    return res.data;
  });
};

const subscribeUser = (data) => {
  return API.post('/subscribe', data).then((res) => {
    return res.data;
  });
};

const updateProfile = (id, data, formType) => {
  if (formType === 'form-data') {
    return FormAPI.post(`/user/updateProfile/${id}`, data).then((res) => {
      return res.data;
    });
  } else {
    return API.post(`/user/updateProfile/${id}`, data).then((res) => {
      return res.data;
    });
  }
};

const getUserInfo = (id) => {
  return API.get(`/user/getProfileInfo/${id}`).then((res) => {
    return res.data;
  });
};

const UserService = {
  getUserInfo,
  updateProfile,
  saveCardDetail,
  getCardsDetails,
  storeCard,
  editCard,
  updateCard,
  deleteCard,
  makeCardDefault,
  subscribeUser,
};

export default UserService;
