import { Check } from '@mui/icons-material';
import React from 'react';

function Component({
  index = 0,
  data = {},
  checked = false,
  onClick = () => {},
  interval = 1,
  selection = false,
}) {
  return (
    <div
      className={`flex flex-col gap-0 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] ${
        selection ? 'cursor-pointer' : ''
      }`}
      onClick={() =>
        selection && document.getElementById(`plan-${index}`).click()
      }
    >
      <div className="py-10 bg-[var(--dark-blue)]">
        <div className="text-center font-bold text-4xl text-white">
          {`${data.currency}`.toLocaleLowerCase() === 'usd'
            ? '$'
            : data.currency}
          {interval === 1 ? data.monthly_price : data.annually_price}
          {interval === 1 ? (
            <sub className="text-[12px]">/moth</sub>
          ) : (
            <sub className="text-[12px]">/year</sub>
          )}
        </div>
        <div className="text-center font-bold text-sm text-white mt-1">
          {data?.title}
        </div>
      </div>
      <div className="flex flex-col items-center py-8 gap-6 bg-white">
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <Check className="text-[var(--dark-blue)]" />
            <span className="text-[var(--gray)]">
              {' '}
              {data.no_of_cards === -1
                ? 'Unlimited'
                : data.no_of_cards} card <sub>/month</sub>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Check className="text-[var(--dark-blue)]" />
            <span className="text-[var(--gray)]">
              {data.no_of_templates === -1 ? 'Unlimited' : data.no_of_templates}{' '}
              templates <sub>/month</sub>
            </span>
          </div>
          <div className="flex items-center justify-center gap-2 text-xs font-bold">
            <span className="text-[var(--dark-blue)]">
              {data.annually_price !== 0 && data.monthly_price !== 0
                ? `${(
                    100 -
                    (data.annually_price * 100) / (data.monthly_price * 12)
                  ).toFixed(0)}   % off on yearly purchase`
                : `Trial version (1 card only)`}
            </span>
          </div>
        </div>

        {selection && (
          <div className="flex gap-2 items-center">
            <input
              checked={checked}
              id={`plan-${index}`}
              onClick={() => onClick(data.id)}
              type="radio"
              value=""
              name="default-radio"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
            />
            <div className="text-black">Select</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
