import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { routesName } from '../constants/routes-name';
import AuthService from '../services/auth.service';
import useNotification from '../hooks/useNotification';

function Component() {
  const navigate = useNavigate();
  const { errorNonification } = useNotification();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const verifyEmail = () => {
    const data = {
      email: params.email,
      verification_code: params.verificationCode,
    };
    AuthService.verifyAccount(data)
      .then((res) => {
        if (res.success === true) {
          navigate(routesName.login);
        }
      })
      .catch((error) => {
        errorNonification(
          error?.response?.data?.message || 'Something went wrong'
        );
        navigate(routesName.home);
      });
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    !isLoading && verifyEmail();
  }, [isLoading]);

  return (
    <div className="mt-12">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="text-3xl font-bold text-center">Please Wait</div>
        </Grid>
        <Grid item xs={12}>
          <div className="text-xl font-bold text-center">
            We are verifying your account
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Component;
