import React, { Fragment, useEffect, useState } from 'react';
import {
  InputMaskWrapper,
  StepperWrapper,
  TextFieldWrapper,
} from '../../components';
import { CircularProgress, Grid, LinearProgress } from '@mui/material';
import { GppBadOutlined, TaskAltOutlined } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/navigation';
import { Formik } from 'formik';
import * as yup from 'yup';
import { PositiveNumberRegex } from '../../constants/constant-values';
import { validationSchema } from '../../constants/validationSchema';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PackageService from '../../services/package.service';
import useScreen from '../../hooks/useScreen';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { user } = useSelector(({ user }) => user);

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const steps = ['Billing info', 'Summary'];
  const [activeTab, setActiveTab] = useState(0);
  const { isPageLoaded } = useScreen();
  const navigate = useNavigate();

  const params = useParams();

  const [isNoOfCardsUnlimited, setIsNoOfCardsUnlimited] = useState(false);
  const [isNoOfTemplatesUnlimited, setIsNoOfTemplatesUnlimited] =
    useState(false);
  const [responseData, setResponseData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPackage, setIsLoadingPackage] = useState(!!params.id);
  const [title, setTitle] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [discountType, setDiscountType] = useState('percent');
  const [percentDiscount, setPercentDiscount] = useState(0);
  const [priceDiscount, setPriceDiscount] = useState(0);
  const [noOfCards, setNoOfCards] = useState(10);
  const [noOfTemplates, setNoOfTemplates] = useState(0);
  const [currency] = useState('usd');
  const [maxDiscount] = useState(100);

  const submitPackage = () => {
    setIsLoading(true);

    const price = Number(percentDiscount)
      ? (Number(monthlyPrice) * Number(percentDiscount)) / 100
      : 0;
    const percentAnnualPrice = 12 * (Number(monthlyPrice) - Number(price));
    const priceAnnualPrice =
      12 * (Number(monthlyPrice) - Number(priceDiscount));

    const data = {
      title: title,
      monthly_price: monthlyPrice,
      discount_type: discountType,
      percent_discount: percentDiscount,
      price_discount: priceDiscount,
      annually_price:
        discountType == 'percent' ? percentAnnualPrice : priceAnnualPrice,
      no_of_cards: isNoOfCardsUnlimited ? -1 : noOfCards,
      no_of_templates: isNoOfTemplatesUnlimited ? -1 : noOfTemplates,
      currency: currency,
    };

    if (params.id) {
      PackageService.updatePackage(params.id, data)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Package has been updated successfully',
            });
            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    } else {
      PackageService.storePackage(data)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Package has been added successfully',
            });
            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (activeTab === 1) {
      submitPackage();
    }
  }, [activeTab]);

  const getPackage = () => {
    PackageService.getPackage(params.id)
      .then((res) => {
        if (res.success) {
          const data = res.data;
          setTitle(data?.title);
          setMonthlyPrice(data?.monthly_price);
          setNoOfCards(data?.no_of_cards);
          setNoOfTemplates(data?.no_of_templates);
          setIsNoOfCardsUnlimited(data?.no_of_cards === -1);
          setIsNoOfTemplatesUnlimited(data?.no_of_templates === -1);
          setDiscountType(data?.discount_type || 'percent');
          setPriceDiscount(data?.price_discount || 0);
          setPercentDiscount(data?.percent_discount || 0);
        }
        setIsLoadingPackage(false);
      })
      .catch((error) => {
        navigate(routesName['404']);
      });
  };

  useEffect(() => {
    if (isPageLoaded && params.id) {
      getPackage();
    }
  }, [isPageLoaded]);

  return (
    <div>
      <div className="text-2xl">
        {params.id ? 'Package Altering' : 'Adding new package'}
      </div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>

        {!isLoadingPackage ? (
          <div>
            {activeTab === 0 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    name: title,
                    monthlyPrice: monthlyPrice,
                    discountType: discountType,
                    discountPercentage: percentDiscount,
                    discountPrice: priceDiscount,
                    noOfCards: noOfCards,
                    noOfTemplates: noOfTemplates,
                  }}
                  validationSchema={yup.object().shape({
                    name: validationSchema.name,
                    monthlyPrice: validationSchema.required('Monthaly Price'),
                    noOfCards: !isNoOfCardsUnlimited
                      ? validationSchema.minRequired('No 0f cards', 0)
                      : validationSchema.nullable,
                    noOfTemplates: !isNoOfTemplatesUnlimited
                      ? validationSchema.minRequired('No of Templates', 0)
                      : validationSchema.nullable,
                    isNoOfCardsUnlimited: validationSchema.nullable,
                    isNoOfTemplatesUnlimited: validationSchema.nullable,
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setTitle(values.name);
                      setMonthlyPrice(values.monthlyPrice);
                      setDiscountType(values.discountType);
                      setPercentDiscount(values.discountPercentage);
                      setPriceDiscount(values.discountPrice);
                      setNoOfCards(values.noOfCards);
                      setNoOfTemplates(values.noOfTemplates);
                      setActiveTab(1);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    validateField,
                    setFieldError,
                  }) => {
                    return (
                      <div>
                        <Grid container spacing={4}>
                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Name <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                helperText={touched.name && errors.name}
                                error={!!touched.name && !!errors.name}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                MONTHLY PICE{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="monthlyPrice"
                                onChange={(e) => {
                                  if (
                                    PositiveNumberRegex.test(e.target.value)
                                  ) {
                                    setFieldValue(
                                      'monthlyPrice',
                                      e.target.value || ''
                                    );

                                    if (
                                      e.target.value &&
                                      values.discountPrice &&
                                      Number(e.target.value) <
                                        Number(values.discountPrice)
                                    ) {
                                      setFieldValue('discountPrice', '');
                                    }
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.monthlyPrice}
                                helperText={
                                  touched.monthlyPrice && errors.monthlyPrice
                                }
                                error={
                                  !!touched.monthlyPrice &&
                                  !!errors.monthlyPrice
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                DISCOUNT % (Optional)
                              </label>
                              <div className="flex">
                                <span
                                  onClick={() =>
                                    setFieldValue('discountType', 'percent')
                                  }
                                  className="inline-flex items-center px-3 text-sm bg-[var(--yellow)] border rounded-e-0 border-gray-300 border-e-0 rounded-s-md"
                                >
                                  <div className="flex gap-1 items-center">
                                    <input
                                      className="mr-2"
                                      name="discountType"
                                      type="radio"
                                      value="percent"
                                      checked={
                                        values.discountType === 'percent'
                                      }
                                    />
                                    <label
                                      style={{ userSelect: 'none' }}
                                      className="text-nowrap"
                                    >
                                      On Percentage
                                    </label>
                                  </div>
                                </span>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="discountPercentage"
                                  onChange={(e) => {
                                    if (
                                      PositiveNumberRegex.test(e.target.value)
                                    ) {
                                      e.target.value <= maxDiscount &&
                                        e.target.value >= 0 &&
                                        setFieldValue(
                                          'discountPercentage',
                                          e.target.value || ''
                                        );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.discountPercentage}
                                  helperText={
                                    touched.discountPercentage &&
                                    errors.discountPercentage
                                  }
                                  error={
                                    !!touched.discountPercentage &&
                                    !!errors.discountPercentage
                                  }
                                />
                              </div>
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                DISCOUNT Price (Optional)
                              </label>
                              <div className="flex">
                                <span
                                  onClick={() =>
                                    setFieldValue('discountType', 'price')
                                  }
                                  className="inline-flex items-center px-3 text-sm bg-[var(--yellow)] border rounded-e-0 border-gray-300 border-e-0 rounded-s-md"
                                >
                                  <div className="flex gap-1 items-center">
                                    <input
                                      className="mr-2"
                                      name="discountType"
                                      type="radio"
                                      value="price"
                                      checked={values.discountType === 'price'}
                                    />
                                    <label
                                      style={{ userSelect: 'none' }}
                                      className="text-nowrap"
                                    >
                                      On Price
                                    </label>
                                  </div>
                                </span>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="discountPrice"
                                  onChange={(e) => {
                                    if (
                                      PositiveNumberRegex.test(e.target.value)
                                    ) {
                                      e.target.value <=
                                        Number(values.monthlyPrice) &&
                                        e.target.value >= 0 &&
                                        setFieldValue(
                                          'discountPrice',
                                          e.target.value || ''
                                        );
                                    }
                                  }}
                                  value={values.discountPrice}
                                  helperText={
                                    touched.discountPrice &&
                                    errors.discountPrice
                                  }
                                  error={
                                    !!touched.discountPrice &&
                                    !!errors.discountPrice
                                  }
                                />
                              </div>
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                NO OF CARDS{' '}
                                <span className="text-red-500">*</span>
                              </label>

                              <div className="flex">
                                <label
                                  htmlFor="isNoOfCardsUnlimited"
                                  className="inline-flex items-center px-3 text-sm bg-[var(--yellow)] border rounded-e-0 border-gray-300 border-e-0 rounded-s-md"
                                >
                                  <div className="flex gap-1 items-center">
                                    <input
                                      className="mr-2"
                                      id="isNoOfCardsUnlimited"
                                      name="isNoOfCardsUnlimited"
                                      type="checkbox"
                                      checked={isNoOfCardsUnlimited}
                                      onChange={(e) => {
                                        setIsNoOfCardsUnlimited(
                                          e.target.checked
                                        );
                                        setFieldError('noOfCards', '');
                                      }}
                                    />
                                    <span
                                      style={{ userSelect: 'none' }}
                                      className="text-nowrap"
                                    >
                                      No Limit
                                    </span>
                                  </div>
                                </label>

                                <InputMaskWrapper
                                  mask="9999999999"
                                  maskChar=""
                                  placeholder="Placeholder"
                                  disabled={isNoOfCardsUnlimited}
                                  name="noOfCards"
                                  id="noOfCards"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.noOfCards}
                                  error={
                                    !!touched.noOfCards && !!errors.noOfCards
                                  }
                                />
                              </div>
                              {touched.noOfCards && errors.noOfCards && (
                                <span className="text-[14px] text-red-600">
                                  {touched.noOfCards && errors.noOfCards}
                                </span>
                              )}
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                NO OF TEMPLATES{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="flex">
                                <label
                                  htmlFor="isNoOfTemplatesUnlimited"
                                  className="inline-flex items-center px-3 text-sm bg-[var(--yellow)] border rounded-e-0 border-gray-300 border-e-0 rounded-s-md"
                                >
                                  <div className="flex gap-1 items-center">
                                    <input
                                      className="mr-2"
                                      id="isNoOfTemplatesUnlimited"
                                      type="checkbox"
                                      checked={isNoOfTemplatesUnlimited}
                                      onChange={(e) => {
                                        setIsNoOfTemplatesUnlimited(
                                          e.target.checked
                                        );
                                        setFieldError('noOfTemplates', '');
                                      }}
                                    />
                                    <span
                                      style={{ userSelect: 'none' }}
                                      className="text-nowrap"
                                    >
                                      No Limit
                                    </span>
                                  </div>
                                </label>

                                <InputMaskWrapper
                                  mask="9999999999"
                                  maskChar=""
                                  placeholder="Placeholder"
                                  disabled={isNoOfTemplatesUnlimited}
                                  name="noOfTemplates"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.noOfTemplates}
                                  error={
                                    !!touched.noOfTemplates &&
                                    !!errors.noOfTemplates
                                  }
                                />
                              </div>
                              {touched.noOfTemplates &&
                                errors.noOfTemplates && (
                                  <span className="text-[14px] text-red-600">
                                    {touched.noOfTemplates &&
                                      errors.noOfTemplates}
                                  </span>
                                )}
                            </div>
                          </Grid>

                          {/* <Grid item md={4} sm={6} xs={12}>
                        <div className="flex flex-col gap-2">
                          <label className="text-xs">
                            CURRENCY <span className="text-red-500">*</span>
                          </label>
                          <TextFieldWrapper placeholder="Placeholder" />
                        </div>
                      </Grid> */}
                        </Grid>

                        <div className="flex items-center justify-end mt-8">
                          <button
                            onClick={handleSubmit}
                            className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}

            {activeTab === 1 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                  {isLoading ? (
                    <CircularProgress />
                  ) : responseData?.status ? (
                    <Fragment>
                      <TaskAltOutlined
                        className="text-[var(--yellow)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  ) : (
                    !responseData?.status &&
                    responseData?.message && (
                      <Fragment>
                        <GppBadOutlined
                          className="text-[var(--pink)]"
                          sx={{ fontSize: '5rem' }}
                        />
                        <div className="text-[var(--pink)] font-bold text-2xl text-center">
                          {responseData?.message}
                        </div>
                      </Fragment>
                    )
                  )}
                </div>

                {!isLoading && !responseData?.status && (
                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={() => {
                        setResponseData(null);
                        setActiveTab(0);
                      }}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
