import { BrowserRouter } from 'react-router-dom';
import './App.css';
import ApplicationRouter from './routes/ApplicationRouter';
import { AuthProvider } from './auth-provider/AuthProvider';
import { useSelector } from 'react-redux';
import { routesName } from './constants/routes-name';
import Authorization from './auth-provider/Authorization';
import { SnackbarProvider } from 'notistack';

function App() {
  const { user } = useSelector(({ user }) => user);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Authorization userRole={user?.name} loginRedirectUrl={routesName.home}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            classes={{
              containerRoot: 'bottom-0 right-0 mb-1 md:mb-8 mx-1 lg:mr-8 z-99',
            }}
          >
            <ApplicationRouter />
          </SnackbarProvider>
        </Authorization>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
