import React from 'react';

function Component({
  className = '',
  style = {},
  title = '',
  count = '',
  link = '',
  linkText = '',
}) {
  return (
    <div
      className={`p-4 rounded-sm bg-white flex flex-col gap-4 ${className}`}
      style={{ ...style }}
    >
      <div className="text-xs font-bold text-start">{title}</div>

      <div className="text-4xl text-secondary font-bold text-center">
        {count}
      </div>

      <div className="text-xs font-bold text-end text-main">
        <a
          href={link}
          className="border border-b-2 border-b-blue-500 border-dotted"
        >
          {linkText || link}
        </a>
      </div>
    </div>
  );
}

export default Component;
