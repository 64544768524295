import API from "../config/api.config";


const getNotificationsOfCompany = (company_id) => {
    return API.get(`/notification/${company_id}`).then((res) => {
        return res.data;
    });
};


const changeStatus = (id) => {
    return API.patch(`/notification/${id}`).then((res) => {
        return res.data;
    });
};


const NotificationService = {
    getNotificationsOfCompany,
    changeStatus
}

export default NotificationService;