import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const BootstrapInput = styled(TextField)(({ theme }) => ({
  '& .Mui-error fieldset': {
    borderColor: 'var(--error) !important',
    borderWidth: '2px !important',
  },
  '& .Mui-error': {
    color: 'var(--error)',
    fontSize: '15px',
  },
  '& .MuiInputBase-root': {
    backgroundColor: '#fff !important',
    padding: '.08rem .75rem',
    width: '100%',
    '&.Mui-focused fieldset': {
      border: '0.3px solid #80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.29)',
    },
    '&:hover fieldset': {
      borderColor: '#ced4da',
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 'none',
    width: '',
    padding: '.375rem 0rem',
    lineHeight: 1.5,
    fontZize: '1rem',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}));

const Component = (props) => {
  return <BootstrapInput {...props} />;
};
Component.prototype = {
  ...TextField.propTypes, // Reuse prop types from TextField
  // You can add custom prop types here if needed
};

export default Component;
