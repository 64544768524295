import React, { Fragment, useEffect, useState } from 'react';
import {
  CardPreview,
  CardTemplates,
  DragDropImage,
  InputMaskWrapper,
  StepperWrapper,
  TextFieldWrapper,
} from '../../components';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
} from '@mui/material';
import {
  Close,
  GppBadOutlined,
  TaskAltOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { selectCardImage } from '../../assets';
import 'swiper/css';
import 'swiper/css/navigation';
import { Formik } from 'formik';
import { validationSchema } from '../../constants/validationSchema';
import * as yup from 'yup';
import useScreen from '../../hooks/useScreen';
import LocationService from '../../services/location.service';
import EmployeeService from '../../services/employee.service';
import { useSelector } from 'react-redux';
import { routesName } from '../../constants/routes-name';
import useNotification from '../../hooks/useNotification';
import { Navigate, useNavigate } from 'react-router-dom';
import CompanyService from '../../services/company.service';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (isUser || isCompany) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { user, isCompany } = useSelector(({ user }) => user);

  const { errorNonification } = useNotification();
  const navigate = useNavigate();

  const steps = [
    'Upload Image',
    'Employee Information',
    'Select Template',
    'Summary',
  ];

  const [activeTab, setActiveTab] = useState(0);
  const { isPageLoaded } = useScreen();
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState(null);
  const [companyDetail, setCompanyDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const [lightLogoFile, setLightLogoFile] = useState(null);
  const [lightLogoUrl, setLightLogoUrl] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cityID, setCityID] = useState('');
  const [countryID, setCountryID] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [designation, setDesignation] = useState('');
  const [selfDescription, setSelfDescription] = useState('');
  const [template, setTemplate] = useState(null);
  const [roleID] = useState(4);
  const [status] = useState(1);

  const geCountries = () => {
    LocationService.getCountries().then((res) => {
      setCountryList(res.data);
    });
  };

  const getCitiesByCountry = (value) => {
    if (value) {
      LocationService.getCitiesByCountry(value).then((res) => {
        setCityList(res.data);
      });
    } else setCityList(null);
  };

  const hasCompany = () => {
    setIsLoading(true);
    CompanyService.hasCompanyRegistered(user.id)
      .then((res) => {
        setIsLoading(false);
        setCompanyDetail(res.data);
        if (!res.data) {
          if (localStorage.getItem('company') === 'null') {
            errorNonification('Company not registered yet.');
            return navigate(routesName.createCompany);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      hasCompany();
      geCountries();
    }
  }, [isPageLoaded]);

  const registerEmployee = () => {
    setIsLoading(true);

    const formObj = {
      employeeGeneral: {
        name: name,
        email: email,
        password: password,
        phone: phone,
        role_id: roleID,
        city_id: cityID,
        country_id: countryID,
        street_address: streetAddress,
        status: status,
      },
      employeeSpecifics: {
        self_description: selfDescription,
        designation: designation,
        //facebook_url: facebookUrl,
        //twitter_link: twitterLink,
        //instagram_username: instagramUsername,
        //skype_id: skypeID,
        company_id: isCompany ? user.id : companyDetail.user_id,
      },
      template_id: template?.id,
    };

    let formType;
    if (lightLogoFile) {
      formType = 'form-data';
      formObj.employeeGeneral.photo = lightLogoFile;
    }

    setIsLoading(true);

    EmployeeService.saveEmployeeDetails(formObj, formType)
      .then((res) => {
        if (res.success) {
          setResponseData({
            status: true,
            message: 'Epmloyee has been added successfully',
          });
          setTimeout(() => navigate(-1), 1000);
        } else {
          setResponseData({ status: false, message: 'Something went wrong' });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setResponseData({
          status: false,
          message: error?.response?.data?.message || 'Something went wrong',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (activeTab === 3) {
      registerEmployee();
    }
  }, [activeTab]);

  return (
    <div>
      <div className="text-2xl">Adding new employee</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>
        {activeTab === 0 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <Formik
              initialValues={{
                lightLogoFile: lightLogoFile,
                lightLogoUrl: lightLogoUrl,
              }}
              validationSchema={yup.object().shape({
                lightLogoFile: validationSchema.image,
              })}
              onSubmit={(values, actions) => {
                try {
                  setLightLogoFile(values.lightLogoFile);
                  setLightLogoUrl(values.lightLogoUrl);
                  setActiveTab(1);
                } catch (error) {}
              }}
            >
              {({
                values,
                errors,
                setFieldValue,
                handleSubmit,
                handleBlur,
              }) => (
                <div>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <DragDropImage
                        error={errors.lightLogoFile}
                        onBlur={handleBlur}
                        name="lightLogoFile"
                        onChangeImage={(file) => {
                          if (file) {
                            setFieldValue('lightLogoFile', file);
                            setFieldValue(
                              'lightLogoUrl',
                              URL.createObjectURL(file)
                            );
                          }
                        }}
                        preview={values.lightLogoUrl}
                        title="Profile picture"
                        cropable={true}
                      />
                    </Grid>
                  </Grid>
                  <div className="flex items-center justify-end mt-8">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        )}

        {activeTab === 1 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <Formik
              initialValues={{
                name: name,
                designation: designation,
                phone: phone,
                email: email,
                password: password,
                showPassword: false,
                confirmPassword: confirmPassword,
                showConfirmPassword: false,
                address: streetAddress,
                country: countryID,
                city: cityID,
                selfDescription: selfDescription,
              }}
              validationSchema={yup.object().shape({
                name: validationSchema.name,
                designation: validationSchema.required('Designation'),
                phone: validationSchema.required('Phone'),
                email: validationSchema.email,
                password: validationSchema.password,
                confirmPassword: validationSchema.confirmPassword,
                address: validationSchema.required('Address'),
              })}
              onSubmit={(values, actions) => {
                try {
                  setName(values.name);
                  setDesignation(values.designation);
                  setPhone(values.phone);
                  setEmail(values.email);
                  setSelfDescription(values.selfDescription);
                  setStreetAddress(values.address);
                  setCityID(values.city);
                  setCountryID(values.country);
                  setPassword(values.password);
                  setConfirmPassword(values.confirmPassword);
                  setActiveTab(2);
                } catch (error) {}
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                const goPreviousTab = () => {
                  setName(values.name);
                  setDesignation(values.designation);
                  setPhone(values.phone);
                  setEmail(values.email);
                  setSelfDescription(values.selfDescription);
                  setStreetAddress(values.address);
                  setCityID(values.city);
                  setCountryID(values.country);
                  setPassword(values.password);
                  setConfirmPassword(values.confirmPassword);
                  setActiveTab(0);
                };
                return (
                  <div>
                    <Grid container spacing={4}>
                      <Grid item xs={12} container spacing={4}>
                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Name <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              helperText={touched.name && errors.name}
                              error={!!touched.name && !!errors.name}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              DESIGNATION{' '}
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="designation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.designation}
                              helperText={
                                touched.designation && errors.designation
                              }
                              error={
                                !!touched.designation && !!errors.designation
                              }
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Phone <span className="text-red-500">*</span>
                            </label>
                            <InputMaskWrapper
                              mask="9999999999999999"
                              maskChar=""
                              placeholder="Placeholder"
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              helperText={touched.phone && errors.phone}
                              error={!!touched.phone && !!errors.phone}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Email
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              helperText={touched.email && errors.email}
                              error={!!touched.email && !!errors.email}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Password<span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              helperText={touched.password && errors.password}
                              error={!!touched.password && !!errors.password}
                              type={values.showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'showPassword',
                                        !values.showPassword
                                      )
                                    }
                                  >
                                    {values.showPassword ? (
                                      <VisibilityOutlined fontSize="small" />
                                    ) : (
                                      <VisibilityOffOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                ),
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Re-enter Password
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={
                                !!touched.confirmPassword &&
                                !!errors.confirmPassword
                              }
                              type={
                                values.showConfirmPassword ? 'text' : 'password'
                              }
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'showConfirmPassword',
                                        !values.showConfirmPassword
                                      )
                                    }
                                  >
                                    {values.showConfirmPassword ? (
                                      <VisibilityOutlined fontSize="small" />
                                    ) : (
                                      <VisibilityOffOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                ),
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Street Address{' '}
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                              helperText={touched.address && errors.address}
                              error={!!touched.address && !!errors.address}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Country (Optional)
                            </label>
                            <TextFieldWrapper
                              select
                              placeholder="Placeholder"
                              name="country"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('city', '');
                                getCitiesByCountry(e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.country}
                              helperText={touched.country && errors.country}
                              error={!!touched.country && !!errors.country}
                            >
                              <MenuItem value={''}>Select</MenuItem>
                              {countryList &&
                                Array.isArray(countryList) &&
                                countryList.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </TextFieldWrapper>
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">City (Optional)</label>
                            <TextFieldWrapper
                              select
                              disabled={!!!cityList}
                              placeholder="Placeholder"
                              name="city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                              helperText={touched.city && errors.city}
                              error={!!touched.city && !!errors.city}
                            >
                              <MenuItem value={''}>Select</MenuItem>
                              {cityList &&
                                Array.isArray(cityList) &&
                                cityList.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </TextFieldWrapper>
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              SELF SHORT DESCRIPTION (OPTIONAL)
                            </label>
                            <TextFieldWrapper
                              multiline
                              minRows={3}
                              placeholder="Placeholder"
                              name="selfDescription"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.selfDescription}
                              helperText={
                                touched.selfDescription &&
                                errors.selfDescription
                              }
                              error={
                                !!touched.selfDescription &&
                                !!errors.selfDescription
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className="flex items-center justify-between mt-8">
                      <button
                        onClick={goPreviousTab}
                        className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}

        {activeTab === 2 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <Formik
              initialValues={{ template: template, isOpenModal: false }}
              validationSchema={yup.object().shape({
                template: yup.object().required('Template'),
              })}
              onSubmit={(values, actions) => {
                try {
                  setTemplate(values.template);
                  setActiveTab(3);
                } catch (error) {}
              }}
            >
              {({ values, setFieldValue, handleSubmit, errors, touched }) => {
                const goPreviousTab = () => {
                  setTemplate(values.template);
                  setActiveTab(1);
                };

                const toggleModal = () => {
                  setFieldValue('isOpenModal', !values.isOpenModal);
                };

                const setSelected = (id) => {
                  setFieldValue('template', id);
                };
                return (
                  <div>
                    {errors?.template && touched?.template && (
                      <div className="text-sm text-red-600 px-4 py-1 bg-red-100 mb-2">
                        {errors?.template}
                      </div>
                    )}
                    <div className="flex justify-center">
                      {!values?.template ? (
                        <img
                          onClick={toggleModal}
                          className="cursor-pointer"
                          alt="card"
                          src={selectCardImage}
                        />
                      ) : (
                        <div className="overflow-x-auto">
                          <CardPreview
                            setIsLoadPreview={() => {}}
                            isLoadPreview={true}
                            cardBackground={values?.template.card_background}
                            backgroundImage={values?.template.background_image}
                            cardComponent={values?.template.card_component}
                            cardData={values?.template}
                            className="border shadow"
                            orientation={values?.template.orientation}
                          />
                          <div className="flex justify-center mt-2">
                            <button
                              onClick={toggleModal}
                              className="bg-[var(--yellow)] text-sm font-bold px-4 py-2 rounded-md"
                            >
                              Change Template
                            </button>
                          </div>
                        </div>
                      )}

                      <Modal
                        open={values.isOpenModal}
                        onClose={() => toggleModal()}
                      >
                        <Box sx={style} className="md:p-4 p-2">
                          <div className="flex justify-between items-center gap-2">
                            <div className="text-xl font-bold">Templates</div>
                            <IconButton onClick={toggleModal} size="small">
                              <Close />
                            </IconButton>
                          </div>
                          <CardTemplates
                            selected={values.template}
                            setSelected={setSelected}
                          />
                        </Box>
                      </Modal>
                    </div>

                    {/*  <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {[1, 2, 3, 4].map((item, index) => {
                    return (
                      <SwiperSlide>
                        <div className="flex justify-center">
                          <img alt="card" src={faceCardImage} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper> */}

                    <div className="flex items-center justify-between mt-8">
                      <button
                        onClick={goPreviousTab}
                        className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Back
                      </button>

                      <button
                        onClick={handleSubmit}
                        className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}

        {activeTab === 3 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <div className="min-h-96 flex items-center justify-center flex-col gap-5">
              {isLoading ? (
                <CircularProgress />
              ) : responseData?.status ? (
                <Fragment>
                  <TaskAltOutlined
                    className="text-[var(--yellow)]"
                    sx={{ fontSize: '5rem' }}
                  />
                  <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                    {responseData?.message}
                  </div>
                </Fragment>
              ) : (
                !responseData?.status &&
                responseData?.message && (
                  <Fragment>
                    <GppBadOutlined
                      className="text-[var(--pink)]"
                      sx={{ fontSize: '5rem' }}
                    />
                    <div className="text-[var(--pink)] font-bold text-2xl text-center">
                      {responseData?.message}
                    </div>
                  </Fragment>
                )
              )}
            </div>

            {!isLoading && !responseData?.status && (
              <div className="flex items-center justify-between mt-8">
                <button
                  onClick={() => {
                    setResponseData(null);
                    setActiveTab(2);
                  }}
                  className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                >
                  Back
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  width: { lg: '60%', md: '50%', xs: '95%' },
  height: '95%',
  bgcolor: 'var(--yellow)',
  borderRadius: '10px',
  boxShadow: 24,
  p: { md: 4, xs: 2 },
  overflowY: 'auto',
};
