import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { TextFieldWrapper } from '../components';
import { Link } from 'react-router-dom';
import { routesName } from '../constants/routes-name';
import { Formik } from 'formik';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import StorageService from '../services/storage.service';
import AuthService from '../services/auth.service';
import useNotification from '../hooks/useNotification';

function Component() {
  const [isLoading, setIsLoading] = useState(false);
  const { successNonification, errorNonification } = useNotification();

  const login = (data) => {
    setIsLoading(true);
    AuthService.login(data)
      .then(async (res) => {
        if (res.success === true) {
          StorageService.setToken(res.data.token);
          successNonification('Login Successfull');
          window.location.reload(true);
        } else {
          errorNonification(res?.message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        errorNonification(
          error?.response?.data?.message || 'Something went wrong.'
        );
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-12">
      <Formik
        initialValues={{
          email: '',
          password: '',
          showPassword: false,
        }}
        onSubmit={(values, actions) => {
          login({ email: values.email, password: values.password });
          try {
          } catch (error) {}
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-3xl font-bold text-center">Sign in</div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <label className="text-xs">Email</label>
                <TextFieldWrapper
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <label className="text-xs">Password</label>{' '}
                  <Link
                    className="text-[12px] text-[var(--yellow)]"
                    to={routesName.forgotPassword}
                    state={{ paramEmail: values.email }}
                  >
                    Forgot Password
                  </Link>
                </div>
                <TextFieldWrapper
                  placeholder="Placeholder"
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  type={values.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setFieldValue('showPassword', !values.showPassword)
                        }
                      >
                        {values.showPassword ? (
                          <VisibilityOutlined fontSize="small" />
                        ) : (
                          <VisibilityOffOutlined fontSize="small" />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <button
                disabled={isLoading}
                onClick={handleSubmit}
                className="w-full text-md border border-white hover:bg-white text-white hover:text-black border border-1 border-[var(--blue)] text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                {!isLoading ? 'Sign in' : 'Signing in'}
              </button>
            </Grid>

            <Grid item xs={12}>
              <div className="text-xs">
                <span>New on our platform?</span>{' '}
                <Link className="text-[var(--yellow)]" to={routesName.signup}>
                  Create an account
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
}

export default Component;
