import React, { useEffect, useState } from 'react';
import AmazingFeatures from './components/AmazingFeatures';
import CountsComponent from './components/CountsComponent';
import PackagesSwiper from './components/PackagesSwiper';
import DefaultCardsGallery from './components/DefaultCardsGallery';
import TopFAQs from './components/TopFAQs';
import DashboardService from '../services/dashboard.service';
import useScreen from '../hooks/useScreen';

function Component() {
  const { isPageLoaded } = useScreen();
  const [counts, setCounts] = useState();
  const [faqs, setFaqs] = useState();
  const [landscapeTemplates, setLandscapeTemplates] = useState();
  const [packages, setPackages] = useState();
  const [verticalTemplates, setVerticalTemplates] = useState();

  const getLandingPageData = () => {
    DashboardService.getLandingPageData()
      .then((res) => {
        if (res) {
          setCounts(res?.counts);
          setFaqs(res?.faqs);
          setLandscapeTemplates(res?.landscape_templates);
          setPackages(res?.packages);
          setVerticalTemplates(res?.vertical_templates);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isPageLoaded) {
      getLandingPageData();
    }
  }, [isPageLoaded]);

  return (
    <div className="container mx-auto px-4 flex flex-col gap-8">
      <DefaultCardsGallery
        verticalTemplates={verticalTemplates}
        landscapeTemplates={landscapeTemplates}
      />

      <AmazingFeatures />

      <CountsComponent counts={counts} />

      <PackagesSwiper packages={packages} />

      <TopFAQs faqs={faqs} />
    </div>
  );
}

export default Component;
