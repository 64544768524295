import React, { Fragment, useEffect, useState } from 'react';
import {
  DragDropImage,
  InputMaskWrapper,
  PricingComponent,
  PricingComponentSelect,
  StepperWrapper,
  SwitchWrapper,
  TextFieldWrapper,
} from '../../components';
import { CircularProgress, Grid, IconButton, MenuItem } from '@mui/material';
import {
  GppBadOutlined,
  Lock,
  TaskAltOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  americanexpressIcon,
  applepayIcon,
  mastercardIcon,
  paypalIcon,
  visaIcon,
} from '../../assets';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../constants/validationSchema';
import LocationService from '../../services/location.service';
import PackageService from '../../services/package.service';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import CompanyService from '../../services/company.service';
import useNotification from '../../hooks/useNotification';
import { useSelector } from 'react-redux';
import useScreen from '../../hooks/useScreen';
import { routesName } from '../../constants/routes-name';
import { Navigate, useNavigate } from 'react-router-dom';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (isUser && !company) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const steps = [
    'Upload Logos',
    'Business Information',
    'Subscription Plan',
    'Billing Details',
    'Summary',
  ];

  const { isPageLoaded } = useScreen();

  const { user } = useSelector(({ user }) => user);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState(null);
  const [packagesList, setPackagesList] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [interval, setInterval] = useState(1);

  const [lightLogoFile, setLightLogoFile] = useState(null);
  const [lightLogoUrl, setLightLogoUrl] = useState(null);
  const [darkLogoFile, setDarkLogoFile] = useState(null);
  const [darkLogoUrl, setDarkLogoUrl] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [roleID] = useState(3);
  const [cityID, setCityID] = useState('');
  const [countryID, setCountryID] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [status] = useState(1);
  const [futureUseFlag] = useState(0);
  const [slogan, setSlogan] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('https://');
  const [packageID, setPackageID] = useState('');
  const [amount, setAmount] = useState(0);
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardNo, setCardNo] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvv, setCvv] = useState('');

  const [responseData, setResponseData] = useState(null);

  const geCountries = () => {
    LocationService.getCountries().then((res) => {
      setCountryList(res.data);
    });
  };

  const getCitiesByCountry = (value) => {
    if (value) {
      LocationService.getCitiesByCountry(value).then((res) => {
        setCityList(res.data);
      });
    } else setCityList(null);
  };

  const getPackagesList = () => {
    PackageService.getPackages().then((res) => {
      if (res.success) {
        setPackagesList(res.data);
      }
    });
  };

  useEffect(() => {
    if (isPageLoaded) {
      geCountries();
      getPackagesList();
    }
  }, [isPageLoaded]);

  const registerCompany = () => {
    setIsLoading(true);
    const formObj = {
      companyGeneral: {
        name: name,
        email: email,
        phone: phone,
        password: password,
        role_id: roleID,
        city_id: cityID,
        //state_id: stateID,
        country_id: countryID,
        street_address: streetAddress,
        status: status,
        photo: lightLogoFile,
      },
      companySpecifics: {
        slogan: slogan,
        website_url: websiteUrl,
        dark_logo: darkLogoFile,
        registered_by: user?.id,
      },
      subscriptionDetails: {
        // if 2nd option is selected it means package will be subscribed for 12 months and 12 intervals
        intervals: interval == 0 ? 1 : interval == 2 ? 12 : 1,
        current_subscription: 1,
        amount: amount,
        status: 1,
        package_id: packageID,
      },
      cardDetails: {
        card_no: cardNo,
        name_on_card: nameOnCard,
        expiry_month: expiry.split('/')[0],
        expiry_year: expiry.split('/')[1],
        cvv: cvv,
        future_use_flag: futureUseFlag,
        status: 1,
        default_flag: 1,
      },
    };

    CompanyService.storeCompany(formObj)
      .then((res) => {
        if (res.success) {
          setResponseData({
            status: true,
            message: 'Account has been created successfully',
          });
          setTimeout(() => navigate(-1), 1000);
        } else {
          setResponseData({ status: false, message: 'Something went wrong' });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setResponseData({
          status: false,
          message: error?.response?.data?.message || 'Something went wrong',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (activeTab === 4) {
      registerCompany();
    }
  }, [activeTab]);

  return (
    <div>
      <div className="text-2xl">Account Craetion</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>
        {activeTab === 0 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <Formik
              initialValues={{
                lightLogoFile: lightLogoFile,
                lightLogoUrl: lightLogoUrl,
                darkLogoFile: darkLogoFile,
                darkLogoUrl: darkLogoUrl,
              }}
              validationSchema={yup.object().shape({
                lightLogoFile: validationSchema.image,
                darkLogoFile: validationSchema.image,
              })}
              onSubmit={(values, actions) => {
                try {
                  setDarkLogoFile(values.darkLogoFile);
                  setDarkLogoUrl(values.darkLogoUrl);
                  setLightLogoFile(values.lightLogoFile);
                  setLightLogoUrl(values.lightLogoUrl);
                  setActiveTab(1);
                } catch (error) {}
              }}
            >
              {({
                values,
                errors,
                setFieldValue,
                handleSubmit,
                handleBlur,
              }) => (
                <div>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <DragDropImage
                        error={errors.lightLogoFile}
                        onBlur={handleBlur}
                        name="lightLogoFile"
                        onChangeImage={(file) => {
                          if (file) {
                            setFieldValue('lightLogoFile', file);
                            setFieldValue(
                              'lightLogoUrl',
                              URL.createObjectURL(file)
                            );
                          }
                        }}
                        preview={values.lightLogoUrl}
                        title="Light Version"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <DragDropImage
                        error={errors.darkLogoFile}
                        onBlur={handleBlur}
                        name="darkLogoFile"
                        onChangeImage={(file) => {
                          if (file) {
                            setFieldValue('darkLogoFile', file);
                            setFieldValue(
                              'darkLogoUrl',
                              URL.createObjectURL(file)
                            );
                          }
                        }}
                        preview={values.darkLogoUrl}
                        title="Dark Version"
                      />
                    </Grid>
                  </Grid>
                  <div className="flex items-center justify-end mt-8">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        )}

        {activeTab === 1 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <Formik
              initialValues={{
                name: name,
                slogon: slogan,
                phone: phone,
                email: email,
                website: websiteUrl,
                address: streetAddress,
                city: cityID,
                country: countryID,
                password: password,
                showPassword: false,
                confirmPassword: confirmPassword,
                showConfirmPassword: false,
              }}
              validationSchema={yup.object().shape({
                name: validationSchema.name,
                slogon: validationSchema.required('Company Slogon'),
                email: validationSchema.email,
                password: validationSchema.password,
                confirmPassword: validationSchema.confirmPassword,
              })}
              onSubmit={(values, actions) => {
                try {
                  setName(values.name);
                  setSlogan(values.slogon);
                  setPhone(values.phone);
                  setEmail(values.email);
                  setWebsiteUrl(values.website);
                  setStreetAddress(values.address);
                  setCityID(values.city);
                  setCountryID(values.country);
                  setPassword(values.password);
                  setConfirmPassword(values.confirmPassword);
                  setActiveTab(2);
                } catch (error) {}
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                const goPreviousTab = () => {
                  setName(values.name);
                  setSlogan(values.slogon);
                  setPhone(values.phone);
                  setEmail(values.email);
                  setWebsiteUrl(values.website);
                  setStreetAddress(values.address);
                  setCityID(values.city);
                  setCountryID(values.country);
                  setPassword(values.password);
                  setConfirmPassword(values.confirmPassword);
                  setActiveTab(0);
                };
                return (
                  <div>
                    <Grid container spacing={4}>
                      <Grid item xs={12} container spacing={4}>
                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Company Title{' '}
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              helperText={touched.name && errors.name}
                              error={!!touched.name && !!errors.name}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Company Slogan (Optional)
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="slogon"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.slogon}
                              helperText={touched.slogon && errors.slogon}
                              error={!!touched.slogon && !!errors.slogon}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Company Phone (Optional)
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              helperText={touched.phone && errors.phone}
                              error={!!touched.phone && !!errors.phone}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Company Email
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              helperText={touched.email && errors.email}
                              error={!!touched.email && !!errors.email}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">Company Website</label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="website"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.website}
                              helperText={touched.website && errors.website}
                              error={!!touched.website && !!errors.website}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={4}>
                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Street Address (Optional)
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                              helperText={touched.address && errors.address}
                              error={!!touched.address && !!errors.address}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Country (Optional)
                            </label>
                            <TextFieldWrapper
                              select
                              placeholder="Placeholder"
                              name="country"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue('city', '');
                                getCitiesByCountry(e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.country}
                              helperText={touched.country && errors.country}
                              error={!!touched.country && !!errors.country}
                            >
                              <MenuItem value={''}>Select</MenuItem>
                              {countryList &&
                                Array.isArray(countryList) &&
                                countryList.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </TextFieldWrapper>
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">City (Optional)</label>
                            <TextFieldWrapper
                              select
                              disabled={!!!cityList}
                              placeholder="Placeholder"
                              name="city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.city}
                              helperText={touched.city && errors.city}
                              error={!!touched.city && !!errors.city}
                            >
                              <MenuItem value={''}>Select</MenuItem>
                              {cityList &&
                                Array.isArray(cityList) &&
                                cityList.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                            </TextFieldWrapper>
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Password<span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              helperText={touched.password && errors.password}
                              error={!!touched.password && !!errors.password}
                              type={values.showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'showPassword',
                                        !values.showPassword
                                      )
                                    }
                                  >
                                    {values.showPassword ? (
                                      <VisibilityOutlined fontSize="small" />
                                    ) : (
                                      <VisibilityOffOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                ),
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item md={4} sm={6} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Re-enter Password
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={
                                !!touched.confirmPassword &&
                                !!errors.confirmPassword
                              }
                              type={
                                values.showConfirmPassword ? 'text' : 'password'
                              }
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'showConfirmPassword',
                                        !values.showConfirmPassword
                                      )
                                    }
                                  >
                                    {values.showConfirmPassword ? (
                                      <VisibilityOutlined fontSize="small" />
                                    ) : (
                                      <VisibilityOffOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className="flex items-center justify-between mt-8">
                      <button
                        onClick={goPreviousTab}
                        className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}

        {activeTab === 2 && (
          <div className="mt-12 mx-auto">
            <Formik
              initialValues={{ packageId: packageID, amount: amount }}
              validationSchema={yup.object().shape({
                packageId: validationSchema.required('Package selection'),
              })}
              onSubmit={(values, actions) => {
                try {
                  setPackageID(values.packageId);
                  setAmount(values.amount);
                  setActiveTab(3);
                } catch (error) {}
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                setFieldTouched,
              }) => {
                const goPreviousTab = () => {
                  setPackageID(values.packageId);
                  setAmount(values.amount);
                  setActiveTab(1);
                };
                return (
                  <div>
                    <div className="flex items-center justify-end gap-1">
                      <span>Month</span>
                      <SwitchWrapper
                        onChange={(e) => {
                          setInterval(e.target.checked ? 2 : 1);
                        }}
                        checked={interval === 2}
                      />
                      <span>Year</span>
                    </div>

                    <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                      slidesPerView={1}
                      spaceBetween={10}
                      breakpoints={{
                        600: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        1100: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1400: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1800: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                      }}
                    >
                      {packagesList &&
                        Array.isArray(packagesList) &&
                        packagesList.map((item, index) => {
                          return (
                            <SwiperSlide key={index} className="">
                              <div className="flex w-full flex-col items-center  justify-center">
                                <div className="max-w-72 min-w-64">
                                  <PricingComponentSelect
                                    index={index}
                                    data={item}
                                    checked={item.id === values.packageId}
                                    onClick={(id) => {
                                      setSelectedPackage(item);
                                      setFieldValue('packageId', id);
                                      setFieldValue(
                                        'amount',
                                        interval == 1
                                          ? item.monthly_price
                                          : item.annually_price
                                      );
                                      setFieldTouched('packageId', false);
                                    }}
                                    interval={interval}
                                    selection={true}
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>

                    {touched.packageId && errors.packageId && (
                      <div className="text-sm font-bold text-red-600 bg-red-200 py-1 px-2 mt-2">
                        {errors.packageId}
                      </div>
                    )}

                    <div className="flex items-center justify-between mt-8">
                      <button
                        onClick={goPreviousTab}
                        className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}

        {activeTab === 3 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <Formik
              initialValues={{
                cardHolderName: nameOnCard,
                cardNumber: cardNo,
                expiryDate: expiry,
                cardCsv: cvv,
              }}
              validationSchema={yup.object().shape({
                cardHolderName: validationSchema.name,
                cardNumber: validationSchema.cardNumber,
                expiryDate: validationSchema.required(''),
                cardCsv: validationSchema.required(''),
              })}
              onSubmit={(values, actions) => {
                try {
                  setNameOnCard(values.cardHolderName);
                  setCardNo(values.cardNumber);
                  setExpiry(values.expiryDate);
                  setCvv(values.cardCsv);
                  setActiveTab(4);
                } catch (error) {}
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleSubmit,
                handleChange,
              }) => {
                const goPreviousTab = () => {
                  setNameOnCard(values.cardHolderName);
                  setCardNo(values.cardNumber);
                  setExpiry(values.expiryDate);
                  setCvv(values.cardCsv);
                  setActiveTab(2);
                };
                return (
                  <div>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <div className="flex flex-col gap-2">
                          <label className="text-lg font-bold">
                            Select payment method
                          </label>
                          <div className="flex items-center justify-between gap-4 flex-wrap">
                            <div className="flex gap-2 items-center">
                              <input
                                defaultChecked
                                id={`plan-visaIcon`}
                                type="radio"
                                value=""
                                name="payment-medthod-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
                              />
                              <label
                                htmlFor={`plan-visaIcon`}
                                className="text-black"
                              >
                                <img alt="visa-icon" src={visaIcon} />
                              </label>
                            </div>
                            {/*  <div className="flex gap-2 items-center">
                      <input
                        id={`plan-paypalIcon`}
                        type="radio"
                        value=""
                        name="payment-medthod-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
                      />
                      <label htmlFor={`plan-paypalIcon`} className="text-black">
                        <img alt="paypal-icon" src={paypalIcon} />
                      </label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        id={`plan-mastercardIcon`}
                        type="radio"
                        value=""
                        name="payment-medthod-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
                      />
                      <label htmlFor={`plan-mastercardIcon`} className="text-black">
                        <img alt="master-card-icon" src={mastercardIcon} />
                      </label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        id={`plan-americanexpressIcon`}
                        type="radio"
                        value=""
                        name="payment-medthod-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
                      />
                      <label
                        htmlFor={`plan-americanexpressIcon`}
                        className="text-black"
                      >
                        <img
                          alt="amercan-express-icon"
                          src={americanexpressIcon}
                        />
                      </label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        id={`plan-applepayIcon`}
                        type="radio"
                        value=""
                        name="payment-medthod-radio"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
                      />
                      <label htmlFor={`plan-applepayIcon`} className="text-black">
                        <img alt="apple-pay-icon" src={applepayIcon} />
                      </label>
                    </div> */}
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12} container>
                        <Grid container spacing={2}>
                          <Grid item sm={8} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Card holder name
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="cardHolderName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cardHolderName}
                                helperText={
                                  touched.cardHolderName &&
                                  errors.cardHolderName
                                }
                                error={
                                  !!touched.cardHolderName &&
                                  !!errors.cardHolderName
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item sm={8} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Card number{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <InputMaskWrapper
                                mask="9999 9999 9999 9999"
                                maskChar=""
                                placeholder="Placeholder"
                                name="cardNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cardNumber}
                                helperText={
                                  touched.cardNumber && errors.cardNumber
                                }
                                error={
                                  !!touched.cardNumber && !!errors.cardNumber
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item className="flex gap-2" sm={8} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Exp <span className="text-red-500">*</span>
                              </label>
                              <InputMaskWrapper
                                mask="99/99"
                                maskChar=""
                                placeholder="00/00"
                                name="expiryDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expiryDate}
                                helperText={
                                  touched.expiryDate && errors.expiryDate
                                }
                                error={
                                  !!touched.expiryDate && !!errors.expiryDate
                                }
                              />
                            </div>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                CSV <span className="text-red-500">*</span>
                              </label>
                              <InputMaskWrapper
                                mask="9999"
                                maskChar=""
                                placeholder="0000"
                                name="cardCsv"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.cardCsv}
                                helperText={touched.cardCsv && errors.cardCsv}
                                error={!!touched.cardCsv && !!errors.cardCsv}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="flex items-center gap-2">
                              <Lock fontSize="small" />
                              <div>
                                Guaranteed{' '}
                                <span className="font-bold">safe & secure</span>{' '}
                                check out.
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="flex flex-col gap-2">
                              <div className="flex items-center justify-between gap-2">
                                <div>Subtotal</div>
                                <div>{amount}$</div>
                              </div>

                              <div className="flex items-center justify-between gap-2">
                                <div>Tax</div>
                                <div>0$</div>
                              </div>

                              <hr className="border border-b-2 border-b-black" />

                              <div className="flex items-center justify-between gap-2 font-bold">
                                <div>Subtotal</div>
                                <div>{amount + 0}$</div>
                              </div>
                            </div>
                          </Grid>
                          {/* <Grid item xs={12}>
                          <div className="flex justify-center gap-2">
                            <button
                              onClick={() => {}}
                              className="w-full text-sm bg-main text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                            >
                              Make payment
                            </button>
                          </div>
                        </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <PricingComponentSelect
                          index={0}
                          data={selectedPackage}
                          checked={true}
                          onClick={() => {}}
                          interval={interval}
                        />
                      </Grid>
                    </Grid>

                    <div className="flex items-center justify-between mt-8">
                      <button
                        onClick={goPreviousTab}
                        className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Back
                      </button>

                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        )}

        {activeTab === 4 && (
          <div className="mt-12 mx-auto max-w-[800px]">
            <div className="min-h-96 flex items-center justify-center flex-col gap-5">
              {isLoading ? (
                <CircularProgress />
              ) : responseData?.status ? (
                <Fragment>
                  <TaskAltOutlined
                    className="text-[var(--yellow)]"
                    sx={{ fontSize: '5rem' }}
                  />
                  <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                    {responseData?.message}
                  </div>
                </Fragment>
              ) : (
                !responseData?.status &&
                responseData?.message && (
                  <Fragment>
                    <GppBadOutlined
                      className="text-[var(--pink)]"
                      sx={{ fontSize: '5rem' }}
                    />
                    <div className="text-[var(--pink)] font-bold text-2xl text-center">
                      {responseData?.message}
                    </div>
                  </Fragment>
                )
              )}
            </div>

            {!isLoading && !responseData?.status && (
              <div className="flex items-center justify-between mt-8">
                <button
                  onClick={() => {
                    setResponseData(null);
                    setActiveTab(3);
                  }}
                  className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                >
                  Back
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
