import API from '../config/api.config';
import FormAPI from '../config/formApi.config';

const getPackages = () => {
  return API.get('getPackages').then((res) => {
    return res.data;
  });
};

const getWebsitePackages = () => {
  return API.get('/websitePackageList').then((res) => {
    return res.data;
  });
};

// admin routes
const getAllPackages = (pageNo) => {
  return API.get(`/packages?page=${pageNo}`).then((res) => {
    return res.data;
  });
};

const getPackage = (id) => {
  return API.get(`/packages/${id}`).then((res) => {
    return res.data;
  });
};

const updatePackage = (id, data) => {
  return FormAPI.post(`/packages/${id}`, data).then((res) => {
    return res.data;
  });
};

const storePackage = (data) => {
  return FormAPI.post(`/packages`, data).then((res) => {
    return res.data;
  });
};

const changeStatus = (id, data) => {
  return API.get(`/packages/changeStatus/${id}`, data).then((res) => {
    return res.data;
  });
};

const PackageService = {
  getPackages,
  getWebsitePackages,
  changeStatus,
  storePackage,
  updatePackage,
  getPackage,
  getAllPackages,
};

export default PackageService;
