import InputMask from 'react-input-mask';
import React from 'react';
import { TextFieldWrapper } from '.';

const Component = (props) => {
  return (
    <InputMask
      mask={props.mask}
      value={props.value}
      onChange={props.onChange}
      onInput={props.onInput}
      onBlur={props.onBlur}
      alwaysShowMask={false}
      placeholder={props.placeholder}
      maskChar={props?.maskChar || ''}
      disabled={props?.disabled}
    >
      {(inputProps) => (
        <TextFieldWrapper
          className="form-control"
          id={props.id}
          min={props.min}
          value={props.value}
          max={props.max}
          name={props?.name}
          error={props?.error}
          helperText={props?.helperText}
          disabled={props?.disabled}
          {...inputProps}
        />
      )}
    </InputMask>
  );
};

export default Component;
