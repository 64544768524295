import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Details, Edit } from '@mui/icons-material';
import { useQuery } from '../../../constants/shared-methods';
import useScreen from '../../../hooks/useScreen';
import { initialPagination } from '../../../constants/constant-values';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useNotification from '../../../hooks/useNotification';
import { routesName } from '../../../constants/routes-name';
import { PaginationWrapper } from '../../../components';
import FaqService from '../../../services/faq.service';

function FAQsTableView({ loading = false }) {
  const { successNonification, errorNonification } = useNotification();
  const navigate = useNavigate();
  const query = useQuery();
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [faqsList, setFaqsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalData, setModalData] = useState();

  const closeModal = () => {
    setIsOpenModal(false);
    setModalData();
  };

  const getAllFaqs = () => {
    FaqService.getFaqs(page || 1)
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        setFaqsList(data.data);
        pagination.from = data.from;
        pagination.to = data.to;
        pagination.total = data.total;
        pagination.perPage = data.per_page;
        pagination.currentPage = data.current_page;
        pagination.lastPage = data.last_page;
        setPagination({ ...pagination });
      })
      .catch((error) => {
        setFaqsList([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getAllFaqs();
    }
  }, [isPageLoaded, page]);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        FaqService.deleteFaq(id)
          .then((res) => {
            if (res.success) {
              getAllFaqs();
              successNonification('FAQ has been deleted.');
            }
          })
          .catch(() => errorNonification('Something went wrong'));
      }
    });
  };

  const columns = [
    {
      field: 'faq_type',
      headerName: 'FAQ TYPE',
      flex: 0.3,
      minWidth: 80,
      sortable: false,
    },
    {
      field: 'question',
      sortable: false,
      headerName: 'QUESTION',
      minWidth: 180,
      flex: 0.7,
    },
    {
      field: 'answer',
      sortable: false,
      headerName: 'ANSWER',
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      sortable: false,
      minWidth: 100,
      flex: 0.5,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Detail">
              <IconButton
                size="small"
                onClick={() => {
                  setModalData(params.row);
                  setIsOpenModal(true);
                }}
              >
                <Details />
              </IconButton>
            </Tooltip>

            <Tooltip slotProps={tooltipSlotStyle} arrow title="Edit">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`${routesName.editFAQsPath}${params?.id}`);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip slotProps={tooltipSlotStyle} arrow title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(params?.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows =
    faqsList &&
    Array.isArray(faqsList) &&
    faqsList.length > 0 &&
    faqsList.map((item, index) => ({
      id: item?.id,
      answer: item?.answer,
      faq_type: item?.faq_type,
      is_public: item?.is_public,
      question: item?.question,
      status: item?.status,
      user_id: item?.user_id,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.faqsSetting}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal open={isOpenModal} onClose={closeModal}>
        <Box sx={style}>
          <div className="text-xl font-bold mb-4">FAQ Detail</div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="flex sm:flex-row flex-col gap-2">
                <div className="text-xs font-bold text-nowrap">FAQ Type : </div>
                <div className="text-xs">{modalData?.faq_type}</div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex sm:flex-row flex-col gap-2">
                <div className="text-xs font-bold text-nowrap">Question : </div>
                <div className="text-xs">{modalData?.question}</div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex sm:flex-row flex-col gap-2">
                <div className="text-xs font-bold text-nowrap">Answer : </div>
                <div className="text-xs">{modalData?.answer}</div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default memo(FAQsTableView);

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  bgcolor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};
