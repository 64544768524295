import React, { useState } from 'react';
import { CardPreview } from '.';
import { Download } from '@mui/icons-material';
import { toPng } from 'html-to-image';
import VCard from 'vcard-creator';
import { saveAs } from 'file-saver';

function Component({ template = {}, personal = {}, company = {} }) {
  const [isLoadPreview, setIsLoadPreview] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCardDownload = async (id) => {
    const elementToDownload = document.getElementById(id);
    setIsDownloading(true);

    elementToDownload &&
      toPng(elementToDownload, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'my-image-name.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          setIsDownloading(false);
          // console.log(err);
        });
  };

  const handleVCardDownload = () => {
    const myVCard = new VCard();

    if (
      company &&
      Object.keys(company).length > 0 &&
      personal &&
      Object.keys(personal).length > 0
    ) {
      myVCard
        .addName('', personal.name, '', '', '')
        .addCompany(company.name ? company.name : '')
        .addJobtitle('')
        .addRole(personal.designation)
        .addEmail(personal.email)
        .addPhoneNumber(company.phone ? company.phone : '', 'WORK')
        .addPhoneNumber(personal.phone ? personal.phone : '', 'HOME')
        .addAddress(
          null,
          null,
          personal.street_address,
          personal.city_name,
          personal.state_name,
          personal.zip_code,
          personal.country_name
        )
        .addURL('');
      const vcfBlob = new Blob(
        myVCard
          .toString()
          .split('\n')
          .map(function (x) {
            return x + '\n';
          })
      );
      saveAs(vcfBlob, `${personal.name}.vcf`);
    }
  };

  return (
    <div>
      <div className="flex justify-center overflow-x-auto">
        <CardPreview
          setIsLoadPreview={setIsLoadPreview}
          isLoadPreview={isLoadPreview}
          cardBackground={template.card_background}
          backgroundImage={template.background_image}
          cardComponent={template.card_component}
          userData={personal}
          cardData={template}
          className="border shadow bRadius-10px"
          canDownload={true}
          id={'employee-card'}
        />
      </div>

      {!isLoadPreview && template && Object.keys(template).length > 0 && (
        <div className="flex justify-center items-center py-2 pt-4 gap-2">
          <button
            onClick={() => handleCardDownload(`employee-card`)}
            disabled={isLoadPreview}
            className="bg-[var(--yellow)] text-sm font-bold px-4 py-2 rounded-md"
          >
            <Download fontSize="small" /> Card
          </button>
          <button
            onClick={() => handleVCardDownload()}
            disabled={isLoadPreview}
            className="bg-main text-sm font-bold px-4 py-2 rounded-md"
          >
            <Download fontSize="small" />
            vCard
          </button>
        </div>
      )}
    </div>
  );
}

export default Component;
