import React from 'react';
import { OverviewComponent } from '../../components';
import EmployessTableView from './EmployessTableView';
import { Add } from '@mui/icons-material';
import { Link, Navigate } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';
import { useSelector } from 'react-redux';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (user?.role_id === 1 || isUser || isCompany) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { isCompany, isUser } = useSelector(({ user }) => user);
  return (
    <div className="flex flex-col gap-8">
      <OverviewComponent />
      <div className="flex flex-col gap-8">
        <div>
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">Employees details</div>
            {(isCompany || isUser) && (
              <Link
                to={routesName.createEmployee}
                className="text-xs bg-main text-center px-3 py-1 rounded-full font-bold flex items-center justify-center gap-1"
              >
                <Add className="text-white" fontSize="small" />
                <span>Add Employee</span>
              </Link>
            )}
          </div>
          <EmployessTableView />
        </div>
      </div>
    </div>
  );
}

export default Component;
