import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '../../constants/shared-methods';
import useScreen from '../../hooks/useScreen';
import { initialPagination } from '../../constants/constant-values';
import { useNavigate } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';
import { PaginationWrapper } from '../../components';
import CompanyService from '../../services/company.service';
import moment from 'moment';
import { useSelector } from 'react-redux';

function BillingTableView({ loading = false }) {
  const navigate = useNavigate();
  const query = useQuery();
  const { user, company } = useSelector(({ user }) => user);
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [transactions, setTransections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);

  const getCompanyTransections = () => {
    CompanyService.getCompanyTransections(company.id, page || 1)
      .then((res) => {
        setIsLoading(false);
        if (res?.data) {
          const data = res.data;
          setTransections(data.data);
          pagination.from = data.from;
          pagination.to = data.to;
          pagination.total = data.total;
          pagination.perPage = data.per_page;
          pagination.currentPage = data.current_page;
          pagination.lastPage = data.last_page;
          setPagination({ ...pagination });
        }
      })
      .catch((error) => {
        setTransections([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded && company?.id) {
      getCompanyTransections();
    }
  }, [isPageLoaded, page, company]);

  const columns = [
    {
      field: 'title',
      sortable: false,
      headerName: 'PACKAGE TITLE',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'amount',
      sortable: false,
      headerName: 'AMOUNT',
      minWidth: 100,
      flex: 0.75,
    },
    {
      field: 'allow_no_of_cards',
      sortable: false,
      headerName: 'ALLOWED CARDS',
      minWidth: 150,
      flex: 0.75,
    },
    {
      field: 'allow_no_of_templates',
      sortable: false,
      headerName: 'ALLOWED TEMPLATES',
      minWidth: 200,
      flex: 0.75,
    },
    {
      field: 'no_of_designed_cards',
      sortable: false,
      headerName: 'DESIGNED CARDS',
      minWidth: 150,
      flex: 0.75,
    },
    {
      field: 'no_of_designed_templates',
      sortable: false,
      headerName: 'DESIGNED TEMPLATES',
      minWidth: 200,
      flex: 0.75,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'SUBSCRIPTION STATUS',
      minWidth: 200,
      flex: 0.75,
    },
    {
      field: 'start_date',
      sortable: false,
      headerName: 'SUBSCRIPTION DATE',
      minWidth: 200,
      flex: 0.75,
    },
  ];

  const rows =
    transactions &&
    Array.isArray(transactions) &&
    transactions.length > 0 &&
    transactions.map((item, index) => ({
      allow_no_of_cards:
        item.allow_no_of_cards === -1 ? 'Unlimited' : item.allow_no_of_cards,
      allow_no_of_templates:
        item.allow_no_of_templates === -1
          ? 'Unlimited'
          : item.allow_no_of_templates,
      amount: item.amount,
      annually_price: item.annually_price,
      card_details_id: item.card_details_id,
      company_id: item.company_id,
      created_at: item.created_at,
      currency: item.currency,
      current_subscription: item.current_subscription,
      deleted_at: item.deleted_at,
      discount_type: item.discount_type,
      expiry_date: item.expiry_date,
      featured: item.featured,
      icon: item.icon,
      icon_logo: item.icon_logo,
      id: item.id,
      interval: item.interval,
      intervals: item.intervals,
      is_free_plan: item.is_free_plan,
      is_notification_sent: item.is_notification_sent,
      monthly_price: item.monthly_price,
      no_of_cards: item.no_of_cards,
      no_of_designed_cards: item.no_of_designed_cards,
      no_of_designed_templates: item.no_of_designed_templates,
      no_of_templates: item.no_of_templates,
      package_id: item.package_id,
      percent_discount: item.percent_discount,
      price_discount: item.price_discount,
      start_date: moment(item.start_date).format('DD-MM-YYYY'),
      status: item.status === 1 ? 'Active' : 'Inactive',
      stripe_token: item.stripe_token,
      title: item.title,
      updated_at: item.updated_at,
      user_id: item.user_id,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          style={{}}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.billing}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default memo(BillingTableView);

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};
