import API from '../config/api.config';

const getCompaniesList = () => {
  return API.get(`/company_list`).then((res) => {
    return res.data;
  });
};

const getAllCompanies = (pageNo) => {
  return API.get(`/company?page=${pageNo}`).then((res) => {
    return res.data;
  });
};

const storeCompany = (data) => {
  return API.post('/company', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).then((res) => {
    return res.data;
  });
};

const updateCompany = (id, data) => {
  return API.post(`/company/updateCompany/${id}`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).then((res) => {
    return res.data;
  });
};

const getCompany = (id) => {
  return API.get(`/company/${id}`).then((res) => {
    return res.data;
  });
};

const deleteCompany = (id) => {
  return API.delete(`/company/${id}`).then((res) => {
    return res.data;
  });
};

const changeStatus = (id) => {
  return API.get(`/company/changeStatus/${id}`).then((res) => {
    return res.data;
  });
};

const hasCompanyRegistered = (user_id) => {
  return API.get(`/company/hasCompany/${user_id}`).then((res) => {
    return res.data;
  });
};

const userHasCompanyRegistered = (user_id) => {
  return API.get(`/company/userHasCompany/${user_id}`).then((res) => {
    return res.data;
  });
};

const getCompanyDetail = (id) => {
  return API.get(`/company/companyDetails/${id}`).then((res) => {
    return res.data;
  });
};

const getCompanyByUser = () => {
  return API.get(`/company`).then((res) => {
    return res.data;
  });
};

const getPublicCompanyDetail = (guid) => {
  return API.get(`/company/${guid}`).then((res) => {
    return res.data;
  });
};

const changeCompanyArchiveStatus = (id) => {
  return API.get(`/company/changeArchiveStatus/${id}`).then((res) => {
    return res.data;
  });
};

const getCompanyTransections = (id, page) => {
  return API.get(`/company/transactions/${id}?page=${page}`).then((res) => {
    return res.data;
  });
};

const CompanyService = {
  getPublicCompanyDetail,
  userHasCompanyRegistered,
  getCompanyByUser,
  getCompaniesList,
  getAllCompanies,
  storeCompany,
  updateCompany,
  deleteCompany,
  changeStatus,
  getCompany,
  hasCompanyRegistered,
  getCompanyDetail,
  changeCompanyArchiveStatus,
  getCompanyTransections,
};

export default CompanyService;
