import API from "../config/api.config";

const getUsersList = (pageNo) => {
    return API.get(`/users_list?page=${pageNo}`)
        .then(res => {
            return res.data
        })
}

const changeUserStatus = (id) => {
    return API.patch(`/user/changeStatus/${id}`)
        .then(res => {
            return res.data
        })
}

const CompanyUserService = {
    getUsersList,
    changeUserStatus
}

export default CompanyUserService
