import API from '../config/api.config'
import FormAPI from '../config/formApi.config'

const getSettings = () => {
    return API.get('setting')
        .then(res => {
            return res.data
        })
}

const getSystemInfo = () => {
    return API.get('getSystemInfo')
        .then(res => {
            return res.data
        })
}

const getPaymentSettings = () => {
    return API.get('/paymentSetting')
        .then(res => {
            return res.data
        })
}

const savePaymentSettings = (data) => {
    return API.post('paymentSetting', data)
        .then(res => {
            return res.data
        })
}

const updatePaymentSettings = (id, data) => {
    return API.put(`paymentSetting/${id}`, data)
        .then(res => {
            return res.data
        })
}

const deletePaymentSettings = (id) => {
    return API.delete(`paymentSetting/${id}`)
        .then(res => {
            return res.data
        })
}

const changePaymentSettingStatus = (id) => {
    return API.get(`paymentSetting/changeStatus/${id}`)
        .then(res => {
            return res.data
        })
}


const saveWebsiteSettings = (setting_id, data, formType) => {
    if (formType === "form-data") {
        return FormAPI.post(`setting/updateSetting/${setting_id}`, data)
            .then(res => {
                return res.data
            })
    }
    else {
        return API.post(`setting/updateSetting/${setting_id}`, data)
            .then(res => {
                return res.data
            })
    }
}

const SettingService = {
    saveWebsiteSettings,
    getSettings,
    getSystemInfo,
    getPaymentSettings,
    savePaymentSettings,
    updatePaymentSettings,
    deletePaymentSettings,
    changePaymentSettingStatus
}

export default SettingService
