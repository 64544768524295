import React, { useEffect, useState } from 'react';
import { CardTab } from '../../components';
import { LinearProgress } from '@mui/material';
import CardService from '../../services/card.service';
import useNotification from '../../hooks/useNotification';
import useScreen from '../../hooks/useScreen';
import { useParams } from 'react-router-dom';

function CardDetail() {
  const params = useParams();
  const { isPageLoaded } = useScreen();
  const { errorNonification } = useNotification();

  const [templateData, setTemplateData] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getCardDetails = () => {
    if (params?.id !== NaN) {
      setIsLoading(true);

      CardService.getCardDetails(params.id)
        .then((res) => {
          const data = res.data;
          setIsLoading(false);
          if (res.success) {
            setTemplateData({
              ...data?.template,
              guid: data.business_card.guid,
            });
            setEmployeeData(data?.employee);
            //setCompanyData(res.data?.company);
          } else {
            errorNonification('Something went wrong');
          }
        })
        .catch((error) => {
          errorNonification(
            error?.response?.data?.message || 'Something went wrong'
          );
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      getCardDetails();
    }
  }, [isPageLoaded]);
  return (
    <div>
      <div className="text-2xl font-bold">Card Detail</div>

      <div className="md:mt-12 mt-6 bg-white lg:px-12 md:px-6 px-4 lg:py-12 md:py-6 py-4 rounded-lg">
        {!isLoading ? (
          <CardTab
            template={templateData}
            personal={employeeData}
            company={companyData}
          />
        ) : (
          <div className="my-24 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default CardDetail;
