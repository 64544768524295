import API from '../config/api.config';

const getCountries = () => {
  return API.get('/getCountries').then((res) => {
    return res.data;
  });
};

const getStates = (country_id) => {
  return API.get(`/getStates/${country_id}`).then((res) => {
    return res.data;
  });
};

const getCitiesByStates = (country_id, state_id) => {
  return API.get(`/getCities/${country_id}/${state_id}`).then((res) => {
    return res.data;
  });
};

const getCitiesByCountry = (country_id) => {
  return API.get(`/getCitiesFromCountry/${country_id}`).then((res) => {
    return res.data;
  });
};

const getCountry = (country_id) => {
  return API.get(`getCountry/${country_id}`).then((res) => {
    return res.data;
  });
};

const getState = (state_id) => {
  return API.get(`getState/${state_id}`).then((res) => {
    return res.data;
  });
};

const getCity = (city_id) => {
  return API.get(`getCity/${city_id}`).then((res) => {
    return res.data;
  });
};

const LocationService = {
  getCountry,
  getState,
  getCity,
  getCountries,
  getStates,
  getCitiesByStates,
  getCitiesByCountry,
};

export default LocationService;
