import React from 'react';
import { OverviewComponent } from '../../components';
import CompanyTableView from './CompanyTableView';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  return (
    <div className="flex flex-col gap-8">
      <OverviewComponent />
      <div className="flex flex-col gap-8">
        <div>
          <div className="text-xl font-bold">Company details</div>
          <CompanyTableView />
        </div>
      </div>
    </div>
  );
}

export default Component;
