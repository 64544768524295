import { Grid } from '@mui/material';
import React from 'react';
import { ShowCountsCard } from '.';
import { useSelector } from 'react-redux';

function Component() {
  const { overallCounts } = useSelector(({ appData }) => appData);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <div className="text-2xl">Overview</div>
      </Grid>

      <Grid item lg={3} md={4} sm={6} xs={12}>
        <ShowCountsCard
          title={'Total Cards'}
          count={overallCounts?.totalCards || 0}
          link={'#'}
          linkText={'Visit'}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <ShowCountsCard
          title={'Templates'}
          count={overallCounts?.totalTemplates || 0}
          link={'#'}
          linkText={'Visit'}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <ShowCountsCard
          title={'Active Cards'}
          count={overallCounts?.activeCards || 0}
          link={'#'}
          linkText={'Visit'}
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <ShowCountsCard
          title={'Available Cards'}
          count={overallCounts?.availableCards || 0}
          link={'#'}
          linkText={'Visit'}
        />
      </Grid>
    </Grid>
  );
}

export default Component;
