import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

function Component({ currentPackage = {} }) {
  const [remainingDays, setRemainingDays] = useState(0);

  const handleExpiryDate = () => {
    if (currentPackage.intervals === 12) {
      if (currentPackage.current_subscription === currentPackage.intervals) {
        return moment(currentPackage.expiry_date).format('LLLL');
      } else {
        let dt = new Date(currentPackage.expiry_date);
        let remaining_subscription =
          currentPackage.intervals - currentPackage.current_subscription;
        // dt.setDate(dt.getDate(), remaining_subscription*30);
        let udpated_expiry = dt.setDate(
          dt.getDate() + remaining_subscription * 30
        );
        return moment(udpated_expiry).format('LLLL');
      }
    } else {
      return moment(currentPackage.expiry_date).format('LLLL');
    }
  };

  const subscriptionRemainingDays = () => {
    let now = new Date();
    let expiry = new Date(currentPackage.expiry_date);
    let remaining = expiry.getTime() - now.getTime();
    let TotalDays = Math.ceil(remaining / (1000 * 3600 * 24));
    setRemainingDays(TotalDays);
  };

  useEffect(() => {
    subscriptionRemainingDays();
  }, [currentPackage]);

  return (
    <Grid container spacing={4} className="text-sm">
      <Grid item md={6} xs={12}>
        <div className="flex flex-col gap-4">
          <div className="font-bold">
            Your Current Plan is {currentPackage.title}
          </div>
          <div>
            No of allowed cards:
            <b>
              {' '}
              {currentPackage.no_of_cards === -1
                ? 'Unlimited'
                : currentPackage.no_of_cards}
            </b>
          </div>
          <div>
            No of allowed templates:
            <b>
              {' '}
              {currentPackage.no_of_templates === -1
                ? 'Unlimited'
                : currentPackage.no_of_templates}
            </b>
          </div>
          <div className="font-bold">
            Activated from {moment(currentPackage.start_date).format('LLLL')}{' '}
            <br />
            Active until {' ' + handleExpiryDate()}
          </div>
          <div>
            We will send you a notification upon Subscription expiration
          </div>
          <div className="font-bold">
            <span className="me-2">
              ${currentPackage.amount} Per{' '}
              {currentPackage.intervals === 1 ? 'Month' : 'Year'}
            </span>{' '}
            {currentPackage.is_featured ? (
              <span className="badge bg-label-primary">Featured</span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Grid>
      <Grid item md={6} xs={12}>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <span className="font-bold">Days</span>
            <span className="font-bold">
              {remainingDays > 0 ? remainingDays : 0} of 30 Days
            </span>
          </div>
          <div className="w-full h-4 bg-gray-200 rounded-full">
            <div
              className="h-4 bg-blue-600 rounded-full"
              style={{
                width:
                  (remainingDays * 100) / 30 > 0
                    ? `${(remainingDays * 100) / 30}%`
                    : '0px',
              }}
            ></div>
          </div>
          <div>
            {remainingDays > 0 ? remainingDays : 0} days remaining until your
            plan requires update
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Component;
