import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PricingComponentSelect } from '../../components';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

function PackagesSwiper({ packages }) {
  return (
    <div className="py-12 flex flex-col md:gap-12 gap-6">
      {packages && Array.isArray(packages) && packages.length > 0 && (
        <Fragment>
          <div className="text-center">
            <div className="text-3xl font-bold">Packages & Prices</div>
          </div>

          <div className=" rounded-md md:px-10 px-3 md:py-4 py-2">
            <div>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={10}
                breakpoints={{
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1100: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1800: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
              >
                {packages.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className="">
                      <div className="flex w-full flex-col items-center  justify-center">
                        <div className="max-w-72 min-w-64">
                          <PricingComponentSelect
                            index={0}
                            data={item}
                            checked={true}
                            onClick={() => {}}
                            interval={1}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default PackagesSwiper;
