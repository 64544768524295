import React, { Fragment, useEffect, useState } from 'react';
import {
  DragDropImage,
  InputMaskWrapper,
  StepperWrapper,
  TextFieldWrapper,
} from '../../components';
import { CircularProgress, Grid, LinearProgress } from '@mui/material';
import { GppBadOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../constants/validationSchema';
import useScreen from '../../hooks/useScreen';
import { toAbsoluteURL } from '../../constants/shared-methods';
import { useSelector } from 'react-redux';
import SettingService from '../../services/setting.service';
import { Navigate, useNavigate } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { user } = useSelector(({ user }) => user);

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const steps = ['Logos', 'Setting', 'Summary'];
  const { isPageLoaded } = useScreen();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSettingLoading, setIsSettingLoading] = useState(true);

  const [lightLogoFile, setLightLogoFile] = useState(null);
  const [lightLogoUrl, setLightLogoUrl] = useState('null');
  const [darkLogoFile, setDarkLogoFile] = useState(null);
  const [darkLogoUrl, setDarkLogoUrl] = useState('null');

  const [settinsID, setSettingsID] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [footerCredits, setFooterCredits] = useState('');

  const [responseData, setResponseData] = useState(null);

  const getSystemSettings = () => {
    SettingService.getSettings()
      .then((res) => {
        if (res.success) {
          if (res.data !== null) {
            setSettingsID(res?.data?.id || '');
            setLightLogoUrl(toAbsoluteURL(res?.data?.light_logo));
            setDarkLogoUrl(toAbsoluteURL(res?.data?.dark_logo));
            setName(res?.data?.name || '');
            setTitle(res?.data?.system_short_name || '');
            setDescription(res?.data?.system_long_name || '');
            setPhone(res?.data?.phone || '');
            setEmail(res?.data?.email || '');
            setAddress(res?.data?.street_address || '');
            setFacebookUrl(res?.data?.facebook_url || '');
            setTwitterUrl(res?.data?.twitter_username || '');
            setInstagramUrl(res?.data?.instagram_username || '');
            setYoutubeUrl(res?.data?.youtube_url || '');
            setFooterCredits(res?.data?.footer_credits || '');
          }
        }
        setIsSettingLoading(false);
      })
      .catch(() => {
        setIsSettingLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getSystemSettings();
    }
  }, [isPageLoaded]);

  const saveAppSetting = () => {
    setIsLoading(true);
    const formObj = {
      name: name,
      system_short_name: title,
      system_long_name: description,
      phone: phone,
      email: email,
      street_address: address,
      facebook_url: facebookUrl,
      twitter_username: twitterUrl,
      instagram_username: instagramUrl,
      youtube_url: youtubeUrl,
      footer_credits: footerCredits,
    };

    let formType;

    if (darkLogoFile !== null) {
      formObj.dark_logo = darkLogoFile;
      formType = 'form-data';
    }

    if (lightLogoFile !== null) {
      formObj.light_logo = lightLogoFile;
      formType = 'form-data';
    }

    SettingService.saveWebsiteSettings(settinsID, formObj, formType)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setResponseData({
            status: true,
            message: 'Settings has been updated successfully',
          });
          setTimeout(() => navigate(-1), 1000);
        } else {
          setResponseData({ status: false, message: 'Something went wrong' });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setResponseData({
          status: false,
          message: error?.response?.data?.message || 'Something went wrong',
        });
        setIsLoading(false);
      });
    setIsLoading(true);
  };

  useEffect(() => {
    if (activeTab === 2) {
      saveAppSetting();
    }
  }, [activeTab]);

  return (
    <div>
      <div className="text-xl font-bold">Application Setting</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>

        {!isSettingLoading ? (
          <div>
            {activeTab === 0 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    lightLogoFile: lightLogoFile,
                    lightLogoUrl: lightLogoUrl,
                    darkLogoFile: darkLogoFile,
                    darkLogoUrl: darkLogoUrl,
                  }}
                  validationSchema={yup.object().shape({
                    lightLogoFile: validationSchema.image,
                    darkLogoFile: validationSchema.image,
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setDarkLogoFile(values.darkLogoFile);
                      setDarkLogoUrl(values.darkLogoUrl);
                      setLightLogoFile(values.lightLogoFile);
                      setLightLogoUrl(values.lightLogoUrl);
                      setActiveTab(1);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    handleBlur,
                  }) => (
                    <div>
                      <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                          <DragDropImage
                            error={errors.lightLogoFile}
                            onBlur={handleBlur}
                            name="lightLogoFile"
                            onChangeImage={(file) => {
                              if (file) {
                                setFieldValue('lightLogoFile', file);
                                setFieldValue(
                                  'lightLogoUrl',
                                  URL.createObjectURL(file)
                                );
                              }
                            }}
                            preview={values.lightLogoUrl}
                            title="Light Version"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <DragDropImage
                            error={errors.darkLogoFile}
                            onBlur={handleBlur}
                            name="darkLogoFile"
                            onChangeImage={(file) => {
                              if (file) {
                                setFieldValue('darkLogoFile', file);
                                setFieldValue(
                                  'darkLogoUrl',
                                  URL.createObjectURL(file)
                                );
                              }
                            }}
                            preview={values.darkLogoUrl}
                            title="Dark Version"
                          />
                        </Grid>
                      </Grid>
                      <div className="flex items-center justify-end mt-8">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            )}

            {activeTab === 1 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    name: name || '',
                    title: title || '',
                    phone: phone || '',
                    email: email || '',
                    facebookUrl: facebookUrl || '',
                    twitterUrl: twitterUrl || '',
                    instagramUrl: instagramUrl || '',
                    youtubeUrl: youtubeUrl || '',
                    address: address || '',
                    description: description || '',
                    footerCredits: footerCredits || '',
                  }}
                  validationSchema={yup.object().shape({
                    name: validationSchema.name,
                    title: validationSchema.required('Title'),
                    description: validationSchema.required('Description'),
                    email: validationSchema.email,
                    phone: validationSchema.required('Phone'),
                    footerCredits: validationSchema.required('Footer Credits'),
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setName(values.name);
                      setTitle(values.title);
                      setDescription(values.description);
                      setPhone(values.phone);
                      setEmail(values.email);
                      setAddress(values.address);
                      setFacebookUrl(values.facebookUrl);
                      setTwitterUrl(values.twitterUrl);
                      setInstagramUrl(values.instagramUrl);
                      setYoutubeUrl(values.youtubeUrl);
                      setFooterCredits(values.footerCredits);
                      setActiveTab(2);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    const goPreviousTab = () => {
                      setName(values.name);
                      setTitle(values.title);
                      setDescription(values.description);
                      setPhone(values.phone);
                      setEmail(values.email);
                      setAddress(values.address);
                      setFacebookUrl(values.facebookUrl);
                      setTwitterUrl(values.twitterUrl);
                      setInstagramUrl(values.instagramUrl);
                      setYoutubeUrl(values.youtubeUrl);
                      setFooterCredits(values.footerCredits);
                      setActiveTab(0);
                    };
                    return (
                      <div>
                        <Grid container spacing={4}>
                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Application Name
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                helperText={touched.name && errors.name}
                                error={!!touched.name && !!errors.name}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Title
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                                helperText={touched.title && errors.title}
                                error={!!touched.title && !!errors.title}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Phone <span className="text-red-500">*</span>
                              </label>
                              <InputMaskWrapper
                                mask="9999999999999999"
                                maskChar=""
                                placeholder="Placeholder"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                helperText={touched.phone && errors.phone}
                                error={!!touched.phone && !!errors.phone}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Email
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                helperText={touched.email && errors.email}
                                error={!!touched.email && !!errors.email}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Street Address (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                helperText={touched.address && errors.address}
                                error={!!touched.address && !!errors.address}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Facebook URL (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="facebookUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.facebookUrl}
                                helperText={
                                  touched.facebookUrl && errors.facebookUrl
                                }
                                error={
                                  !!touched.facebookUrl && !!errors.facebookUrl
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Twitter URL (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="twitterUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.twitterUrl}
                                helperText={
                                  touched.twitterUrl && errors.twitterUrl
                                }
                                error={
                                  !!touched.twitterUrl && !!errors.twitterUrl
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Instagram URL (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="instagramUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.instagramUrl}
                                helperText={
                                  touched.instagramUrl && errors.instagramUrl
                                }
                                error={
                                  !!touched.instagramUrl &&
                                  !!errors.instagramUrl
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Youtube URL (Optional)
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="youtubeUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.youtubeUrl}
                                helperText={
                                  touched.youtubeUrl && errors.youtubeUrl
                                }
                                error={
                                  !!touched.youtubeUrl && !!errors.youtubeUrl
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                DESCRIPTION{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                multiline
                                minRows={4}
                                placeholder="Placeholder"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                helperText={
                                  (touched.description && errors.description) ||
                                  'Complete meta description of the app'
                                }
                                error={
                                  !!touched.description && !!errors.description
                                }
                              />
                            </div>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Footer Credits{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                multiline
                                minRows={4}
                                placeholder="Placeholder"
                                name="footerCredits"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.footerCredits}
                                helperText={
                                  touched.footerCredits && errors.footerCredits
                                }
                                error={
                                  !!touched.footerCredits &&
                                  !!errors.footerCredits
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="flex items-center justify-between mt-8">
                          <button
                            onClick={goPreviousTab}
                            className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Back
                          </button>

                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}

            {activeTab === 2 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                  {isLoading ? (
                    <CircularProgress />
                  ) : responseData?.status ? (
                    <Fragment>
                      <TaskAltOutlined
                        className="text-[var(--yellow)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  ) : (
                    !responseData?.status &&
                    responseData?.message && (
                      <Fragment>
                        <GppBadOutlined
                          className="text-[var(--pink)]"
                          sx={{ fontSize: '5rem' }}
                        />
                        <div className="text-[var(--pink)] font-bold text-2xl text-center">
                          {responseData?.message}
                        </div>
                      </Fragment>
                    )
                  )}
                </div>

                {!isLoading && !responseData?.status && (
                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={() => {
                        setResponseData(null);
                        setActiveTab(1);
                      }}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
