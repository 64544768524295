import API from '../config/api.config';

const getAdminStatistics = () => {
  return API.get('/dashboard/admin').then((res) => {
    return res.data;
  });
};

const getUserStatistics = (user_id) => {
  return API.get(`/dashboard/user/${user_id}`).then((res) => {
    return res.data;
  });
};

const getCompanyStatistics = (user_id) => {
  return API.get(`/dashboard/company/${user_id}`).then((res) => {
    return res.data;
  });
};

const getEmployeeStatistics = (user_id) => {
  return API.get(`/dashboard/employee/${user_id}`).then((res) => {
    return res.data;
  });
};

const getOverviewCounts = () => {
  return API.get(`/dashboard/overview`).then((res) => {
    return res.data;
  });
};

const getDashboardTransections = () => {
  return API.get(`/dashboard/transactions`).then((res) => {
    return res.data;
  });
};

const getLandingPageData = () => {
  return API.get(`/misc`).then((res) => {
    return res.data;
  });
};

const DashboardService = {
  getCompanyStatistics,
  getAdminStatistics,
  getUserStatistics,
  getEmployeeStatistics,
  getOverviewCounts,
  getDashboardTransections,
  getLandingPageData,
};

export default DashboardService;
