import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AppLogo, userDetailScreen } from '../assets';
import { Box, Drawer, Grid, IconButton, List, ListItem } from '@mui/material';
import { routesName } from '../constants/routes-name';
import { Menu as MenuIcon } from '@mui/icons-material';

function Component() {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div className="h-screen bg-gray-200 overflow-y-auto">
      <div className="py-6 bg-gradient-to-r from-blue-600 via-blue-500 to-blue-400">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <a href="/">
              <img alt="logo" src={AppLogo} />
              <div className="flex items-center justify-center gap-4"></div>
            </a>
            <div>
              <div className="md:block hidden">
                <div className="flex items-center justify-center ">
                  {/* <Link
                    to="#"
                    className="text-sm text-white text-center px-4 py-2 font-bold text-center cursor-pointer"
                  >
                    Demos
                  </Link>
                  <Link
                    to="#"
                    className="text-sm text-white text-center px-4 py-2 font-bold text-center cursor-pointer"
                  >
                    Features
                  </Link>
                  <Link
                    to="#"
                    className="text-sm text-white text-center px-4 py-2 font-bold text-center cursor-pointer"
                  >
                    Useful Pages
                  </Link>
                  <Link
                    to="#"
                    className="text-sm text-white text-center px-4 py-2 font-bold text-center cursor-pointer"
                  >
                    Pricing
                  </Link> */}
                  <Link
                    to={routesName?.home}
                    className="text-sm text-white px-4 py-2 font-bold text-center cursor-pointer"
                  >
                    Home
                  </Link>
                  <Link
                    to={routesName?.faqs}
                    className="text-sm text-white px-4 py-2 font-bold text-center cursor-pointer"
                  >
                    FAQs
                  </Link>
                  <Link
                    to={routesName.login}
                    className="text-sm text-white border border-1 border-white text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                  >
                    Get Started
                  </Link>
                </div>
              </div>

              <div className="md:hidden block">
                <IconButton id="basic-button" onClick={toggleDrawer(true)}>
                  <MenuIcon className="text-white" />
                </IconButton>
                <Drawer
                  anchor={'right'}
                  open={open}
                  onClose={toggleDrawer(false)}
                  sx={{
                    '& .MuiPaper-root': {
                      backgroundColor: 'var(--blue)',
                    },
                  }}
                >
                  <Box
                    sx={{ width: 250, mt: 6 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                  >
                    <List>
                      {/* <ListItem disablePadding>
                        <Link
                          to="#"
                          className="text-sm text-white hover:text-black hover:bg-blue-200 text-center px-4 py-4 font-bold text-center cursor-pointer w-full"
                        >
                          Demos
                        </Link>
                      </ListItem>

                      <ListItem disablePadding>
                        <Link
                          to="#"
                          className="text-sm text-white hover:text-black hover:bg-blue-200 text-center px-4 py-4 font-bold text-center cursor-pointer w-full"
                        >
                          Features
                        </Link>
                      </ListItem>

                      <ListItem disablePadding>
                        <Link
                          to="#"
                          className="text-sm text-white hover:text-black hover:bg-blue-200 text-center px-4 py-4 font-bold text-center cursor-pointer w-full"
                        >
                          Useful Pages
                        </Link>
                      </ListItem>

                      <ListItem disablePadding>
                        <Link
                          to="#"
                          className="text-sm text-white hover:text-black hover:bg-blue-200 text-center px-4 py-4 font-bold text-center cursor-pointer w-full"
                        >
                          Pricing
                        </Link>
                      </ListItem> */}

                      <ListItem disablePadding>
                        <Link
                          to={routesName?.home}
                          className="text-sm text-white hover:text-black hover:bg-blue-200 text-center px-4 py-4 font-bold cursor-pointer w-full"
                        >
                          Home
                        </Link>
                      </ListItem>

                      <ListItem disablePadding>
                        <Link
                          to={routesName.faqs}
                          className="text-sm text-white hover:text-black hover:bg-blue-200 text-center px-4 py-4 font-bold cursor-pointer w-full"
                        >
                          FAQs
                        </Link>
                      </ListItem>

                      <ListItem className="mt-5" disablePadding>
                        <div className="w-full flex justify-center">
                          <Link
                            to={routesName.login}
                            className="text-sm text-white border border-1 border-white text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Get Started
                          </Link>
                        </div>
                      </ListItem>
                    </List>
                  </Box>
                </Drawer>
              </div>
            </div>
          </div>
          <div className="md:my-12 my-6">
            <Grid container spacing={4}>
              <Grid item md={5} xs={12}>
                <div className="h-full flex flex-col gap-4 justify-center">
                  <div className="font-bold text-2xl text-white">
                    Digital Business Card - <br />
                    Employee Business &amp; vCard Portal
                  </div>
                  <div className="text-lg text-white">
                    Most Powerful &amp; Comprehensive <br />{' '}
                    <b>Business Card Generating</b> Dashboard <br /> built for
                    companies!👨‍💻
                  </div>
                  <div className="ml-2">
                    <div className="text-white">
                      - Based on <b>Card Templates</b>
                    </div>
                    <div className="text-white">
                      - <b>vCard</b> can be downloaded layouts
                    </div>
                  </div>

                  <div className="flex justify-start items-center">
                    <Link
                      to={routesName.login}
                      className="text-md bg-white text-black border border-1 border-[var(--blue)] text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      GetStarted
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item md={7} xs={12}>
                <div className="h-full flex justify-center items-center">
                  <img alt="Modified Image" src={userDetailScreen} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Component;
