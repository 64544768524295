import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Archive, Delete, Details, Edit, Unarchive } from '@mui/icons-material';
import { toAbsoluteURL, useQuery } from '../../constants/shared-methods';
import CompanyService from '../../services/company.service';
import useScreen from '../../hooks/useScreen';
import { initialPagination } from '../../constants/constant-values';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useNotification from '../../hooks/useNotification';
import { routesName } from '../../constants/routes-name';
import { PaginationWrapper } from '../../components';
import EmployeeService from '../../services/employee.service';
import { useSelector } from 'react-redux';

function EmployessTableView({ loading = false }) {
  const { isCompany, isUser } = useSelector(({ user }) => user);
  const { successNonification, errorNonification } = useNotification();
  const navigate = useNavigate();
  const query = useQuery();
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);

  const getAllCompanies = () => {
    EmployeeService.getEmployees(page || 1)
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        setEmployees(data.data);
        pagination.from = data.from;
        pagination.to = data.to;
        pagination.total = data.total;
        pagination.perPage = data.per_page;
        pagination.currentPage = data.current_page;
        pagination.lastPage = data.last_page;
        setPagination({ ...pagination });
      })
      .catch((error) => {
        setEmployees([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getAllCompanies();
    }
  }, [isPageLoaded, page]);

  const handleChangeArchive = (id) => {
    EmployeeService.changeEmployeeArchiveStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Archive status has been changed.');
          getAllCompanies();
        }
      })
      .catch(() => errorNonification('Something went wrong'));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        EmployeeService.deleteEmployee(id)
          .then((res) => {
            if (res.success) {
              getAllCompanies();
              successNonification('Employee has been deleted.');
            }
          })
          .catch(() => errorNonification('Something went wrong'));
      }
    });
  };

  const handleStatus = (id) => {
    EmployeeService.changeStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Status changed.');
          getAllCompanies();
        }
      })
      .catch((error) => errorNonification('Something went wrong'));
  };

  const columns = [
    {
      field: 'photo',
      headerName: 'LIGHT LOGO',
      flex: 0.5,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center text-[var(--gray)]">
          <Avatar alt="light_logo" src={toAbsoluteURL(params?.row?.photo)} />
        </div>
      ),
    },
    {
      field: 'name',
      sortable: false,
      headerName: 'NAME',
      minWidth: 200,
      flex: 0.8,
    },
    {
      field: 'email',
      sortable: false,
      headerName: 'EMAIL',
      minWidth: 210,
      flex: 1,
    },
    /* {
      field: 'website_url',
      sortable: false,
      headerName: 'WEBSITE',
      minWidth: 100,
      flex: 0.4,
    }, */
    {
      field: 'status',
      headerName: 'STATUS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center justify-center text-[var(--gray)]">
          {params.row.status ? (
            <span
              onClick={() => handleStatus(params.row.employeeID)}
              className="cursor-pointer bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Active
            </span>
          ) : (
            <span
              onClick={() => handleStatus(params.row.employeeID)}
              className="cursor-pointer bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Inactive
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 180,
      flex: 0.8,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Detail">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(
                    `${routesName.employeeDetailPath}${params?.row?.employeeID}`
                  );
                }}
              >
                <Details />
              </IconButton>
            </Tooltip>
            {(isCompany || isUser) && (
              <Tooltip slotProps={tooltipSlotStyle} arrow title="Edit">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(
                      `${routesName.editEmployeePath}${params?.row?.employeeID}`
                    );
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              slotProps={tooltipSlotStyle}
              arrow
              title={params?.row?.archived ? 'Unarchive' : 'Archive'}
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleChangeArchive(params?.row?.employeeID, params.id);
                }}
              >
                {params?.row?.archived ? <Unarchive /> : <Archive />}
              </IconButton>
            </Tooltip>
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(params?.row?.employeeID, params.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows =
    employees &&
    Array.isArray(employees) &&
    employees.length > 0 &&
    employees.map((item, index) => ({
      archived: item?.archived,
      city_id: item?.city_id,
      companyName: item?.companyName,
      company_id: item?.company_id,
      country_id: item?.country_id,
      created_at: item?.created_at,
      deleted_at: item?.deleted_at,
      designation: item?.designation,
      email: item?.email,
      email_verified_at: item?.email_verified_at,
      employeeID: item?.employeeID,
      facebook_url: item?.facebook_url,
      id: item?.id,
      instagram_username: item?.instagram_username,
      is_email_verified: item?.is_email_verified,
      name: item?.name,
      phone: item?.phone,
      photo: item?.photo,
      role_id: item?.role_id,
      self_description: item?.self_description,
      skype_id: item?.skype_id,
      slug: item?.slug,
      state_id: item?.state_id,
      status: item?.status,
      street_address: item?.street_address,
      twitter_link: item?.twitter_link,
      updated_at: item?.updated_at,
      user_id: item?.user_id,
      zip_code: item?.zip_code,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.employessListing}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default memo(EmployessTableView);

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};
