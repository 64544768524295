import * as MaterialIcons from '@mui/icons-material';

export const cardMaterialIconsList = [
  'Person',
  'Person2',
  'Person2Outlined',
  'Person3',
  'Person3Outlined',
  'Face',
  'FaceOutlined',
  'AddIcCall',
  'AddIcCallOutlined',
  'Call',
  'CallOutlined',
  'CallEnd',
  'CallEndOutlined',
  'WhatsApp',
  'Mail',
  'MailOutline',
  'Drafts',
  'DraftsOutlined',
  'Place',
  'PlaceOutlined',
  'MyLocation',
  'Apartment',
  'Link',
  'LinkOutlined',
  'Share',
  'ShareOutlined',
  'Public',
  'Language',
  'NearMe',
  'NearMeOutlined',
  'RssFeed',
  'MilitaryTech',
  'MilitaryTechOutlined',
];

export const getMaterialIconForCard = (icon = '', iconStyle = {}) => {
  var style = { ...iconStyle };
  if (iconStyle.fontSize) {
    style.fontSize = `${iconStyle.fontSize}px`;
  }

  if (icon == 'Person') {
    return <MaterialIcons.Person sx={style} size="small" />;
  } else if (icon == 'Person2') {
    return <MaterialIcons.Person2 sx={style} size="small" />;
  } else if (icon == 'Person2Outlined') {
    return <MaterialIcons.Person2Outlined sx={style} size="small" />;
  } else if (icon == 'Person3') {
    return <MaterialIcons.Person3 sx={style} size="small" />;
  } else if (icon == 'Person3Outlined') {
    return <MaterialIcons.Person3Outlined sx={style} size="small" />;
  } else if (icon == 'Face') {
    return <MaterialIcons.Face sx={style} size="small" />;
  } else if (icon == 'FaceOutlined') {
    return <MaterialIcons.FaceOutlined sx={style} size="small" />;
  } else if (icon == 'AddIcCall') {
    return <MaterialIcons.AddIcCall sx={style} size="small" />;
  } else if (icon == 'AddIcCallOutlined') {
    return <MaterialIcons.AddIcCallOutlined sx={style} size="small" />;
  } else if (icon == 'Call') {
    return <MaterialIcons.Call sx={style} size="small" />;
  } else if (icon == 'CallOutlined') {
    return <MaterialIcons.CallOutlined sx={style} size="small" />;
  } else if (icon == 'CallEnd') {
    return <MaterialIcons.CallEnd sx={style} size="small" />;
  } else if (icon == 'CallEndOutlined') {
    return <MaterialIcons.CallEndOutlined sx={style} size="small" />;
  } else if (icon == 'WhatsApp') {
    return <MaterialIcons.WhatsApp sx={style} size="small" />;
  } else if (icon == 'Mail') {
    return <MaterialIcons.Mail sx={style} size="small" />;
  } else if (icon == 'MailOutline') {
    return <MaterialIcons.MailOutline sx={style} size="small" />;
  } else if (icon == 'Drafts') {
    return <MaterialIcons.Drafts sx={style} size="small" />;
  } else if (icon == 'DraftsOutlined') {
    return <MaterialIcons.DraftsOutlined sx={style} size="small" />;
  } else if (icon == 'Place') {
    return <MaterialIcons.Place sx={style} size="small" />;
  } else if (icon == 'PlaceOutlined') {
    return <MaterialIcons.PlaceOutlined sx={style} size="small" />;
  } else if (icon == 'MyLocation') {
    return <MaterialIcons.MyLocation sx={style} size="small" />;
  } else if (icon == 'MyLocationOutlined') {
    return <MaterialIcons.MyLocationOutlined sx={style} size="small" />;
  } else if (icon == 'Apartment') {
    return <MaterialIcons.Apartment sx={style} size="small" />;
  } else if (icon == 'Link') {
    return <MaterialIcons.Link sx={style} size="small" />;
  } else if (icon == 'LinkOutlined') {
    return <MaterialIcons.LinkOutlined sx={style} size="small" />;
  } else if (icon == 'Share') {
    return <MaterialIcons.Share sx={style} size="small" />;
  } else if (icon == 'ShareOutlined') {
    return <MaterialIcons.ShareOutlined sx={style} size="small" />;
  } else if (icon == 'Public') {
    return <MaterialIcons.Public sx={style} size="small" />;
  } else if (icon == 'Language') {
    return <MaterialIcons.Language sx={style} size="small" />;
  } else if (icon == 'NearMe') {
    return <MaterialIcons.NearMe sx={style} size="small" />;
  } else if (icon == 'NearMeOutlined') {
    return <MaterialIcons.NearMeOutlined sx={style} size="small" />;
  } else if (icon == 'RssFeed') {
    return <MaterialIcons.RssFeed sx={style} size="small" />;
  } else if (icon == 'MilitaryTech') {
    return <MaterialIcons.MilitaryTech sx={style} size="small" />;
  } else if (icon == 'MilitaryTechOutlined') {
    return <MaterialIcons.MilitaryTechOutlined sx={style} size="small" />;
  } else return <></>;
};

export const ItemTypes = {
  BOX: 'box',
};

export const landscapeCardSize = {
  width: '400px',
  height: '220px',
};

export const verticalCardSize = {
  width: '240px',
  height: '400px',
};
