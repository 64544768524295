import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppLogo } from '../assets';
import { useSelector } from 'react-redux';
import { routesName } from '../constants/routes-name';

function Component() {
  const { user } = useSelector(({ user }) => user);
  if (user) {
    return <Navigate to={routesName.dashboard} replace={true} />;
  }
  return (
    <div className="flex flex-column bg-gray-200 h-screen overflow-y-auto">
      <div className="flex-1 flex flex-col items-center justify-center">
        <div className="max-w-96 sm:w-96 bg-white rounded-2xl px-6 py-6 bg-main text-white">
          <div className="flex flex-col items-center justify-center gap-4">
            <img alt="logo" src={AppLogo} />
            <div className="text-xl font-bold"> Digital Face Card</div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Component;
