import { Box, Grid } from '@mui/material';
import React from 'react';
import QRCode from 'react-qr-code';
import { toAbsoluteURL } from '../constants/shared-methods';
import { routesName } from '../constants/routes-name';
import { Link, useLocation } from 'react-router-dom';

function Component({ companyData = {}, userDetail = {} }) {
  const location = useLocation();

  return (
    <div className="">
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Box
            sx={{ flexDirection: { md: 'column', xs: 'row' } }}
            className="flex gap-4 flex-wrap items-center justify-center"
          >
            {companyData?.photo && (
              <img
                width={100}
                alt="company light logo"
                src={toAbsoluteURL(companyData?.photo)}
              />
            )}
            {companyData?.dark_logo && (
              <img
                width={100}
                alt="company dark logo"
                src={toAbsoluteURL(companyData?.dark_logo)}
              />
            )}
            <QRCode
              size={100}
              value={
                window.location.origin + routesName.viewCompanyPath + companyData.guid
              }
              viewBox={`0 0 256 256`}
            />
            {
              !location.pathname.includes('/view/company/detail') && <Link
                to={window.location.origin + routesName.viewCompanyPath + companyData.guid}
                target='_blank'
                state={null}
                className="text-xs bg-second text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                View Company
              </Link>
            }

          </Box>
        </Grid>
        <Grid item md={9} xs={12} container spacing={3}>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              Title
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {companyData.name}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              EMAIL
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {companyData.email}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              PHONE
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {companyData.phone}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              ADDRESS
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {(companyData.street_address ? companyData.street_address : '') +
                (companyData.city_name ? ', ' + companyData.city_name : '') +
                (companyData.country_name
                  ? ', ' + companyData.country_name
                  : '')}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              SLOGAN
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {companyData.slogan}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              WEBSITE
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              <a href={companyData?.website_url} target="_blank">
                {companyData?.website_url}
              </a>
            </Grid>
          </Grid>

          {userDetail?.name && (
            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                REGISTERED BY
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                <a href={companyData?.website_url} target="_blank">
                  {userDetail?.name}
                </a>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Component;
