import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
/* import { me } from '../services/api/ApiManager'; */
import {
  saveUser,
  saveCompany,
  saveEmployee,
  saveIsCompany,
  saveIsEmployee,
  saveIsUser,
} from '../store/slices/userSlices';
import AuthService from '../services/auth.service';
import { Box, CircularProgress } from '@mui/material';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) {
      new Promise((resolve, reject) => {
        /* setTimeout(() => {
          return success({
            name: 'Ubaid',
            email: 'ubaid@gmail.com',
            role: 'admin',
            id: 12,
          });
        }, 1500); */
        AuthService.me()
          .then(async (res) => {
            if (res.success === true) {
              success(res?.data);
            } else {
              pass(res);
            }
          })
          .catch((error) => {
            pass(error);
          });
      }, []);
    }

    function success(data) {
      Promise.all([
        dispatch(saveUser(data?.user || null)),
        dispatch(saveCompany(data?.company || null)),
        dispatch(saveEmployee(data?.employee || null)),
        dispatch(saveIsCompany(data?.is_company || false)),
        dispatch(saveIsEmployee(data?.is_employee || false)),
        dispatch(saveIsUser(data?.is_user || false)),
        // You can receive data in here before app initialization
      ]).then((values) => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message) {
      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch, isLoading]);

  useEffect(() => {
    setIsLoading(false);
  }, []);


  return waitAuthCheck ? (
    <div className='flex w-screen h-screen justify-center items-center'>
      <div class="spinner"></div>
    </div>
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProviderComponent');
  }
  return context;
}

export { AuthProvider, useAuth };
