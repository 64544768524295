import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { DesignServicesOutlined } from '@mui/icons-material';
import { Step, StepLabel, Stepper } from '@mui/material';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'var(--blue)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'var(--blue)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: 'var(--gray)',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: '#fff',
  border: '2px solid var(--gray)',
  zIndex: 1,
  color: 'var(--gray)',
  width: 54,
  height: 54,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#fff',
    border: '2px solid var(--yellow)',
    color: 'var(--yellow)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#fff',
    border: '2px solid var(--blue)',
    color: 'var(--blue)',
  }),
}));

function ColorlibStepIcon(props) {
  const { icons, active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons}
      {/* {icons[String(props.icon)]} */}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function Component({
  steps = [],
  icon = <DesignServicesOutlined fontSize="medium" />,
  activeStep = 0,
}) {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      {steps &&
        Array.isArray(steps) &&
        steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon {...props} icons={icon} />
              )}
              sx={{
                '& .MuiStepLabel-label': {
                  marginTop: '4px !important',
                  fontSize: '14px',
                },
                '& .Mui-active': {
                  fontWeight: 'bold !important',
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  );
}
