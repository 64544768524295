import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    company: null,
    employee: null,
    isCompany: false,
    isEmployee: false,
    isUser: false,
  },
  reducers: {
    saveUser: (state, action) => {
      state.user = action.payload;
    },
    saveCompany: (state, action) => {
      state.company = action.payload;
    },
    saveEmployee: (state, action) => {
      state.employee = action.payload;
    },
    saveIsCompany: (state, action) => {
      state.isCompany = action.payload;
    },
    saveIsEmployee: (state, action) => {
      state.isEmployee = action.payload;
    },
    saveIsUser: (state, action) => {
      state.isUser = action.payload;
    },
  },
});

export const {
  saveUser,
  saveCompany,
  saveEmployee,
  saveIsCompany,
  saveIsEmployee,
  saveIsUser,
} = userSlice.actions;

export default userSlice.reducer;
