import API from '../config/api.config';

const renewBilliing = (data) => {
  return API.post(`/subscription/renew`, data).then((res) => {
    return res.data;
  });
};

const upgradeBilliing = (data) => {
    return API.patch(`/subscription/upgrade`, data).then((res) => {
      return res.data;
    });
  };

const SubscriptionService = {
    renewBilliing,
    upgradeBilliing
}


export default SubscriptionService