import { useSnackbar } from 'notistack';

function useNotification() {
  const { enqueueSnackbar } = useSnackbar();

  const successNonification = (message = '') => {
    enqueueSnackbar(message || '', {
      variant: 'success',
      autoHideDuration: 5000,
    });
  };

  const errorNonification = (message = '') => {
    enqueueSnackbar(message || '', {
      variant: 'error',
      autoHideDuration: 5000,
    });
  };

  const warningNonification = (message = '') => {
    enqueueSnackbar(message || '', {
      variant: 'warning',
      autoHideDuration: 5000,
    });
  };

  const infoNonification = (message = '') => {
    enqueueSnackbar(message || '', {
      variant: 'info',
      autoHideDuration: 5000,
    });
  };

  const defaultNonification = (message = '') => {
    enqueueSnackbar(message || '', {
      variant: 'default',
      autoHideDuration: 5000,
    });
  };

  return {
    successNonification,
    errorNonification,
    warningNonification,
    infoNonification,
    defaultNonification,
  };
}

export default useNotification;
