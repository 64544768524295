import React, { useEffect, useState } from 'react';
import { CompanyDetailsTab } from '../components';
import { useParams } from 'react-router-dom';
import CompanyService from '../services/company.service';
import useScreen from '../hooks/useScreen';
import useNotification from '../hooks/useNotification';
import { LinearProgress } from '@mui/material';

function ViewCompany() {
  const params = useParams();
  const { isPageLoaded } = useScreen();
  const { errorNonification } = useNotification();
  const [company, setCompany] = useState({});
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getCompany = () => {
    if (params?.id) {
      setIsLoading(true);
      CompanyService.getPublicCompanyDetail(params.id)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setCompany(res.data.company);
            setUserData(res.data.user);
          } else {
            errorNonification('Something went wrong');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          errorNonification(
            error?.response?.data?.message || 'Something went wrong'
          );
        });
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      getCompany();
    }
  }, [isPageLoaded]);

  return (
    <div className="container mx-auto px-4 flex flex-col gap-8">
      <div className="py-12 flex flex-col md:gap-12 gap-6">
        <div className="text-center">
          <div className="text-3xl font-bold">Company Detail</div>
        </div>
        <div className="bg-white lg:px-12 md:px-6 px-4 lg:py-12 md:py-6 py-4 rounded-lg">
          {!isLoading ? (
            <CompanyDetailsTab companyData={company} userDetail={userData} />
          ) : (
            <div className="my-24 mx-auto max-w-[500px]">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewCompany;
