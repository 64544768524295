import { Avatar, Box, Grid } from '@mui/material';
import React from 'react';
import { toAbsoluteURL } from '../constants/shared-methods';

function Component({ employeeData = {}, userDetail = {} }) {
  console.log(employeeData);
  return (
    <div className="">
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Box
            sx={{ flexDirection: { md: 'column', xs: 'row' } }}
            className="flex gap-4 flex-wrap items-center justify-center"
          >
            <Avatar
              sx={{ height: '100px', width: '100px' }}
              alt="company light logo"
              src={toAbsoluteURL(employeeData?.photo)}
            />
          </Box>
        </Grid>
        <Grid item md={9} xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                Name
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                {employeeData.name}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                EMAIL
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                {employeeData.email}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                PHONE
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                {employeeData.phone}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                DESIGNATION
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                {employeeData.designation}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                ADDRESS
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                {(employeeData.street_address
                  ? employeeData.street_address
                  : '') +
                  (employeeData.city_name
                    ? ', ' + employeeData.city_name
                    : '') +
                  (employeeData.country_name
                    ? ', ' + employeeData.country_name
                    : '')}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                REGISTERED BY
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                <a href={employeeData?.website_url} target="_blank">
                  {userDetail?.name}
                </a>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={1}
              className="border-b border-b-4"
            >
              <Grid item sm={4} xs={12} className="font-semibold text-sm">
                PERSONAL DESCRIPTION
              </Grid>
              <Grid item sm={8} xs={12} className="text-sm">
                <a href={employeeData?.website_url} target="_blank">
                  {employeeData?.self_description}
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Component;
