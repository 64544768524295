import * as Layout from '../layouts';
import * as Pages from '../config/pagesConfig';
import { routesName } from '../constants/routes-name';

export const appRoutes = [
  {
    Component: Layout.AuthLayout,
    children: [
      {
        path: routesName.login,
        Component: Pages.LoginPage,
      },
      {
        path: routesName.signup,
        Component: Pages.SignupPage,
      },
      {
        path: routesName.forgotPassword,
        Component: Pages.ForgotPassword,
      },
      {
        path: routesName.resetPassword,
        Component: Pages.ResetPassword,
      },
      {
        path: routesName.verifyAccount,
        Component: Pages.VerifyAccount,
      },
    ],
  },
  {
    Component: Layout.LandingPageLayout,
    children: [
      {
        path: routesName.home,
        Component: Pages.LandingPage,
      },
      {
        path: routesName.viewCard,
        Component: Pages.ViewCard,
      },
      {
        path: routesName.viewCompany,
        Component: Pages.ViewCompany,
      },
      {
        path: routesName.faqs,
        Component: Pages.FAQsPage,
      },
    ],
  },
  {
    Component: Layout.DashboardLayout,
    children: [
      {
        path: routesName.appSetting,
        Component: Pages.AppSetting,
      },
      {
        path: routesName.paymentSetting,
        Component: Pages.PaymentSetting,
      },
      {
        path: routesName.faqsSetting,
        Component: Pages.FAQsSetting,
      },
      {
        path: routesName.addFAQs,
        Component: Pages.AddFAQs,
      },
      {
        path: routesName.editFAQs,
        Component: Pages.AddFAQs,
      },
      {
        path: routesName.usersListing,
        Component: Pages.UsersListing,
      },
      {
        path: routesName.usersDetail,
        Component: Pages.UserDetail,
      },
      {
        path: routesName.createCompany,
        Component: Pages.AddCompany,
      },
      {
        path: routesName.editCompany,
        Component: Pages.EditCompany,
      },
      {
        path: routesName.companyDetail,
        Component: Pages.CompanyDetails,
      },
      {
        path: routesName.companyListing,
        Component: Pages.CompanyListing,
      },
      {
        path: routesName.createEmployee,
        Component: Pages.AddEmployee,
      },
      {
        path: routesName.employeeDetail,
        Component: Pages.EmployeeDetail,
      },
      {
        path: routesName.editEmployee,
        Component: Pages.EditEmployee,
      },
      {
        path: routesName.employessListing,
        Component: Pages.EmployessListing,
      },
      {
        path: routesName.dashboard,
        Component: Pages.DashboardPages,
      },
      {
        path: routesName.templates,
        Component: Pages.TemplateList,
      },
      {
        path: routesName.cardsListing,
        Component: Pages.CardListing,
      },
      {
        path: routesName.createCard,
        Component: Pages.AddNewCard,
      },
      {
        path: routesName.editCard,
        Component: Pages.AddNewCard,
      },
      {
        path: routesName.cardDetail,
        Component: Pages.CardDetail,
      },
      {
        path: routesName.package,
        Component: Pages.PackageListing,
      },
      {
        path: routesName.createPackage,
        Component: Pages.AddPackage,
      },
      {
        path: routesName.editPackage,
        Component: Pages.AddPackage,
      },
      {
        path: routesName.packageDetail,
        Component: Pages.PackageDetail,
      },
      {
        path: routesName.billing,
        Component: Pages.BillingListing,
      },
      {
        path: routesName.pricing,
        Component: Pages.Pricing,
      },
      {
        path: routesName.createTemplate,
        Component: Pages.CreateTemplate,
      },
      {
        path: routesName.editTemplate,
        Component: Pages.CreateTemplate,
      },
      {
        path: routesName.profile,
        Component: Pages.MyProfile,
      },
      {
        path: routesName.editProfile,
        Component: Pages.EditProfile,
      },
    ],
  },
];
