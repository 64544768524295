import {
  Avatar,
  Backdrop,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Details } from '@mui/icons-material';
import { toAbsoluteURL, useQuery } from '../../constants/shared-methods';
import useScreen from '../../hooks/useScreen';
import { initialPagination } from '../../constants/constant-values';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../hooks/useNotification';
import { routesName } from '../../constants/routes-name';
import { PaginationWrapper } from '../../components';
import CompanyUserService from '../../services/companyUser.service';

function UsersTableView({ loading = false }) {
  const { successNonification, errorNonification } = useNotification();
  const navigate = useNavigate();
  const query = useQuery();
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);

  const getAllUsers = () => {
    CompanyUserService.getUsersList(page || 1)
      .then((res) => {
        setIsLoading(false);
        if (res?.data) {
          const data = res.data;
          setUsers(data.data);
          pagination.from = data.from;
          pagination.to = data.to;
          pagination.total = data.total;
          pagination.perPage = data.per_page;
          pagination.currentPage = data.current_page;
          pagination.lastPage = data.last_page;
          setPagination({ ...pagination });
        }
      })
      .catch((error) => {
        setUsers([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getAllUsers();
    }
  }, [isPageLoaded, page]);

  const handleStatus = (id) => {
    CompanyUserService.changeUserStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Status changed.');
          getAllUsers();
        }
      })
      .catch((error) => errorNonification('Something went wrong'));
  };

  const columns = [
    {
      field: 'photo',
      headerName: 'Picture',
      flex: 0.5,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center text-[var(--gray)]">
          <Avatar alt="light_logo" src={toAbsoluteURL(params?.row?.photo)} />
        </div>
      ),
    },
    {
      field: 'name',
      sortable: false,
      headerName: 'NAME',
      minWidth: 200,
      flex: 0.8,
    },
    {
      field: 'email',
      sortable: false,
      headerName: 'EMAIL',
      minWidth: 210,
      flex: 1,
    },

    {
      field: 'phone',
      sortable: false,
      headerName: 'PHONE',
      minWidth: 190,
      flex: 0.8,
    },
    {
      field: 'slogan',
      sortable: false,
      headerName: 'SLOGAN',
      minWidth: 150,
      flex: 0.5,
    },

    {
      field: 'status',
      headerName: 'STATUS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center justify-center text-[var(--gray)] select-none">
          {params.row.is_email_verified === 0 ? (
            <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
              Not Verified
            </span>
          ) : params.row.status ? (
            <span
              onClick={() => handleStatus(params.row.id)}
              className="cursor-pointer bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Active
            </span>
          ) : (
            <span
              onClick={() => handleStatus(params.row.id)}
              className="cursor-pointer bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Inactive
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 80,
      flex: 0.4,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Detail">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`${routesName.usersDetailPath}${params?.row?.id}`);
                }}
              >
                <Details />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows =
    users &&
    Array.isArray(users) &&
    users.length > 0 &&
    users.map((item, index) => ({
      id: item?.id,
      phone: item?.phone,
      photo: item?.photo,
      name: item?.name,
      email: item?.email,
      slogan: item?.slogan,
      status: item?.status,
      archived: item?.archived,
      is_email_verified: item?.is_email_verified,
      role_id: item?.role_id,
      slug: item?.slug,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
            '&.MuiDataGrid-root': {
              width: '100%',
            },
          }}
          style={{}}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.usersListing}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default memo(UsersTableView);

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};
