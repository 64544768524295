import API from '../config/api.config';

const getPublicFaqs = (pageNo) => {
  return API.get(`/public-faqs?page=${pageNo}`).then((res) => {
    return res.data;
  });
};

const getWebsitePublicFaqs = (limit) => {
  return API.get(`/publicFaqs/${limit}`).then((res) => {
    return res.data;
  });
};

const getFaqs = (pageNo) => {
  return API.get(`/faqDetail?page=${pageNo}`).then((res) => {
    return res.data;
  });
};


const saveFaq = (data) => {
  return API.post(`faqDetail`, data).then((res) => {
    return res.data;
  });
};

const updateFaq = (id, data) => {
  return API.put(`/faqDetail/${id}`, data).then((res) => {
    return res.data;
  });
};

const getFaq = (id) => {
  return API.get(`/faqDetail/${id}`).then((res) => {
    return res.data;
  });
};


const deleteFaq = (id) => {
  return API.delete(`/faqDetail/${id}`).then((res) => {
    return res.data;
  });
};

const getFAQsByType = (type) => {
  return API.get(`/public-faqs/${type}`).then((res) => {
    return res.data;
  });
};

const FaqService = {
  getWebsitePublicFaqs,
  getFAQsByType,
  deleteFaq,
  updateFaq,
  getFaq,
  getFaqs,
  saveFaq,
  getPublicFaqs
};

export default FaqService;