import React, { useCallback, useState } from 'react';
import update from 'immutability-helper';
import { useDrop } from 'react-dnd';
import { MovableComponent } from './MovableComponent';
import { Box, FormControl, MenuItem, Modal, Select } from '@mui/material';
import {
  ItemTypes,
  cardMaterialIconsList,
  getMaterialIconForCard,
  landscapeCardSize,
  verticalCardSize,
} from '../../../constants/card-data.js';
import { InputMaskWrapper, SwitchWrapper } from '../../../components/index.js';
import { defaultCardBG } from '../../../assets/index.js';

const styles = {
  border: '1px solid black',
  position: 'relative',
  borderRadius: '10px',
  zIndex: 1000,
};

const DesignCard = ({
  setBg,
  setPhoto,
  photo,
  cardComponent,
  setCardComponent,
  cardBackground,
  setCardBackground,
  availableComponent,
  setAvailableComponent,
  photoPreview,
  setPhotoPreview,
  selectedComponent,
  setSelectedComponent,
  modal,
  setModal,
  modalData,
  setModalData,
  additionalCardProperties,
  setAdditionalCardProperties,
  orientation = 'l',
  showEditorLines = true,
}) => {
  console.log('additionalCardProperties=>', additionalCardProperties);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFilePreview, setUploadedFilePreview] = useState(photoPreview);

  const toggle = () => {
    setModal(!modal);
    setSelectedComponent({});
    setModalData({});
    setAdditionalCardProperties({});
    //setPhoto(null);
    // setPhotoPreview(null);
  };

  const cardStyle = orientation === 'l' ? landscapeCardSize : verticalCardSize;

  const handleImageChange = (e) => {
    let file = e.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + ' kB',
        base64: reader.result,
        file: file,
      };
      //setPhoto(file);
      //setPhotoPreview(fileInfo.base64);
      setUploadedFile(file);
      setUploadedFilePreview(fileInfo.base64);
    };
  };

  const moveBox = useCallback(
    (id, left, top) => {
      setCardComponent(
        update(cardComponent, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [cardComponent, setCardComponent]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  const saveModalData = () => {
    // console.log('style=>', modalData);
    if (selectedComponent?.name === 'card_background') {
      setCardBackground({
        ...cardBackground,
        style: {
          ...cardBackground.style,
          backgroundColor: modalData.backgroundColor,
        },
      });
      setBg(photo);
      setPhoto(uploadedFile);
      setPhotoPreview(uploadedFilePreview);
    } else {
      cardComponent.map((item, index) => {
        if (item.id === selectedComponent.id) {
          cardComponent[index] = {
            ...item,
            ...additionalCardProperties,
            style: modalData,
          };
        }
      });
      setCardComponent([...cardComponent]);
    }
    toggle();
  };

  return (
    <>
      <div className="overflow-auto">
        <div
          ref={drop}
          style={{
            ...styles,
            ...cardStyle,
            background: photoPreview ? `url("${photoPreview}")` : 'white',
            overflow: 'hidden',
          }}
        >
          <div
            className={`${showEditorLines ? 'card-editor-background' : ''}`}
            style={{
              ...styles,
              ...cardStyle,
              border: 'none',
              height: '100%',
              width: '100%',
            }}
          >
            {cardComponent.map(
              ({
                id,
                top,
                left,
                style,
                title,
                name,
                icon = '',
                iconStyle = {},
                showBackground = false,
              }) => {
                return (
                  <MovableComponent
                    style={style}
                    key={id}
                    id={id}
                    left={left}
                    top={top}
                    name={name}
                    icon={icon}
                    iconStyle={iconStyle}
                    showBackground={showBackground}
                  >
                    {title}
                  </MovableComponent>
                );
              }
            )}
          </div>
        </div>
      </div>
      <Modal open={modal} onClose={toggle}>
        <Box sx={style}>
          <div className="text-lg font-bold">{selectedComponent?.title}</div>
          <div className="border-b border-2 border-b-gray-500"></div>
          <div className="mt-3 text-sm">
            {selectedComponent?.name === 'card_background' ? (
              <>
                <div className="flex items-center gap-2">
                  <label htmlFor="backgroundColor" className="font-semibold">
                    Background Color
                  </label>
                  <input
                    style={{ width: '50px', height: '30px' }}
                    type="color"
                    id="backgroundColor"
                    className="form-control p-0"
                    placeholder="#000000"
                    value={modalData.backgroundColor}
                    onChange={(e) => {
                      setModalData({
                        ...modalData,
                        backgroundColor: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-2">
                  <label
                    className="font-semibold"
                    htmlFor="cardBackgroundImage"
                  >
                    Background Image
                  </label>
                  <div className="flex justify-center items-center flex-col gap-3">
                    <div id="profile-container">
                      <img
                        id="profileImage"
                        style={{ width: '400px', height: '250px' }}
                        src={
                          uploadedFilePreview
                            ? uploadedFilePreview
                            : defaultCardBG
                        }
                        alt="BG-IMG"
                      />
                    </div>
                    <input
                      id="cardBackgroundImage"
                      className="hidden"
                      type="file"
                      value={''}
                      accept="image/png, image/jpeg"
                      name="photo"
                      placeholder="Photo"
                      onChange={(e) => handleImageChange(e)}
                    />
                    <div className="d-flex gap-3">
                      <label
                        className="bg-[var(--yellow)] px-4 py-2 rounded-md cursor-pointer"
                        htmlFor="cardBackgroundImage"
                      >
                        Choose File
                      </label>

                      {photo && (
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => {
                            setPhoto(null);
                            setPhotoPreview(null);
                          }}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row mb-3 gap-2 grid sm:grid-cols-2 grid-cols-1">
                  {selectedComponent?.name !== 'profile_picture' &&
                    selectedComponent?.name !== 'qr_code' &&
                    selectedComponent?.name !== 'items_icon' && (
                      <div className="w-full flex gap-2 flex-col">
                        <label
                          htmlFor="email"
                          className="font-semibold text-xs"
                        >
                          Font Size <span className="text-red-600">(px)</span>
                        </label>
                        <InputMaskWrapper
                          mask="99"
                          type="number"
                          id="fontSize"
                          className="form-control"
                          placeholder="0"
                          value={modalData.fontSize}
                          onChange={(e) => {
                            setModalData({
                              ...modalData,
                              fontSize: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}

                  {selectedComponent?.name !== 'profile_picture' &&
                    selectedComponent?.name !== 'qr_code' &&
                    selectedComponent?.name !== 'items_icon' && (
                      <div className="w-full flex gap-2 flex-col">
                        <label
                          htmlFor="password"
                          className="font-semibold text-xs"
                        >
                          Width <span className="text-red-600">(%)</span>
                        </label>
                        <InputMaskWrapper
                          mask="99"
                          type="number"
                          id="width"
                          className="form-control"
                          placeholder="0"
                          value={modalData.width}
                          onChange={(e) => {
                            setModalData({
                              ...modalData,
                              width: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}
                  {selectedComponent?.name !== 'profile_picture' &&
                    selectedComponent?.name !== 'qr_code' &&
                    selectedComponent?.name !== 'items_icon' && (
                      <div className="w-full flex gap-2 flex-col">
                        <label
                          htmlFor="password"
                          className="font-semibold text-xs"
                        >
                          Rotate <span className="text-red-600">(deg)</span>
                        </label>
                        <InputMaskWrapper
                          mask="999"
                          type="number"
                          id="width"
                          className="form-control"
                          placeholder="000"
                          value={modalData.rotate}
                          onChange={(e) => {
                            setModalData({
                              ...modalData,
                              rotate: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}

                  {selectedComponent?.name !== 'profile_picture' &&
                    selectedComponent?.name !== 'qr_code' &&
                    selectedComponent?.name !== 'items_icon' && (
                      <div className="w-full flex gap-2 flex-col">
                        <label
                          htmlFor="password"
                          className="font-semibold text-xs"
                        >
                          Text Align
                        </label>

                        <FormControl size="small" fullWidth>
                          <Select
                            value={modalData.textAlign}
                            onChange={(e) => {
                              setModalData({
                                ...modalData,
                                textAlign: e.target.value,
                              });
                            }}
                          >
                            <MenuItem value={'start'}>Start</MenuItem>
                            <MenuItem value={'center'}>Center</MenuItem>
                            <MenuItem value={'justify'}>Justify</MenuItem>
                            <MenuItem value={'end'}>End</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    )}

                  {(selectedComponent?.name === 'profile_picture' ||
                    selectedComponent?.name === 'items_icon' ||
                    selectedComponent?.name === 'qr_code') && (
                    <div className="w-full flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Height And Width{' '}
                        <span className="text-red-600">(px)</span>
                      </label>
                      <InputMaskWrapper
                        mask="99"
                        type="number"
                        id="height"
                        className="form-control"
                        placeholder="0"
                        value={modalData.height}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            height: e.target.value,
                            width: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}

                  {selectedComponent?.name === 'items_icon' && (
                    <div className="w-full flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Icon Size
                        <span className="text-red-600">(px)</span>
                      </label>
                      <InputMaskWrapper
                        mask="99"
                        type="number"
                        id="height"
                        className="form-control"
                        placeholder="0"
                        value={additionalCardProperties?.iconStyle.fontSize}
                        onChange={(e) => {
                          setAdditionalCardProperties({
                            ...additionalCardProperties,
                            iconStyle: {
                              ...additionalCardProperties.iconStyle,
                              fontSize: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  )}
                </div>

                {selectedComponent?.name === 'items_icon' && (
                  <div className="flex flex-col sm:flex-row mb-3 gap-2 grid sm:grid-cols-2 grid-cols-1">
                    <div className="w-full flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Border Top Left Radius
                        <span className="text-red-600">(px)</span>
                      </label>
                      <InputMaskWrapper
                        mask="99"
                        type="number"
                        id="height"
                        className="form-control"
                        placeholder="0"
                        value={modalData.borderTopLeftRadius}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            borderTopLeftRadius: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="w-full flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Border Bottom Left Radius
                        <span className="text-red-600">(px)</span>
                      </label>
                      <InputMaskWrapper
                        mask="99"
                        type="number"
                        id="height"
                        className="form-control"
                        placeholder="0"
                        value={modalData.borderBottomLeftRadius}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            borderBottomLeftRadius: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="w-full flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Border Top Right Radius
                        <span className="text-red-600">(px)</span>
                      </label>
                      <InputMaskWrapper
                        mask="99"
                        type="number"
                        id="height"
                        className="form-control"
                        placeholder="0"
                        value={modalData.borderTopRightRadius}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            borderTopRightRadius: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="w-full flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Border Bottom Right Radius
                        <span className="text-red-600">(px)</span>
                      </label>
                      <InputMaskWrapper
                        mask="99"
                        type="number"
                        id="height"
                        className="form-control"
                        placeholder="0"
                        value={modalData.borderBottomRightRadius}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            borderBottomRightRadius: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Icon
                      </label>

                      <FormControl size="small" fullWidth>
                        <Select
                          value={additionalCardProperties?.icon}
                          onChange={(e) => {
                            setAdditionalCardProperties({
                              ...additionalCardProperties,
                              icon: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value={''}>
                            <i>None</i>
                          </MenuItem>

                          {cardMaterialIconsList.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {getMaterialIconForCard(item)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}

                <div className="flex flex-col sm:flex-row mb-3 gap-4">
                  {selectedComponent?.name !== 'profile_picture' &&
                    selectedComponent?.name !== 'items_icon' && (
                      <div className="flex gap-2 flex-col">
                        <label
                          htmlFor="password"
                          className="font-semibold text-xs"
                        >
                          Font Color
                        </label>
                        <input
                          style={{ width: '50px', height: '30px' }}
                          type="color"
                          id="color"
                          className="form-control p-0"
                          placeholder="#000000"
                          value={modalData.color}
                          onChange={(e) => {
                            setModalData({
                              ...modalData,
                              color: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )}

                  {selectedComponent?.name === 'items_icon' && (
                    <div className="flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Background Color Attach
                      </label>

                      <div class="w-full flex items-center">
                        <label className="font-semibold text-xs">No</label>
                        <SwitchWrapper
                          checked={additionalCardProperties?.showBackground}
                          onChange={(e) => {
                            setAdditionalCardProperties({
                              ...additionalCardProperties,
                              showBackground: e.target.checked,
                            });
                          }}
                        />
                        <label className="font-semibold text-xs">Yes</label>
                      </div>
                    </div>
                  )}
                  {selectedComponent?.name === 'items_icon' && (
                    <div className="flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Background Color
                      </label>
                      <input
                        style={{ width: '50px', height: '30px' }}
                        type="color"
                        id="color"
                        className="form-control p-0"
                        placeholder="#000000"
                        value={modalData.backgroundColor}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            backgroundColor: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                  {selectedComponent?.name === 'items_icon' && (
                    <div className="flex gap-2 flex-col">
                      <label
                        htmlFor="password"
                        className="font-semibold text-xs"
                      >
                        Icon Color
                      </label>
                      <input
                        style={{ width: '50px', height: '30px' }}
                        type="color"
                        id="color"
                        className="form-control p-0"
                        placeholder="#000000"
                        value={additionalCardProperties?.iconStyle.color}
                        onChange={(e) => {
                          setAdditionalCardProperties({
                            ...additionalCardProperties,
                            iconStyle: {
                              ...additionalCardProperties.iconStyle,
                              color: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  )}

                  {selectedComponent?.name !== 'profile_picture' &&
                    selectedComponent?.name !== 'qr_code' &&
                    selectedComponent?.name !== 'items_icon' && (
                      <div className="flex gap-2 flex-col">
                        <label
                          htmlFor="password"
                          className="font-semibold text-xs"
                        >
                          Font Weight
                        </label>
                        <div className="flex gap-3">
                          <div className="flex gap-1">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                              value="normal"
                              onChange={(e) => {
                                setModalData({
                                  ...modalData,
                                  fontWeight: e.target.value,
                                });
                              }}
                              checked={modalData.fontWeight === 'normal'}
                            />
                            <label
                              className="form-check-label p-0"
                              htmlFor="flexRadioDefault1"
                            >
                              Normal
                            </label>
                          </div>
                          <div className="flex gap-1">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              value="bold"
                              onChange={(e) => {
                                setModalData({
                                  ...modalData,
                                  fontWeight: e.target.value,
                                });
                              }}
                              checked={modalData.fontWeight === 'bold'}
                            />
                            <label
                              class="form-check-label p-0"
                              htmlFor="flexRadioDefault2"
                            >
                              Bold
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}

            <button
              onClick={saveModalData}
              className="bg-main px-4 py-1 rounded-full font-bold text-md text-white"
            >
              Apply
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DesignCard;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 320,
  bgcolor: 'white',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};
