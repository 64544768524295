import React, { useEffect, useState } from 'react';
import useScreen from '../../hooks/useScreen';
import { Navigate, useParams } from 'react-router-dom';
import { LinearProgress, Tab, Tabs } from '@mui/material';
import {
  CardTab,
  CompanyDetailsTab,
  EmployeeDetailTab,
} from '../../components';
import EmployeeService from '../../services/employee.service';
import { routesName } from '../../constants/routes-name';
import { useSelector } from 'react-redux';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (user?.role_id === 1 || isUser || isCompany) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { isPageLoaded } = useScreen();

  const params = useParams();

  const employeeDetailTab = 'employee-detail-tab';
  const companyDetailsTab = 'company-details-tab';
  const cardTab = 'card-tab';
  const [selectedTab, setSelectedTab] = useState(employeeDetailTab);

  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(false);

  const [userData, setUserData] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [card, setCard] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getEmployeeDetail = () => {
    setIsLoading(true);
    EmployeeService.getEmployeeDetails(params.id)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setUserData(res.data.user);
          setPersonal(res.data.personal);
          setCompany(res.data.company);
          if (
            res.data.cards &&
            Array.isArray(res.data.cards) &&
            res.data.cards.length > 0
          ) {
            setCard(res.data.cards);
          } else {
            setCard(res.data.cards);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getEmployeeDetail();
    }
  }, [isPageLoaded]);

  return (
    <div>
      <div className="text-2xl">Account Detail</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[1000px] bg-white rounded-lg md:px-12 px-4 md:py-8 py-4">
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab value={employeeDetailTab} label="Employee Detail" />
            <Tab value={companyDetailsTab} label="Company Detail" />
            <Tab value={cardTab} label="Card" />
          </Tabs>
          {!isLoading ? (
            <div className="md:mt-12 mt-6">
              {selectedTab === employeeDetailTab && (
                <EmployeeDetailTab
                  employeeData={personal}
                  userDetail={userData}
                />
              )}

              {selectedTab === companyDetailsTab && (
                <CompanyDetailsTab companyData={company} />
              )}

              {selectedTab === cardTab && (
                <div>
                  {Array.isArray(card) && card.length > 0 ? (
                    card.map((item, index) => (
                      <div key={index}>
                        <CardTab
                          template={item}
                          personal={personal}
                          company={company}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-md font-bold">
                      This employee did't have card.
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="mt-36 mx-auto max-w-[500px]">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Component;
