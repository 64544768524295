import React, { Fragment, useEffect, useState } from 'react';
import {
  InputMaskWrapper,
  PricingComponentSelect,
  StepperWrapper,
  SwitchWrapper,
  TextFieldWrapper,
} from '../../components';
import { Formik } from 'formik';
import { validationSchema } from '../../constants/validationSchema';
import * as yup from 'yup';
import { CircularProgress, Grid } from '@mui/material';
import { visaIcon } from '../../assets';
import { GppBadOutlined, Lock, TaskAltOutlined } from '@mui/icons-material';
import SubscriptionService from '../../services/subscription.service';
import { useSelector } from 'react-redux';

function UpgradePlan({
  packagesList = [],
  formType = 'upgrade',
  currentPackage = {},
}) {
  const steps = ['Subscription Plan', 'Billing Details', 'Summary'];

  const { user, company } = useSelector(({ user }) => user);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [interval, setInterval] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packageID, setPackageID] = useState('');
  const [amount, setAmount] = useState(0);
  const [nameOnCard, setNameOnCard] = useState('');
  const [cardNo, setCardNo] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvv, setCvv] = useState('');

  const submitPlan = () => {
    setIsLoading(true);
    const formObj = {
      company_id: company.user_id,
      subscriptionDetails: {
        intervals: interval == 0 ? 1 : interval == 2 ? 12 : 1,
        current_subscription: 1,
        amount: amount,
        status: 1,
        package_id: packageID,
      },
      cardDetails: {
        card_no: cardNo,
        name_on_card: nameOnCard,
        expiry_month: expiry.split('/')[0],
        expiry_year: expiry.split('/')[1],
        cvv: cvv,
        future_use_flag: 0,
        status: 1,
        default_flag: 1,
      },
    };

    if (formType === 'upgrade') {
      SubscriptionService.upgradeBilliing(formObj)
        .then((res) => {
          if (res.success) {
            setResponseData({
              status: true,
              message: `Package has been ${
                formType === 'upgrade' ? 'upgraded' : 'renewed'
              } successfully`,
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    } else {
      SubscriptionService.renewBilliing(formObj)
        .then((res) => {
          if (res.success) {
            setResponseData({
              status: true,
              message: `Package has been ${
                formType === 'upgrade' ? 'upgraded' : 'renewed'
              } successfully`,
            });
            setTimeout(() => window.location.reload(), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (activeTab === 2) {
      submitPlan();
    }
  }, [activeTab]);

  return (
    <div className="">
      <div className="overflow-x-auto mx-auto max-w-[800px]">
        <StepperWrapper activeStep={activeTab} steps={steps} />
      </div>
      {activeTab === 0 && (
        <div className="mt-12 mx-auto">
          <Formik
            initialValues={{ packageId: packageID, amount: amount }}
            validationSchema={yup.object().shape({
              packageId: validationSchema.required('Package selection'),
            })}
            onSubmit={(values, actions) => {
              try {
                setPackageID(values.packageId);
                setAmount(values.amount);
                setActiveTab(1);
              } catch (error) {}
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
              setFieldTouched,
            }) => {
              return (
                <div>
                  <div className="flex items-center justify-end gap-1">
                    <span>Month</span>
                    <SwitchWrapper
                      onChange={(e) => {
                        setInterval(e.target.checked ? 2 : 1);
                      }}
                      checked={interval === 2}
                    />
                    <span>Year</span>
                  </div>

                  <div className="flex flex-wrap justify-center gap-6">
                    {packagesList &&
                      Array.isArray(packagesList) &&
                      packagesList.map((item, index) => {
                        return (
                          item.monthly_price > 0 &&
                          (formType === 'upgrade'
                            ? currentPackage.intervals === 1
                              ? item.monthly_price > currentPackage.amount
                              : item.annually_price > currentPackage.amount
                            : true) && (
                            <div key={index} className="max-w-72 min-w-64">
                              <PricingComponentSelect
                                index={index}
                                data={item}
                                checked={item.id === values.packageId}
                                onClick={(id) => {
                                  setSelectedPackage(item);
                                  setFieldValue('packageId', id);
                                  setFieldValue(
                                    'amount',
                                    interval == 1
                                      ? item.monthly_price
                                      : item.annually_price
                                  );
                                  setFieldTouched('packageId', false);
                                }}
                                interval={interval}
                                selection={true}
                              />
                            </div>
                          )
                        );
                      })}
                  </div>

                  {touched.packageId && errors.packageId && (
                    <div className="text-sm font-bold text-red-600 bg-red-200 py-1 px-2 mt-2">
                      {errors.packageId}
                    </div>
                  )}

                  <div className="flex items-center justify-end mt-8">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Next
                    </button>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      )}

      {activeTab === 1 && (
        <div className="mt-12 mx-auto max-w-[800px]">
          <Formik
            initialValues={{
              cardHolderName: nameOnCard,
              cardNumber: cardNo,
              expiryDate: expiry,
              cardCsv: cvv,
            }}
            validationSchema={yup.object().shape({
              cardHolderName: validationSchema.name,
              cardNumber: validationSchema.cardNumber,
              expiryDate: validationSchema.required(''),
              cardCsv: validationSchema.required(''),
            })}
            onSubmit={(values, actions) => {
              try {
                setNameOnCard(values.cardHolderName);
                setCardNo(values.cardNumber);
                setExpiry(values.expiryDate);
                setCvv(values.cardCsv);
                setActiveTab(2);
              } catch (error) {}
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              handleSubmit,
              handleChange,
            }) => {
              const goPreviousTab = () => {
                setNameOnCard(values.cardHolderName);
                setCardNo(values.cardNumber);
                setExpiry(values.expiryDate);
                setCvv(values.cardCsv);
                setActiveTab(0);
              };
              return (
                <div>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <div className="flex flex-col gap-2">
                        <label className="text-lg font-bold">
                          Select payment method
                        </label>
                        <div className="flex items-center justify-between gap-4 flex-wrap">
                          <div className="flex gap-2 items-center">
                            <input
                              defaultChecked
                              id={`plan-visaIcon`}
                              type="radio"
                              value=""
                              name="payment-medthod-radio"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
                            />
                            <label
                              htmlFor={`plan-visaIcon`}
                              className="text-black"
                            >
                              <img alt="visa-icon" src={visaIcon} />
                            </label>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item sm={8} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">Card holder name</label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="cardHolderName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.cardHolderName}
                              helperText={
                                touched.cardHolderName && errors.cardHolderName
                              }
                              error={
                                !!touched.cardHolderName &&
                                !!errors.cardHolderName
                              }
                            />
                          </div>
                        </Grid>

                        <Grid item sm={8} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Card number{' '}
                              <span className="text-red-500">*</span>
                            </label>
                            <InputMaskWrapper
                              mask="9999 9999 9999 9999"
                              maskChar=""
                              placeholder="Placeholder"
                              name="cardNumber"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.cardNumber}
                              helperText={
                                touched.cardNumber && errors.cardNumber
                              }
                              error={
                                !!touched.cardNumber && !!errors.cardNumber
                              }
                            />
                          </div>
                        </Grid>

                        <Grid item className="flex gap-2" sm={8} xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Exp <span className="text-red-500">*</span>
                            </label>
                            <InputMaskWrapper
                              mask="99/99"
                              maskChar=""
                              placeholder="00/00"
                              name="expiryDate"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.expiryDate}
                              helperText={
                                touched.expiryDate && errors.expiryDate
                              }
                              error={
                                !!touched.expiryDate && !!errors.expiryDate
                              }
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              CSV <span className="text-red-500">*</span>
                            </label>
                            <InputMaskWrapper
                              mask="9999"
                              maskChar=""
                              placeholder="0000"
                              name="cardCsv"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.cardCsv}
                              helperText={touched.cardCsv && errors.cardCsv}
                              error={!!touched.cardCsv && !!errors.cardCsv}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="flex items-center gap-2">
                            <Lock fontSize="small" />
                            <div>
                              Guaranteed{' '}
                              <span className="font-bold">safe & secure</span>{' '}
                              check out.
                            </div>
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="flex flex-col gap-2">
                            <div className="flex items-center justify-between gap-2">
                              <div>Subtotal</div>
                              <div>{amount}$</div>
                            </div>

                            <div className="flex items-center justify-between gap-2">
                              <div>Tax</div>
                              <div>0$</div>
                            </div>

                            <hr className="border border-b-2 border-b-black" />

                            <div className="flex items-center justify-between gap-2 font-bold">
                              <div>Subtotal</div>
                              <div>{amount + 0}$</div>
                            </div>
                          </div>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <div className="flex justify-center gap-2">
                            <button
                              onClick={() => {}}
                              className="w-full text-sm bg-main text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                            >
                              Make payment
                            </button>
                          </div>
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <PricingComponentSelect
                        index={0}
                        data={selectedPackage}
                        checked={true}
                        onClick={() => {}}
                        interval={interval}
                      />
                    </Grid>
                  </Grid>

                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={goPreviousTab}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>

                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Next
                    </button>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      )}

      {activeTab === 2 && (
        <div className="mt-12 mx-auto max-w-[800px]">
          <div className="min-h-96 flex items-center justify-center flex-col gap-5">
            {isLoading ? (
              <CircularProgress />
            ) : responseData?.status ? (
              <Fragment>
                <TaskAltOutlined
                  className="text-[var(--yellow)]"
                  sx={{ fontSize: '5rem' }}
                />
                <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                  {responseData?.message}
                </div>
              </Fragment>
            ) : (
              !responseData?.status &&
              responseData?.message && (
                <Fragment>
                  <GppBadOutlined
                    className="text-[var(--pink)]"
                    sx={{ fontSize: '5rem' }}
                  />
                  <div className="text-[var(--pink)] font-bold text-2xl text-center">
                    {responseData?.message}
                  </div>
                </Fragment>
              )
            )}
          </div>

          {!isLoading && !responseData?.status && (
            <div className="flex items-center justify-between mt-8">
              <button
                onClick={() => {
                  setResponseData(null);
                  setActiveTab(1);
                }}
                className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                Back
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default UpgradePlan;
