import React, { useEffect, useState } from 'react';
import { Chip, LinearProgress, Tooltip } from '@mui/material';
import { CardPreview } from '.';
import { initialPagination } from '../constants/constant-values';
import useScreen from '../hooks/useScreen';
import TemplateService from '../services/template.service';

function Component({ selected = '', setSelected = () => {} }) {
  const [page, setPage] = useState(1);
  const { isPageLoaded } = useScreen();
  const [cardType, setCardType] = useState('l');
  const [cardTemplates, setCardTemplates] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadPreview, setIsLoadPreview] = useState(true);

  const getCardTemplates = (orientation, page = 1) => {
    TemplateService.getTemplates(orientation, page)
      .then((res) => {
        setIsLoading(false);
        if (res.success && res?.data?.data) {
          const data = res?.data;
          Array.isArray(data?.data) &&
            setCardTemplates([...cardTemplates, ...data.data]);

          pagination.from = data.from;
          pagination.to = data.to;
          pagination.total = data.total;
          pagination.perPage = data.per_page;
          pagination.currentPage = data.current_page;
          pagination.lastPage = data.last_page;
          setPagination({ ...pagination });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setPagination({ initialPagination });
      });
  };

  const getSubstring = (string = '', till = 10) => {
    if (string.length > till) {
      return `${string.substring(0, till)}...`;
    }
    return string;
  };

  useEffect(() => {
    if (isPageLoaded) {
      getCardTemplates(cardType, page || 1);
    }
  }, [cardType, page, isPageLoaded]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-center gap-4">
        <div className="flex gap-2 items-center">
          <input
            checked={cardType === 'l'}
            onClick={() => setCardType('l')}
            onChange={() => {
              setPagination(initialPagination);
              setPage(1);
              setCardTemplates([]);
              setIsLoading(true);
            }}
            id={`landscape`}
            type="radio"
            value=""
            name="default-radio"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
          />
          <label htmlFor={`landscape`} className="text-black">
            Landscape
          </label>
        </div>

        <div className="flex gap-2 items-center">
          <input
            checked={cardType === 'v'}
            onClick={() => setCardType('v')}
            onChange={() => {
              setPagination(initialPagination);
              setPage(1);
              setCardTemplates([]);
              setIsLoading(true);
            }}
            id={`portrait`}
            type="radio"
            value=""
            name="default-radio"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600 rounded-full"
          />
          <label htmlFor={`portrait`} className="text-black">
            Portrait
          </label>
        </div>
      </div>

      <div>
        {cardType && (
          <div className="flex gap-2 flex-wrap justify-center items-center">
            {isLoading ? (
              <div className="flex-1 mt-36 mx-auto max-w-[500px]">
                <LinearProgress />
              </div>
            ) : (
              cardTemplates &&
              cardTemplates.length > 0 &&
              cardTemplates.map((template, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelected(template);
                    }}
                    className={`cursor-pointer border p-2 rounded-lg overflow-x-auto ${
                      selected?.id === template.id
                        ? 'bg-main text-white'
                        : 'bg-white text-black'
                    }`}
                    key={index}
                  >
                    {isLoadPreview && (
                      <div className="">
                        <div className="mb-2 text-start">
                          <Tooltip placement="top" arrow title={template.title}>
                            <span className="font-semibold">
                              {getSubstring(
                                template.title,
                                template.orientation === 'l' ? 35 : 21
                              )}
                            </span>
                          </Tooltip>
                        </div>

                        <div className="flex gap-1 justify-between items-center">
                          <Tooltip
                            placement="top"
                            arrow
                            title={template?.created_by_name}
                          >
                            <span style={{ fontSize: '13px' }}>
                              By:{' '}
                              {getSubstring(
                                template?.created_by_name,
                                template.orientation === 'l' ? 50 : 18
                              )}
                            </span>
                          </Tooltip>
                          <Tooltip
                            placement="left"
                            arrow
                            title={template?.public == 1 ? 'Public' : 'Private'}
                          >
                            <Chip
                              color={
                                template?.public == 1 ? 'success' : 'error'
                              }
                              label={
                                template?.public == 1 ? ' Public ' : ' Private '
                              }
                              size="small"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    )}

                    <div className="pt-2 overflow-x-auto">
                      <CardPreview
                        setIsLoadPreview={setIsLoadPreview}
                        isLoadPreview={isLoadPreview}
                        cardBackground={template.card_background}
                        backgroundImage={template.background_image}
                        cardComponent={template.card_component}
                        cardData={template}
                        className="border shadow"
                        orientation={template.orientation}
                      />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}

        {pagination && pagination.currentPage < pagination.lastPage && (
          <div className="flex items-center justify-center mt-8">
            <button
              disabled={isLoading}
              onClick={() => setPage((page) => page + 1)}
              className="text-xs bg-[var(--yellow)] opacity-50 hover:opacity-100 text-center px-3 py-1 rounded-full font-bold flex items-center justify-center gap-1"
            >
              <span>Load more...</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
