import { Grid } from '@mui/material';
import React from 'react';

function CountsComponent({ counts }) {
  return (
    <div className="bg-main px-4 py-12">
      <Grid container spacing={4}>
        <Grid item md={3} xs={6}>
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-5xl font-bold">4</div>
            <div className="font-bold">Dashboards</div>
          </div>
        </Grid>
        <Grid item md={3} xs={6}>
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-5xl font-bold">
              {counts?.templates > 99 ? '99+' : counts?.templates}
            </div>
            <div className="font-bold">Templates</div>
          </div>
        </Grid>
        <Grid item md={3} xs={6}>
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-5xl font-bold">
              {' '}
              {counts?.cards > 99 ? '99+' : counts?.cards}
            </div>
            <div className="font-bold">Cards Design</div>
          </div>
        </Grid>
        <Grid item md={3} xs={6}>
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-5xl font-bold">
              {' '}
              {counts?.packages > 99 ? '99+' : counts?.packages}
            </div>
            <div className="font-bold">Packages</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default CountsComponent;
