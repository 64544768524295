import API from '../config/api.config';

const getTemplates = (orientation = '', page = 1) => {
  if (orientation) {
    return API.get(`template?page=${page}&orientation=${orientation}`).then(
      (res) => {
        return res.data;
      }
    );
  }
  return API.get(`template?page=${page}`).then((res) => {
    return res.data;
  });
};

const getTemplatesByOrientation = (orientation) => {
  return API.get(`template-list/${orientation}`).then((res) => {
    return res.data;
  });
};

const getTemplatesList = () => {
  return API.get('template-list').then((res) => {
    return res.data;
  });
};

const getTemplateById = (id) => {
  return API.get(`template/${id}`).then((res) => {
    return res.data;
  });
};

const deleteTemplate = (id) => {
  return API.delete(`template/${id}`).then((res) => {
    return res.data;
  });
};

const TemplateService = {
  getTemplatesByOrientation,
  getTemplates,
  getTemplatesList,
  getTemplateById,
  deleteTemplate,
};

export default TemplateService;
