import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Details, Edit } from '@mui/icons-material';
import { useQuery } from '../../constants/shared-methods';
import useScreen from '../../hooks/useScreen';
import { initialPagination } from '../../constants/constant-values';
import QRCode from 'react-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useNotification from '../../hooks/useNotification';
import { routesName } from '../../constants/routes-name';
import { PaginationWrapper } from '../../components';
import CardService from '../../services/card.service';
import { useSelector } from 'react-redux';

function CardTableView({ loading = false }) {
  const { isCompany, isUser } = useSelector(({ user }) => user);
  const { successNonification, errorNonification } = useNotification();
  const navigate = useNavigate();
  const query = useQuery();
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);
  const [qrData, setQrData] = useState('');
  const [showQR, setShowQR] = useState(false);

  const toggleShowQR = () => {
    setShowQR(!showQR);
  };

  const getAllCards = () => {
    CardService.getBusinessCards(page || 1)
      .then((res) => {
        setIsLoading(false);
        if (res?.data) {
          const data = res.data;
          setCards(data.data);
          pagination.from = data.from;
          pagination.to = data.to;
          pagination.total = data.total;
          pagination.perPage = data.per_page;
          pagination.currentPage = data.current_page;
          pagination.lastPage = data.last_page;
          setPagination({ ...pagination });
        }
      })
      .catch((error) => {
        setCards([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getAllCards();
    }
  }, [isPageLoaded, page]);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        CardService.deleteCard(id)
          .then((res) => {
            if (res.success) {
              getAllCards();
              successNonification('Card has been deleted.');
            }
          })
          .catch(() => errorNonification('Something went wrong'));
      }
    });
  };

  const handleStatus = (id) => {
    CardService.changeStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Status changed.');
          getAllCards();
        }
      })
      .catch((error) => errorNonification('Something went wrong'));
  };

  const columns = [
    {
      field: 'title',
      sortable: false,
      headerName: 'TITLE',
      minWidth: 200,
      flex: 0.8,
    },
    {
      field: 'slug',
      sortable: false,
      headerName: 'SLUG',
      minWidth: 210,
      flex: 1,
    },
    {
      field: 'companyName',
      sortable: false,
      headerName: 'COMPANY NAME',
      minWidth: 210,
      flex: 1,
    },
    {
      field: 'employeeName',
      sortable: false,
      headerName: 'EMPLOYEE NAME',
      minWidth: 210,
      flex: 1,
    },
    {
      field: 'orientation',
      headerName: 'CARD DESIGN',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 150,
      flex: 0.8,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center">
          {params?.row?.orientation === 'l' ? 'Landscape' : 'Portrait'}
        </div>
      ),
    },
    {
      field: 'qr_code',
      headerName: 'QR Code',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full gap-2 items-center text-[var(--gray)]">
          <QRCode
            className="cursor-pointer"
            size={40}
            value={
              window.location.origin +
              routesName.viewCardPath +
              params?.row?.guid
            }
            viewBox={`0 0 256 256`}
            onClick={() => {
              setQrData(
                window.location.origin +
                routesName.viewCardPath +
                params?.row?.guid
              );
              toggleShowQR();
            }}
          />
        </div>
      ),
    },

    {
      field: 'status',
      headerName: 'STATUS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center justify-center text-[var(--gray)]">
          {params.row.status ? (
            <span
              onClick={() => handleStatus(params.row.id)}
              className="cursor-pointer bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Active
            </span>
          ) : (
            <span
              onClick={() => handleStatus(params.row.id)}
              className="cursor-pointer bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Inactive
            </span>
          )}
        </div>
      ),
    },

    {
      field: 'action',
      headerName: 'ACTIONS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 180,
      flex: 0.8,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Detail">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`${routesName.cardDetailPath}${params?.row?.id}`);
                }}
              >
                <Details />
              </IconButton>
            </Tooltip>
            {(isCompany || isUser) && (
              <Tooltip slotProps={tooltipSlotStyle} arrow title="Edit">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(`${routesName.editCardPath}${params?.row?.id}`);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(params?.row?.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows =
    cards &&
    Array.isArray(cards) &&
    cards.length > 0 &&
    cards.map((item, index) => ({
      companyName: item?.companyName,
      company_id: item?.company_id,
      created_at: item?.created_at,
      created_by: item?.created_by,
      deleted_at: item?.deleted_at,
      designation: item?.designation,
      employeeName: item?.employeeName,
      employee_id: item?.employee_id,
      facebook_url: item?.facebook_url,
      guid: item?.guid,
      id: item?.id,
      instagram_username: item?.instagram_username,
      is_default: item?.is_default,
      orientation: item?.orientation,
      public: item?.public,
      self_description: item?.self_description,
      skype_id: item?.skype_id,
      slug: item?.slug,
      status: item?.status,
      template_id: item?.template_id,
      title: item?.title,
      twitter_link: item?.twitter_link,
      updated_at: item?.updated_at,
      user_id: item?.user_id,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.cardsListing}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal open={showQR} onClose={toggleShowQR}>
        <Box sx={style}>
          {qrData ? (
            <div className="flex flex-col justify-center gap-2 items-center">
              <QRCode
                value={qrData}
                viewBox={`0 0 256 256`}
                style={{ width: '100%' }}
              />
              <Link
                to={qrData}
                target='_blank'
                state={null}
                className="text-xs bg-second text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                View Card
              </Link>
            </div>
          ) : (
            <h4>No QR code found</h4>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default memo(CardTableView);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  bgcolor: 'var(--blue)',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};
