import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { TextFieldWrapper } from '../components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routesName } from '../constants/routes-name';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../constants/validationSchema';
import useNotification from '../hooks/useNotification';
import AuthService from '../services/auth.service';

function Component() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { successNonification, errorNonification } = useNotification();

  const sendResetLink = (data) => {
    setIsLoading(true);

    AuthService.forgetPassword(data)
      .then(async (res) => {
        setIsLoading(false);
        if (res.success) {
          successNonification(res.message);
          navigate(routesName.login);
        } else {
          errorNonification(res.message);
        }
      })
      .catch(async (error) => {
        setIsLoading(false);
        error.response.data.message &&
          errorNonification(error.response.data.message);
        navigate(routesName.login);
      });
  };

  return (
    <div className="mt-12">
      <Formik
        initialValues={{ email: state?.paramEmail || '' }}
        validationSchema={yup.object().shape({
          email: validationSchema.email,
        })}
        onSubmit={(values, actions) => {
          try {
            sendResetLink({ email: values.email });
          } catch (error) {}
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          handleBlur,
          touched,
        }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-3xl font-bold text-center">
                Forgot Password
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <label className="text-xs">
                  Email <span className="text-red-500">*</span>
                </label>
                <TextFieldWrapper
                  placeholder="Placeholder"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={!!touched.email && !!errors.email}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="w-full text-md border border-white hover:bg-white text-white hover:text-black border border-1 border-[var(--blue)] text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                {!isLoading ? 'Send Rest Link' : 'Sending Rest Link'}
              </button>
            </Grid>

            <Grid item xs={12}>
              <div className="text-xs">
                <span>Remembered password?</span>{' '}
                <Link className="text-[var(--yellow)]" to={routesName.login}>
                  Login to account
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
}

export default Component;
