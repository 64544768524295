import React from 'react';
import { appRoutes } from './appRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routesName } from '../constants/routes-name';

function ApplicationRouter() {
  return (
    <Routes>
      {appRoutes.map(({ Component, children }, key) => (
        <Route exact element={<Component />} key={key}>
          {children.map(({ path, Component }, key) => (
            <Route path={path} element={<Component />} key={key} />
          ))}
        </Route>
      ))}

      <Route
        path={routesName.allRoutes}
        exact
        element={<Navigate to={routesName[404]} replace={true} />}
      />

      <Route
        path={routesName[404]}
        exact
        element={
          <div className="flex items-center justify-center h-screen text-4xl font-bold">
            <div className="animate-bounce">
              <span className="animate-ping">404! Page Not Found</span>
            </div>
          </div>
        }
      />
    </Routes>
  );
}

export default ApplicationRouter;
