import React, { Fragment, useEffect, useState } from 'react';
import {
  DragDropImage,
  StepperWrapper,
  TextFieldWrapper,
} from '../../components';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { GppBadOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../constants/validationSchema';
import LocationService from '../../services/location.service';
import CompanyService from '../../services/company.service';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useScreen from '../../hooks/useScreen';
import { toAbsoluteURL } from '../../constants/shared-methods';
import { useSelector } from 'react-redux';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { isUser, company } = useSelector(({ user }) => user);
  if (isUser && !company) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const steps = ['Upload Logos', 'Business Information', 'Summary'];
  const { isPageLoaded } = useScreen();

  const navigate = useNavigate();
  const params = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);

  const [lightLogoFile, setLightLogoFile] = useState(null);
  const [lightLogoUrl, setLightLogoUrl] = useState(null);
  const [darkLogoFile, setDarkLogoFile] = useState(null);
  const [darkLogoUrl, setDarkLogoUrl] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [roleID] = useState(3);
  const [cityID, setCityID] = useState('');
  const [countryID, setCountryID] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [status] = useState(1);
  const [slogan, setSlogan] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');

  const [responseData, setResponseData] = useState(null);

  const geCountries = () => {
    LocationService.getCountries().then((res) => {
      setCountryList(res.data);
    });
  };

  const getCitiesByCountry = (value) => {
    if (value) {
      LocationService.getCitiesByCountry(value).then((res) => {
        setCityList(res.data);
      });
    } else setCityList(null);
  };

  const getCompany = () => {
    setIsCompanyLoading(true);
    CompanyService.getCompanyDetail(params.id)
      .then((res) => {
        if (res.success) {
          if (res.data?.company) {
            const company = res.data?.company;
            if (company?.country_id) {
              getCitiesByCountry(company?.country_id);
            }
            setLightLogoUrl(
              company?.photo ? toAbsoluteURL(company?.photo) : ''
            );
            setDarkLogoUrl(
              company?.dark_logo ? toAbsoluteURL(company?.dark_logo) : ''
            );
            setName(company?.name);
            setEmail(company?.email);
            setPhone(company?.phone);
            setCityID(company?.city_id);
            setCountryID(company?.country_id);
            setStreetAddress(company?.street_address);
            setSlogan(company?.slogan);
            setWebsiteUrl(company?.website_url);
          }
        }
        setIsCompanyLoading(false);
      })
      .catch((error) => {
        setIsCompanyLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getCompany();
      geCountries();
    }
  }, [isPageLoaded]);

  const updateCompany = () => {
    setIsLoading(true);
    const formObj = {
      companyGeneral: {
        name: name,
        email: email,
        phone: phone,
        role_id: roleID,
        city_id: cityID,
        //state_id: stateID,
        country_id: countryID,
        street_address: streetAddress,
        status: status,
      },
      companySpecifics: {
        slogan: slogan,
        website_url: websiteUrl,
      },
    };

    if (lightLogoFile !== null) {
      formObj.companyGeneral['photo'] = lightLogoFile;
    }

    if (darkLogoFile !== null) {
      formObj.companySpecifics['dark_logo'] = darkLogoFile;
    }

    CompanyService.updateCompany(params.id, formObj)
      .then((res) => {
        if (res.success) {
          setResponseData({
            status: true,
            message: 'Account has been updated successfully',
          });
          setTimeout(() => navigate(-1), 1000);
        } else {
          setResponseData({ status: false, message: 'Something went wrong' });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setResponseData({
          status: false,
          message: error?.response?.data?.message || 'Something went wrong',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (activeTab === 2) {
      updateCompany();
    }
  }, [activeTab]);

  return (
    <div>
      <div className="text-2xl">Account Alteration</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>

        {!isCompanyLoading ? (
          <div>
            {activeTab === 0 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    lightLogoFile: lightLogoFile,
                    lightLogoUrl: lightLogoUrl,
                    darkLogoFile: darkLogoFile,
                    darkLogoUrl: darkLogoUrl,
                  }}
                  validationSchema={yup.object().shape({
                    lightLogoFile: validationSchema.image,
                    darkLogoFile: validationSchema.image,
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setDarkLogoFile(values.darkLogoFile);
                      setDarkLogoUrl(values.darkLogoUrl);
                      setLightLogoFile(values.lightLogoFile);
                      setLightLogoUrl(values.lightLogoUrl);
                      setActiveTab(1);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    handleBlur,
                  }) => (
                    <div>
                      <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                          <DragDropImage
                            error={errors.lightLogoFile}
                            onBlur={handleBlur}
                            name="lightLogoFile"
                            onChangeImage={(file) => {
                              if (file) {
                                setFieldValue('lightLogoFile', file);
                                setFieldValue(
                                  'lightLogoUrl',
                                  URL.createObjectURL(file)
                                );
                              }
                            }}
                            preview={values.lightLogoUrl}
                            title="Light Version"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <DragDropImage
                            error={errors.darkLogoFile}
                            onBlur={handleBlur}
                            name="darkLogoFile"
                            onChangeImage={(file) => {
                              if (file) {
                                setFieldValue('darkLogoFile', file);
                                setFieldValue(
                                  'darkLogoUrl',
                                  URL.createObjectURL(file)
                                );
                              }
                            }}
                            preview={values.darkLogoUrl}
                            title="Dark Version"
                          />
                        </Grid>
                      </Grid>
                      <div className="flex items-center justify-end mt-8">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            )}

            {activeTab === 1 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    name: name,
                    slogon: slogan,
                    phone: phone,
                    email: email,
                    website: websiteUrl,
                    address: streetAddress,
                    city: cityID,
                    country: countryID,
                  }}
                  validationSchema={yup.object().shape({
                    name: validationSchema.name,
                    slogon: validationSchema.required('Company Slogon'),
                    email: validationSchema.email,
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setName(values.name);
                      setSlogan(values.slogon);
                      setPhone(values.phone);
                      setEmail(values.email);
                      setWebsiteUrl(values.website);
                      setStreetAddress(values.address);
                      setCityID(values.city);
                      setCountryID(values.country);
                      setActiveTab(2);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => {
                    const goPreviousTab = () => {
                      setName(values.name);
                      setSlogan(values.slogon);
                      setPhone(values.phone);
                      setEmail(values.email);
                      setWebsiteUrl(values.website);
                      setStreetAddress(values.address);
                      setCityID(values.city);
                      setCountryID(values.country);
                      setActiveTab(0);
                    };
                    return (
                      <div>
                        <Grid container spacing={4}>
                          <Grid item xs={12} container spacing={4}>
                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Title{' '}
                                  <span className="text-red-500">*</span>
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  helperText={touched.name && errors.name}
                                  error={!!touched.name && !!errors.name}
                                />
                              </div>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Slogan (Optional)
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="slogon"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.slogon}
                                  helperText={touched.slogon && errors.slogon}
                                  error={!!touched.slogon && !!errors.slogon}
                                />
                              </div>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Phone (Optional)
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="phone"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phone}
                                  helperText={touched.phone && errors.phone}
                                  error={!!touched.phone && !!errors.phone}
                                />
                              </div>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Email
                                  <span className="text-red-500">*</span>
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  helperText={touched.email && errors.email}
                                  error={!!touched.email && !!errors.email}
                                />
                              </div>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company Website
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="website"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.website}
                                  helperText={touched.website && errors.website}
                                  error={!!touched.website && !!errors.website}
                                />
                              </div>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} container spacing={4}>
                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Street Address (Optional)
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  name="address"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.address}
                                  helperText={touched.address && errors.address}
                                  error={!!touched.address && !!errors.address}
                                />
                              </div>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Country (Optional)
                                </label>
                                <TextFieldWrapper
                                  select
                                  placeholder="Placeholder"
                                  name="country"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue('city', '');
                                    getCitiesByCountry(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.country}
                                  helperText={touched.country && errors.country}
                                  error={!!touched.country && !!errors.country}
                                >
                                  <MenuItem value={''}>Select</MenuItem>
                                  {countryList &&
                                    Array.isArray(countryList) &&
                                    countryList.map((item, index) => (
                                      <MenuItem key={index} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                </TextFieldWrapper>
                              </div>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  City (Optional)
                                </label>
                                <TextFieldWrapper
                                  select
                                  disabled={!!!cityList}
                                  placeholder="Placeholder"
                                  name="city"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.city}
                                  helperText={touched.city && errors.city}
                                  error={!!touched.city && !!errors.city}
                                >
                                  <MenuItem value={''}>Select</MenuItem>
                                  {cityList &&
                                    Array.isArray(cityList) &&
                                    cityList.map((item, index) => (
                                      <MenuItem key={index} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                </TextFieldWrapper>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <div className="flex items-center justify-between mt-8">
                          <button
                            onClick={goPreviousTab}
                            className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Back
                          </button>

                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}

            {activeTab === 2 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                  {isLoading ? (
                    <CircularProgress />
                  ) : responseData?.status ? (
                    <Fragment>
                      <TaskAltOutlined
                        className="text-[var(--yellow)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  ) : (
                    !responseData?.status &&
                    responseData?.message && (
                      <Fragment>
                        <GppBadOutlined
                          className="text-[var(--pink)]"
                          sx={{ fontSize: '5rem' }}
                        />
                        <div className="text-[var(--pink)] font-bold text-2xl text-center">
                          {responseData?.message}
                        </div>
                      </Fragment>
                    )
                  )}
                </div>

                {!isLoading && !responseData?.status && (
                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={() => {
                        setResponseData(null);
                        setActiveTab(1);
                      }}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
