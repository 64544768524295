import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DesignCard from './DesignCard.js';
import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import {
  Add,
  Delete,
  GppBadOutlined,
  Help,
  Settings,
  TaskAltOutlined,
} from '@mui/icons-material';
import TemplateService from '../../../services/template.service.js';
import {
  CardPreview,
  StepperWrapper,
  TextFieldWrapper,
} from '../../../components/index.js';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../../constants/validationSchema.js';
import useNotification from '../../../hooks/useNotification.js';
import useScreen from '../../../hooks/useScreen.js';
import { routesName } from '../../../constants/routes-name.js';

const defaultStyle = {
  width: '99',
  color: '#000000',
  height: '25',
  fontWeight: 'normal',
  fontSize: '14',
  textAlign: 'start',
  rotate: '0',
};
const iconProperties = {
  icon: '',
  showBackground: false,
  iconStyle: {
    color: '#000',
    fontSize: '14',
  },
};

const CardEditor = () => {
  const navigate = useNavigate();
  const { isPageLoaded } = useScreen();
  const { user, company } = useSelector(({ user }) => user);
  const steps = ['Design Card', 'Summary'];
  const [activeTab, setActiveTab] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [submitFor, setSubmitFor] = useState('save');
  const { errorNonification } = useNotification();

  const list = [
    {
      id: 1,
      top: 0,
      left: 0,
      title: 'Profile Picture',
      name: 'profile_picture',
      isVisible: true,
      style: {
        ...defaultStyle,
        borderRadius: '50%',
        height: '80',
        width: '80',
      },
      ...iconProperties,
    },
    {
      id: 2,
      top: 0,
      left: 0,
      title: 'User Name',
      name: 'user_name',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 3,
      top: 0,
      left: 0,
      title: 'User Desigination',
      name: 'user_desigination',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 4,
      top: 0,
      left: 0,
      title: 'Position',
      name: 'position',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 5,
      top: 0,
      left: 0,
      title: 'Phone Number',
      name: 'phone_number',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 6,
      top: 0,
      left: 0,
      title: 'Email',
      name: 'email',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 7,
      top: 0,
      left: 0,
      title: 'Reference',
      name: 'reference',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 8,
      top: 0,
      left: 0,
      title: 'Address',
      name: 'address',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 9,
      top: 0,
      left: 0,
      title: 'QR Code',
      name: 'qr_code',
      isVisible: true,
      style: {
        ...defaultStyle,
        height: '50',
        width: '50',
      },
      ...iconProperties,
    },
    {
      id: 10,
      top: 0,
      left: 0,
      title: 'Website',
      name: 'website_url',
      isVisible: true,
      style: defaultStyle,
      ...iconProperties,
    },
    {
      id: 11,
      top: 0,
      left: 0,
      title: 'Items Icon',
      name: 'items_icon',
      isVisible: true,
      style: {
        ...defaultStyle,
        backgroundColor: '#999999',
        height: '14',
        width: '14',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        fontSize: 'auto',
      },
      icon: '',
      iconStyle: {
        color: '#000',
        fontSize: '14',
      },
      ...iconProperties,
    },
  ];

  const [availableComponent, setAvailableComponent] = useState(list);
  const [validationErrors, setValidationErrors] = useState({});
  const [name, setName] = useState('');
  const [templateType, setTemplateType] = useState('l');
  const [cardBackground, setCardBackground] = useState({
    title: 'Card Background',
    name: 'card_background',
    style: {
      backgroundColor: '#ffffff',
      backgroundImage: null,
      backgroundRepeat: 'streched',
    },
  });
  const [bg, setBg] = useState({});
  const [cardComponent, setCardComponent] = useState([]);
  const [modalData, setModalData] = useState({});
  const [additionalCardProperties, setAdditionalCardProperties] = useState({});
  const [selectedComponent, setSelectedComponent] = useState({});
  const [modal, setModal] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(
    !(user?.role_id === 2 || user?.role_id === 3)
  );
  const [showEditorLines, setShowEditorLines] = useState(true);
  const params = useParams();
  const [cardId] = useState(params?.id);
  const [isLoadingCard, setIsLoadingCard] = useState(!!params?.id);

  useEffect(() => {
    if (isPageLoaded && cardId) {
      getTemplateById(cardId);
    }
  }, [isPageLoaded, cardId]);

  const getTemplateById = (id) => {
    TemplateService.getTemplateById(id)
      .then((res) => {
        if (res?.data) {
          setPhoto(res?.data?.background_image);
          setBg(res?.data?.background_image);
          setPhotoPreview(
            res?.data?.background_image
              ? `${process.env.REACT_APP_ASSET_URL}/${res?.data?.background_image}`
              : ''
          );
          setCardBackground(
            res?.data?.card_background
              ? JSON.parse(res?.data?.card_background)
              : {
                  title: 'Card Background',
                  name: 'card_background',
                  style: {
                    backgroundColor: '#ffffff',
                    backgroundImage: null,
                    backgroundRepeat: 'streched',
                  },
                }
          );
          setCardComponent(
            res?.data?.card_component
              ? JSON.parse(res?.data?.card_component)
              : []
          );
          setTemplateType(res?.data?.orientation);
          setIsPublic(res?.data?.public);
          setName(res?.data?.title);

          var currentComponents = res?.data?.card_component
            ? JSON.parse(res?.data?.card_component)
            : [];

          console.log('currentComponents =>', currentComponents);
          var defaultComponent = [...availableComponent];

          defaultComponent.map((item) => {
            currentComponents.forEach((itm) => {
              if (itm.name !== 'items_icon' && item.name == itm.name) {
                item.isVisible = false;
              }
            });
          });

          setAvailableComponent([...defaultComponent]);
        }
        setIsLoadingCard(false);
      })
      .catch(() => navigate(routesName[404]));
  };

  const updateTemplate = () => {
    const formObject = {
      title: name,
      orientation: templateType,
      card_background: JSON.stringify(cardBackground),
      card_component: JSON.stringify(cardComponent),
      background_image: bg,
      public: isPublic ? 1 : 0,
    };

    if (photo) {
      formObject.background_image = photo;
    }
    if (cardId) {
      formObject.id = cardId;
    }

    setLoading(true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/template/${cardId}/update`,
        formObject,
        config
      )
      .then((response) => {
        setLoading(false);
        const result = response.data.data;
        if (result?.template?.status) {
          setResponseData({
            status: true,
            message: 'Template has been updated successfully',
          });
          setTimeout(() => navigate(-1), 1000);
        } else {
          setResponseData({ status: false, message: 'Something went wrong' });
        }
      })
      .catch((error) => {
        setLoading(false);
        setResponseData({
          status: false,
          message: error?.response?.data?.message || 'Something went wrong',
        });
        window.scrollTo(0, 0);
      });
  };

  const saveTemplate = () => {
    const formObject = {
      title: name,
      orientation: templateType,
      card_background: JSON.stringify(cardBackground),
      card_component: JSON.stringify(cardComponent),
      background_image: bg,
      public: isPublic ? 1 : 0,
    };

    if (photo) {
      formObject.background_image = photo;
    }

    setLoading(true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/template/${company.user_id}`,
        formObject,
        config
      )
      .then((response) => {
        setLoading(false);
        const result = response.data.data;
        if (result?.template?.status) {
          setResponseData({
            status: true,
            message: 'Template has been saved successfully',
          });
          setTimeout(() => navigate(-1), 1000);
        } else {
          setResponseData({ status: false, message: 'Something went wrong' });
        }
      })
      .catch((error) => {
        setLoading(false);
        setResponseData({
          status: false,
          message: error?.response?.data?.message || 'Something went wrong',
        });
      });
  };

  const addToCard = (id, index) => {
    if (availableComponent[index].name !== 'items_icon') {
      availableComponent[index].isVisible = false;
      setAvailableComponent([...availableComponent]);
    }

    setCardComponent([
      ...cardComponent,
      {
        id: cardComponent.length,
        top: availableComponent[index].top,
        left: availableComponent[index].left,
        title: availableComponent[index].title,
        name: availableComponent[index].name,
        style: availableComponent[index].style,
        icon: availableComponent[index].icon,
        showBackground: availableComponent[index].showBackground,
        iconStyle: availableComponent[index].iconStyle,
      },
    ]);
  };

  const prepareSettingData = (object) => {
    if (object?.name === 'card_background') {
    }
    setAdditionalCardProperties({
      icon: object.icon,
      showBackground: object.showBackground,
      iconStyle: object.iconStyle,
    });
    setModalData(object.style);
    setSelectedComponent(object);
    setModal(true);
  };

  const deleteComponent = (id, title) => {
    var temp = [];
    cardComponent.map((item, index) => {
      if (item.id < id) {
        temp.push(item);
      } else if (item.id > id) {
        temp.push({ ...item, id: item.id - 1 });
      }
    });
    setCardComponent([...temp]);

    availableComponent.map((item) => {
      if (item.title === title) {
        item.isVisible = true;
      }
    });
    setAvailableComponent([...availableComponent]);
  };

  useEffect(() => {
    if (activeTab === 1) {
      if (submitFor === 'save') {
        if (cardId) {
          updateTemplate();
        } else {
          saveTemplate();
        }
      } else {
        saveTemplate();
      }
    }
  }, [activeTab]);

  return (
    <div className="mt-12 mx-auto">
      <div className="overflow-x-auto mx-auto max-w-[800px]">
        <StepperWrapper activeStep={activeTab} steps={steps} />
      </div>

      {!isLoadingCard ? (
        <div>
          {activeTab === 0 && (
            <div className="mt-12">
              <Formik
                initialValues={{ name: name }}
                validationSchema={yup.object().shape({
                  name: validationSchema.required('Name'),
                })}
                onSubmit={(values, actions) => {
                  try {
                    setName(values?.name);
                    if (cardComponent.length === 0) {
                      errorNonification('Add at least 1 item to card to save.');
                      return;
                    }
                    setActiveTab(1);
                  } catch (error) {}
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Grid container spacing={2}>
                    <Grid item md={3.5} xs={12}>
                      <div>
                        <h5 className="card-title text-primary mb-3">
                          Components
                        </h5>

                        {!preview && (
                          <div className="flex justify-center items-center flex-col gap-2 py-2">
                            {availableComponent &&
                              availableComponent.map((item, index) => {
                                if (item.isVisible) {
                                  return (
                                    <div
                                      key={index}
                                      className="w-full  bg-white"
                                    >
                                      <div className="border flex justify-between items-center w-full px-2 py-1 text-xs font-semibold">
                                        <span>{item.title}</span>
                                        <IconButton
                                          onClick={() =>
                                            addToCard(item.id, index)
                                          }
                                          size="small"
                                        >
                                          <Add fontSize="small"></Add>
                                        </IconButton>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        )}
                        {preview && (
                          <div className="flex justify-center text-[var(--dark-blue)] text-sm font-semibold">
                            <span>
                              Components not available in preview mode.
                            </span>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={8.5} xs={12} className="border mx-auto mb-3">
                      <div className="card-body sm-px-0">
                        <h5 className="card-title text-primary">
                          {preview ? 'Card Preview' : 'Card Designing Editor'}
                        </h5>
                        <hr />
                        <div>
                          <div className="my-3 flex flex-col gap-1 ">
                            <label htmlFor="name" className="text-sm">
                              Template Name{' '}
                              <sup className="text-red-600 text-sm">*</sup>
                            </label>

                            <TextFieldWrapper
                              type={'text'}
                              inputProps={{ id: 'name', maxLength: 30 }}
                              name="name"
                              placeholder="Try to set unique name."
                              value={values.name}
                              error={!!touched.name && !!errors.name}
                              helperText={!!touched.name && errors.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={30}
                            />

                            {validationErrors.name !== '' && (
                              <small className="text-danger">
                                {validationErrors.name}
                              </small>
                            )}
                          </div>
                          {!preview && (
                            <div className="flex justify-between flex-wrap mb-4">
                              <div className="flex gap-2 sm:flex-row flex-col">
                                <button
                                  type="button"
                                  onClick={() => setTemplateType('l')}
                                  className={`text-xs text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer ${
                                    templateType === 'l'
                                      ? 'bg-main'
                                      : 'border border-1 border-[var(--blue)]'
                                  }`}
                                >
                                  Landscape Style
                                </button>

                                <button
                                  type="button"
                                  onClick={() => setTemplateType('v')}
                                  className={`text-xs text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer ${
                                    templateType === 'v'
                                      ? 'bg-main'
                                      : 'border border-1 border-[var(--blue)]'
                                  }`}
                                >
                                  Portrait Style
                                </button>
                              </div>

                              <div className="flex items-center gap-1">
                                <input
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                  type="checkbox"
                                  checked={showEditorLines}
                                  onChange={(e) => {
                                    setShowEditorLines(e.target.checked);
                                  }}
                                  id="showEditorLines"
                                />
                                <label
                                  htmlFor="showEditorLines"
                                  className="text-xs"
                                >
                                  Show grid on editor
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        {!preview && (
                          <Grid container spacing={2}>
                            <Grid item xs={12} className="flex justify-center">
                              <DesignCard
                                setBg={setBg}
                                setPhoto={setPhoto}
                                photo={photo}
                                cardComponent={cardComponent}
                                setCardComponent={setCardComponent}
                                cardBackground={cardBackground}
                                setCardBackground={setCardBackground}
                                availableComponent={availableComponent}
                                setAvailableComponent={setAvailableComponent}
                                photoPreview={photoPreview}
                                setPhotoPreview={setPhotoPreview}
                                selectedComponent={selectedComponent}
                                setSelectedComponent={setSelectedComponent}
                                modal={modal}
                                setModal={setModal}
                                modalData={modalData}
                                setModalData={setModalData}
                                additionalCardProperties={
                                  additionalCardProperties
                                }
                                setAdditionalCardProperties={
                                  setAdditionalCardProperties
                                }
                                orientation={templateType}
                                showEditorLines={showEditorLines}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <div className="flex flex-wrap gap-2">
                                <div className="bg-[var(--yellow)] text-sm py-1 px-2 flex rounded-md items-center">
                                  <div className="flex-grow-1 text-left sm:block hidden">
                                    Card Background
                                  </div>
                                  <div className="flex-grow-1 text-left sm:hidden block">
                                    Card Bg
                                  </div>
                                  <div className="ml-2">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        prepareSettingData(cardBackground)
                                      }
                                    >
                                      <Settings fontSize="small" />
                                    </IconButton>
                                  </div>
                                </div>
                                {cardComponent.map((item, _index) => {
                                  return (
                                    <div
                                      key={_index}
                                      className="bg-[var(--yellow)] text-sm py-1 px-2 flex rounded-md items-center"
                                    >
                                      <div
                                        className="flex-grow-1 text-left"
                                        key={item.id}
                                        id={item.id}
                                      >
                                        {item.title}
                                      </div>
                                      <div className="ml-2">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            prepareSettingData(item)
                                          }
                                        >
                                          <Settings fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            deleteComponent(item.id, item.title)
                                          }
                                        >
                                          <Delete fontSize="small" />
                                        </IconButton>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </Grid>
                          </Grid>
                        )}

                        {preview && (
                          <div className="flex justify-center overflow-x-auto">
                            <CardPreview
                              cardBackground={JSON.stringify(cardBackground)}
                              cardComponent={JSON.stringify(cardComponent)}
                              backgroundImage={photoPreview}
                              localBackground={true}
                              className="border shadow"
                              orientation={templateType}
                            />
                          </div>
                        )}

                        <div className="flex justify-between items-center gap-2 flex-wrap">
                          {user?.role_id === 2 || user?.role_id === 3 ? (
                            <div className="form-check m-0 align-items-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                checked={isPublic}
                                onChange={(e) => {
                                  setIsPublic(e.target.checked);
                                }}
                              />
                              <label
                                className="form-check-label pl-1"
                                for="flexCheckDefault"
                              >
                                Public
                              </label>
                              <Tooltip
                                placement="top"
                                arrow
                                title="If this template is marked as 'Public,' it will be visible and accessible to all users of this website. Otherwise, only you and your company will have access to it."
                              >
                                <Help
                                  className="ml-2"
                                  sx={{ fontSize: '16px' }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <i></i>
                          )}

                          <div className="flex justify-end mt-3 sm:flex-row flex-colu gap-2">
                            <button
                              type="button"
                              className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                              style={{ borderRadius: 50 }}
                              onClick={() => setPreview(!preview)}
                            >
                              {preview ? 'Design' : 'Preview'}
                            </button>
                            <button
                              type="button"
                              className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                              style={{ borderRadius: 50 }}
                              onClick={(e) => {
                                setSubmitFor('save');
                                handleSubmit();
                              }}
                              disabled={loading}
                            >
                              {'Save Template'}
                            </button>
                            {cardId && (
                              <button
                                type="button"
                                className="text-xs bg-[var(--yellow)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                                style={{ borderRadius: 50 }}
                                onClick={(e) => {
                                  setSubmitFor('save-as');
                                  handleSubmit();
                                }}
                                disabled={loading}
                              >
                                {'Save As'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Formik>
            </div>
          )}
          {activeTab === 1 && (
            <div className="flex-1 mt-12 mx-auto max-w-[800px]">
              <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                {loading ? (
                  <CircularProgress />
                ) : responseData?.status ? (
                  <Fragment>
                    <TaskAltOutlined
                      className="text-[var(--yellow)]"
                      sx={{ fontSize: '5rem' }}
                    />
                    <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                      {responseData?.message}
                    </div>
                  </Fragment>
                ) : (
                  !responseData?.status &&
                  responseData?.message && (
                    <Fragment>
                      <GppBadOutlined
                        className="text-[var(--pink)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--pink)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  )
                )}
              </div>

              {!loading && !responseData?.status && (
                <div className="flex items-center justify-between mt-8">
                  <button
                    onClick={() => {
                      setResponseData(null);
                      setActiveTab(0);
                    }}
                    className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                  >
                    Back
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {/* <Modal
        isOpen={isOpenModal}
        toggle={toggle}
        backdrop={true}
        centered={true}
      >
        <ModalHeader>Saving...</ModalHeader>
        <ModalBody>Template is saving please wait</ModalBody>
      </Modal> */}
    </div>
  );
};

export default CardEditor;
