import {
  Add,
  Edit,
  Home,
  Logout,
  Notifications,
  Person,
  Settings,
} from '@mui/icons-material';
import { Avatar, Badge, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';
import { useSelector } from 'react-redux';
import { toAbsoluteURL } from '../../constants/shared-methods';
import NotificationService from '../../services/notification.service';
import useScreen from '../../hooks/useScreen';
import moment from 'moment';

function TopHeaderMenu() {
  const navigate = useNavigate();
  const { user, isUser, isCompany, company } = useSelector(({ user }) => user);
  const { isPageLoaded } = useScreen();
  const [notifications, setNotifications] = useState();
  const [anchorProfile, setAnchorProfile] = useState(null);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const openProfile = Boolean(anchorProfile);
  const openNotification = Boolean(anchorNotification);
  const handleClickProfile = (event) => {
    setAnchorProfile(event.currentTarget);
  };
  const handleClickNotification = (event) => {
    setAnchorNotification(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorProfile(null);
  };
  const handleCloseNotification = () => {
    setAnchorNotification(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    handleCloseProfile();
    window.location.reload(true);
  };

  const handleProfile = () => {
    handleCloseProfile();
    navigate(routesName.profile);
  };

  const handleEditProfile = () => {
    handleCloseProfile();
    navigate(routesName.editProfile);
  };

  const handleSetting = () => {
    handleCloseProfile();
    navigate(routesName.appSetting);
  };

  const getNotification = () => {
    NotificationService.getNotificationsOfCompany(company.user_id)
      .then((res) => {
        if (res.success) {
          setNotifications(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (isPageLoaded && company?.user_id && (isCompany || isUser)) {
      getNotification();
      setTimeout(() => getNotification(), 300000);
    }
  }, [isPageLoaded, isCompany, isUser]);

  const getUnseenNotificationInfo = () => {
    let counter = 0;
    Array.isArray(notifications) &&
      notifications.length > 0 &&
      notifications.map((item) => {
        if (item.seen_status === 0) {
          ++counter;
        }
      });
    return counter;
  };

  return (
    <div className="flex justify-end px-4 py-2 mt-4">
      <div className="flex justify-between items-center gap-6">
        {isUser && !company && (
          <div>
            <Link
              to={routesName.createCompany}
              className="text-xs bg-main text-center px-3 py-1 rounded-full font-bold flex items-center justify-center gap-1"
            >
              <Add className="text-white" fontSize="small" />
              <span>Add Company</span>
            </Link>
          </div>
        )}
        <div className="flex items-center gap-2">
          <IconButton onClick={() => navigate(routesName.home)}>
            <Home />
          </IconButton>
          {(isCompany || isUser) && (
            <IconButton
              id="notification-button"
              aria-controls={openNotification ? 'notification-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNotification ? 'true' : undefined}
              onClick={handleClickNotification}
            >
              <Badge
                badgeContent={getUnseenNotificationInfo()}
                fontSize={'small'}
                color="error"
                overlap="circular"
                invisible={false}
                max={99}
              >
                <Notifications />
              </Badge>
            </IconButton>
          )}

          <IconButton
            id="profile-button"
            aria-controls={openProfile ? 'profile-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openProfile ? 'true' : undefined}
            onClick={handleClickProfile}
          >
            <Avatar
              style={{ height: '25px', width: '25px' }}
              src={toAbsoluteURL(user?.photo)}
            />
          </IconButton>

          <Menu
            id="profile-menu"
            anchorEl={anchorProfile}
            open={openProfile}
            onClose={handleCloseProfile}
            MenuListProps={{
              'aria-labelledby': 'profile-button',
            }}
          >
            <div className="flex gap-2 px-2 py-2">
              <Avatar
                style={{ height: '34px', width: '34px' }}
                src={toAbsoluteURL(user?.photo)}
              />
              <div className="flex-1">
                <div className="text-sm font-bold">{user?.name}</div>
                <div className="text-xs">
                  {user.role_id === 1
                    ? 'Super Admin'
                    : user.role_id === 2
                    ? 'User'
                    : user.role_id === 3
                    ? 'Company'
                    : 'Employee'}
                </div>
              </div>
            </div>
            <div className="border-b border-2" />

            <MenuItem onClick={handleProfile}>
              <Person fontSize="small" className="mr-1" />
              Profile
            </MenuItem>
            <MenuItem onClick={handleEditProfile}>
              <Edit fontSize="small" className="mr-1" /> Edit Profile
            </MenuItem>
            {user?.role_id === 1 && (
              <MenuItem onClick={handleSetting}>
                <Settings fontSize="small" className="mr-1" /> Settings
              </MenuItem>
            )}
            <div className="border-b border-2" />
            <MenuItem onClick={handleLogout}>
              <Logout fontSize="small" className="mr-1" /> Logout
            </MenuItem>
          </Menu>

          <Menu
            id="notification-menu"
            anchorEl={anchorNotification}
            open={openNotification}
            onClose={handleCloseNotification}
            MenuListProps={{
              'aria-labelledby': 'notification-button',
            }}
            className="py-0"
          >
            <div className="min-w-72 px-4 py-2 font-bold border-b border-b-4 mb-2">
              Notifications
            </div>
            {notifications &&
            Array.isArray(notifications) &&
            notifications.length > 0 ? (
              notifications.map((item, index) => (
                <MenuItem key={index}>
                  <div className="flex flex-col">
                    <div className="text-sm font-bold">{item?.title}</div>
                    <div className="text-xs">{item?.notification}</div>
                    <div className="text-xs text-right mt-2">
                      {moment(item?.created_at).fromNow()}
                    </div>
                  </div>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <div className="w-full">No notification found!</div>
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default TopHeaderMenu;
