import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Add, Edit } from '@mui/icons-material';
import useScreen from '../../hooks/useScreen';
import { Navigate } from 'react-router-dom';
import useNotification from '../../hooks/useNotification';
import { routesName } from '../../constants/routes-name';
import { useSelector } from 'react-redux';
import SettingService from '../../services/setting.service';
import { Formik } from 'formik';
import { OverviewComponent, TextFieldWrapper } from '../../components';
import { validationSchema } from '../../constants/validationSchema';
import * as yup from 'yup';

const forAdd = 'modal-for-add';
const forEdit = 'modal-for-edit';
const forView = 'modal-for-view';

function Component() {
  const { user } = useSelector(({ user }) => user);

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { successNonification, errorNonification } = useNotification();
  const { isPageLoaded } = useScreen();
  const [paymentSettings, setPaymentSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);

  const [publicKey, setPublicKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [keyId, setKeyId] = useState('');

  const [openModalFor, setOpenModalFor] = useState('');

  const closeModal = () => {
    setOpenModalFor('');
    setPublicKey('');
    setSecretKey('');
  };

  const getPaymentSettings = () => {
    SettingService.getPaymentSettings()
      .then((res) => {
        setPaymentSettings(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getPaymentSettings();
    }
  }, [isPageLoaded]);

  const handleStatus = (id) => {
    SettingService.changePaymentSettingStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Status changed.');
          getPaymentSettings();
        }
      })
      .catch((error) => errorNonification('Something went wrong'));
  };

  const columns = [
    {
      field: 'srNo',
      headerName: 'SR.NO',
      minWidth: 90,
      sortable: false,
    },
    {
      field: 'stripe_key',
      sortable: false,
      headerName: 'STRIPE KEY',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'stripe_secret',
      sortable: false,
      headerName: 'STRIPE SECRET',
      minWidth: 210,
      flex: 1,
    },
    {
      field: 'default_setting',
      headerName: 'STATUS',
      sortable: false,
      minWidth: 120,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center justify-center text-[var(--gray)]">
          {params.row.default_setting ? (
            <span
              onClick={() => handleStatus(params.id)}
              className="cursor-pointer bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Active
            </span>
          ) : (
            <span
              onClick={() => handleStatus(params.id)}
              className="cursor-pointer bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Inactive
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 120,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Edit">
              <IconButton
                size="small"
                onClick={() => {
                  setPublicKey(params.row.stripe_key);
                  setSecretKey(params.row.stripe_secret);
                  setKeyId(params.row.id);
                  setOpenModalFor(forEdit);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows =
    paymentSettings &&
    Array.isArray(paymentSettings) &&
    paymentSettings.length > 0 &&
    paymentSettings.map((item, index) => ({
      id: item?.id,
      srNo: index + 1,
      default_setting: item?.default_setting,
      stripe_key: item?.stripe_key,
      stripe_secret: item?.stripe_secret,
    }));

  const savePaymentSetting = (publicKey = '', secretKey = '') => {
    setIsSavingForm(true);

    const formObj = {
      stripe_key: publicKey,
      stripe_secret: secretKey,
    };

    if (!!keyId && openModalFor === forEdit) {
      SettingService.updatePaymentSettings(keyId, formObj)
        .then((res) => {
          if (res.success) {
            getPaymentSettings();
            successNonification('Updated Successfully!');
            closeModal();
          } else {
            errorNonification('Something went wrong');
          }
          setIsSavingForm(false);
        })
        .catch((error) => {
          setIsSavingForm(false);
          errorNonification(
            error?.response?.data?.message || 'Something went wrong'
          );
        });
    } else {
      SettingService.savePaymentSettings(formObj)
        .then((res) => {
          if (res.success) {
            getPaymentSettings();
            successNonification('Saved Successfully!');
            closeModal();
          } else {
            errorNonification('Something went wrong');
          }
          setIsSavingForm(false);
        })
        .catch((error) => {
          setIsSavingForm(false);
          errorNonification(
            error?.response?.data?.message || 'Something went wrong'
          );
        });
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <OverviewComponent />
      <div className="flex flex-col gap-8">
        <div>
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">Payment Setting</div>

            <button
              onClick={() => setOpenModalFor(forAdd)}
              className="text-xs bg-main text-center px-3 py-1 rounded-full font-bold flex items-center justify-center gap-1"
            >
              <Add className="text-white" fontSize="small" />
              <span>Add Keys</span>
            </button>
          </div>
          <div>
            {isLoading && (
              <div className="mt-36 mx-auto max-w-[500px]">
                <LinearProgress />
              </div>
            )}
            {rows && (
              <DataGrid
                sx={{
                  '& .MuiDataGrid-columnHeaders div': {
                    backgroundColor: 'inherit !important',
                  },
                  '& .MuiDataGrid-virtualScrollerContent': {
                    backgroundColor: 'white !important',
                  },
                  '& .MuiDataGrid-row': {
                    maxHeight: 'auto',
                    height: 'auto',
                  },
                  /*   '& .MuiDataGrid-cell': {
              height: 'auto',
              whiteSpace: 'normal',
              wordBreak: 'break-all',
              lineHeight: 'normal',
              padding: '5px',
              fontSize: '11px',
            }, */
                }}
                rows={rows}
                columns={columns}
                disableColumnMenu
                hideFooter
                autoHeight
              />
            )}
          </div>
        </div>

        <Modal open={!!openModalFor} onClose={() => {}}>
          <Box className="bg-gray-100" sx={style}>
            <div className="text-xl font-bold mb-4">
              {openModalFor === forAdd
                ? 'Add Keys'
                : openModalFor === forEdit
                ? 'Edit Keys'
                : 'Keys Detail'}
            </div>

            <div>
              {(openModalFor === forAdd || openModalFor === forEdit) && (
                <div>
                  <Formik
                    initialValues={{
                      secretKey: secretKey,
                      publicKey: publicKey,
                    }}
                    validationSchema={yup.object().shape({
                      secretKey: validationSchema.required('Secret Key'),
                      publicKey: validationSchema.required('Public Key'),
                    })}
                    onSubmit={(values) => {
                      try {
                        savePaymentSetting(values.publicKey, values.secretKey);
                      } catch (error) {}
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                    }) => (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Secret Key
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="secretKey"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.secretKey}
                              helperText={touched.secretKey && errors.secretKey}
                              error={!!touched.secretKey && !!errors.secretKey}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="flex flex-col gap-2">
                            <label className="text-xs">
                              Public Key
                              <span className="text-red-500">*</span>
                            </label>
                            <TextFieldWrapper
                              placeholder="Placeholder"
                              name="publicKey"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.publicKey}
                              helperText={touched.publicKey && errors.publicKey}
                              error={!!touched.publicKey && !!errors.publicKey}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="flex items-center justify-end gap-3">
                            <button
                              onClick={closeModal}
                              className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                            >
                              Save
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </Formik>
                </div>
              )}

              {openModalFor === forView && <div>Detail</div>}
            </div>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isSavingForm}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default memo(Component);

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};
