import {
  Avatar,
  Backdrop,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Archive, Delete, Details, Edit, Unarchive } from '@mui/icons-material';
import { toAbsoluteURL, useQuery } from '../../constants/shared-methods';
import useScreen from '../../hooks/useScreen';
import { initialPagination } from '../../constants/constant-values';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useNotification from '../../hooks/useNotification';
import { routesName } from '../../constants/routes-name';
import { PaginationWrapper } from '../../components';
import EmployeeService from '../../services/employee.service';
import PackageService from '../../services/package.service';

function PackageTableView({ loading = false }) {
  const { successNonification, errorNonification } = useNotification();
  const navigate = useNavigate();
  const query = useQuery();
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);

  const getAllCompanies = () => {
    PackageService.getAllPackages(page || 1)
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        setPackages(data.data);
        pagination.from = data.from;
        pagination.to = data.to;
        pagination.total = data.total;
        pagination.perPage = data.per_page;
        pagination.currentPage = data.current_page;
        pagination.lastPage = data.last_page;
        setPagination({ ...pagination });
      })
      .catch((error) => {
        setPackages([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getAllCompanies();
    }
  }, [isPageLoaded, page]);

  const handleChangeArchive = (id) => {
    EmployeeService.changeEmployeeArchiveStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Archive status has been changed.');
          getAllCompanies();
        }
      })
      .catch(() => errorNonification('Something went wrong'));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        EmployeeService.deleteEmployee(id)
          .then((res) => {
            if (res.success) {
              getAllCompanies();
              successNonification('Employee has been deleted.');
            }
          })
          .catch(() => errorNonification('Something went wrong'));
      }
    });
  };

  const handleStatus = (id) => {
    PackageService.changeStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Status changed.');
          getAllCompanies();
        }
      })
      .catch((error) => errorNonification('Something went wrong'));
  };

  const columns = [
    {
      field: 'title',
      sortable: false,
      headerName: 'Title',
      minWidth: 220,
      flex: 1,
    },
    {
      field: 'monthly_price',
      sortable: false,
      headerName: 'MONTHLY PRICE',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'annually_price',
      sortable: false,
      headerName: 'ANNUALLY PRICE',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'currency',
      sortable: false,
      headerName: 'CURRENCY',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'no_of_cards',
      sortable: false,
      headerName: 'NO OF CARDS',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center justify-center text-[var(--gray)]">
          {params.row.status ? (
            <span
              onClick={() => handleStatus(params.row.id)}
              className="cursor-pointer bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Active
            </span>
          ) : (
            <span
              onClick={() => handleStatus(params.row.id)}
              className="cursor-pointer bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Inactive
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 180,
      flex: 0.8,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Detail">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`${routesName.packageDetailPath}${params?.row?.id}`);
                }}
              >
                <Details />
              </IconButton>
            </Tooltip>
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Edit">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`${routesName.editPackagePath}${params?.row?.id}`);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  console.log(packages);

  const rows =
    packages &&
    Array.isArray(packages) &&
    packages.length > 0 &&
    packages.map((item, index) => ({
      annually_price: item?.annually_price,
      created_at: item?.created_at,
      currency: item?.currency,
      deleted_at: item?.deleted_at,
      discount_type: item?.discount_type,
      featured: item?.featured,
      icon: item?.icon,
      icon_logo: item?.icon_logo,
      id: item?.id,
      interval: item?.interval,
      is_free_plan: item?.is_free_plan,
      monthly_price: item?.monthly_price,
      no_of_cards: item?.no_of_cards,
      no_of_templates: item?.no_of_templates,
      percent_discount: item?.percent_discount,
      price_discount: item?.price_discount,
      status: item?.status,
      title: item?.title,
      updated_at: item?.updated_at,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.package}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default memo(PackageTableView);

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};
