import API from '../config/api.config';
import StorageService from './storage.service';

const login = (credentials) => {
  return API.post('/login', credentials).then((res) => {
    return res.data;
  });
  // .catch(error => {
  //     console.log(error.response.data)
  //     return error.response.data
  // })
};

const resendVerification = (email) => {
  return API.get(`/resendVerification/${email}`).then((res) => {
    return res.data;
  });
};

const verifyAccount = (data) => {
  return API.post('/verifyAccount', data).then((res) => {
    return res.data;
  });
};

const register = (credentials) => {
  return API.post('/register', credentials).then((res) => {
    return res.data;
  });
  // .catch(error => {
  //     return error.response.data
  // })
};

const logout = () => {
  return API.get('/logout').then((res) => {
    return res.data;
  });
};

const me = () => {
  return API.post('/me').then((res) => {
    return res.data;
  });
};

const isLoggedIn = () => {
  // return !!(StorageService.getCurrentUser() && StorageService.getToken())
  return StorageService.getCurrentUser() && StorageService.getToken()
    ? true
    : false;
};

const forgetPassword = (data) => {
  return API.post('/forgotPassword', data).then((res) => {
    return res.data;
  });
};

const resettingPassword = (data) => {
  return API.post('/resetPassword', data).then((res) => {
    return res.data;
  });
};

const AuthService = {
  verifyAccount,
  login,
  register,
  logout,
  isLoggedIn,
  forgetPassword,
  resettingPassword,
  resendVerification,
  me,
};

export default AuthService;
