import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './css/CustomCalendar.css';
import moment from 'moment';

function Component({ subscriptionDates }) {
  const result = new Date();
  result.setDate(result.getDate() + 2);

  const [selected] = useState(result);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (subscriptionDates) {
      setStartDate(new Date(moment(subscriptionDates?.start_date) || ''));
      setEndDate(new Date(moment(subscriptionDates?.expiry_date) || ''));
    }
  }, [subscriptionDates]);

  return (
    <Calendar
      className="text-sm w-full"
      value={endDate}
      tileClassName={({ date }) =>
        endDate && date.toDateString() === endDate.toDateString()
          ? 'selected'
          : ''
      }
      showNavigation={false}
      view="month"
      //tileDisabled={() => true}
    />
  );
}

export default Component;
