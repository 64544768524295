import {
  Apartment,
  CreditCard,
  ExpandMore,
  Groups,
  Login,
  Quiz,
  Badge,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FaqService from '../services/faq.service';
import useScreen from '../hooks/useScreen';

function FAQsPage() {
  const { isPageLoaded, width } = useScreen();
  const FaqTypes = [
    'company',
    'employee',
    'card template',
    'payment gateway',
    'authentication',
    'other',
  ];
  const [faqs, setFaqs] = useState();
  const [selectedTab, setSelectedTab] = useState('company');

  const handleChangeTab = (event, newValue) => {
    setFaqs();
    setSelectedTab(newValue);
  };

  const getFAQs = () => {
    FaqService.getFAQsByType(selectedTab)
      .then((res) => {
        if (res.success) {
          setFaqs(res.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isPageLoaded) {
      getFAQs();
    }
  }, [isPageLoaded, selectedTab]);

  return (
    <div className="container mx-auto px-4 flex flex-col gap-8">
      <div className="py-12 flex flex-col md:gap-12 gap-6">
        <div className="text-center">
          <div className="text-3xl font-bold">FAQs</div>
        </div>
        <div className="bg-white lg:px-12 md:px-6 px-4 lg:py-12 md:py-6 py-4 rounded-lg">
          <Grid container spacing={4}>
            <Grid item md={3} xs={12}>
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                textColor="primary"
                indicatorColor="primary"
                orientation={width >= 900 ? 'vertical' : 'horizontal'}
                variant="scrollable"
                scrollButtons="auto"
              >
                {FaqTypes.map((item, index) => (
                  <Tab className="text-[14px]" value={item} label={item} />
                ))}
              </Tabs>
            </Grid>
            <Grid item md={9} xs={12}>
              {selectedTab === 'company' && (
                <div>
                  <div className="flex items-center gap-3">
                    <Apartment fontSize="large" />
                    <div>
                      <div className="text-lg font-bold">Company</div>
                      <div className="text-xs">Get help with Company</div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-col gap-4">
                      {faqs &&
                        Array.isArray(faqs) &&
                        faqs.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className="text-md font-bold"
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails className="text-sm">
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 'employee' && (
                <div>
                  <div className="flex items-center gap-3">
                    <Groups fontSize="large" />
                    <div>
                      <div className="text-lg font-bold">Employee</div>
                      <div className="text-xs">Get help with Employee</div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-col gap-4">
                      {faqs &&
                        Array.isArray(faqs) &&
                        faqs.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className="text-md font-bold"
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails className="text-sm">
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 'card template' && (
                <div>
                  <div className="flex items-center gap-3">
                    <Badge fontSize="large" />
                    <div>
                      <div className="text-lg font-bold">Card template</div>
                      <div className="text-xs">Get help with card template</div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-col gap-4">
                      {faqs &&
                        Array.isArray(faqs) &&
                        faqs.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className="text-md font-bold"
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails className="text-sm">
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 'payment gateway' && (
                <div>
                  <div className="flex items-center gap-3">
                    <CreditCard fontSize="large" />
                    <div>
                      <div className="text-lg font-bold">Payment gateway</div>
                      <div className="text-xs">
                        Get help with Payment gateway
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-col gap-4">
                      {faqs &&
                        Array.isArray(faqs) &&
                        faqs.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className="text-md font-bold"
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails className="text-sm">
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 'authentication' && (
                <div>
                  <div className="flex items-center gap-3">
                    <Login fontSize="large" />
                    <div>
                      <div className="text-lg font-bold">Authentication</div>
                      <div className="text-xs">
                        Get help with Authentication
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-col gap-4">
                      {faqs &&
                        Array.isArray(faqs) &&
                        faqs.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className="text-md font-bold"
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails className="text-sm">
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === 'other' && (
                <div>
                  <div className="flex items-center gap-3">
                    <Quiz fontSize="large" />
                    <div>
                      <div className="text-lg font-bold">Other</div>
                      <div className="text-xs">Get help with Other</div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-col gap-4">
                      {faqs &&
                        Array.isArray(faqs) &&
                        faqs.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              className="text-md font-bold"
                            >
                              {item.question}
                            </AccordionSummary>
                            <AccordionDetails className="text-sm">
                              {item.answer}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default FAQsPage;
