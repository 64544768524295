import React, { Fragment, useEffect, useState } from 'react';
import {
  CardPreview,
  StepperWrapper,
  TextFieldWrapper,
} from '../../components';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { GppBadOutlined, TaskAltOutlined } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/navigation';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../constants/validationSchema';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useScreen from '../../hooks/useScreen';
import { routesName } from '../../constants/routes-name';
import TemplateService from '../../services/template.service';
import EmployeeService from '../../services/employee.service';
import CompanyService from '../../services/company.service';
import useNotification from '../../hooks/useNotification';
import CardService from '../../services/card.service';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (isCompany || isUser) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const steps = ['Card Detail', 'Summary'];
  const [activeTab, setActiveTab] = useState(0);
  const { isPageLoaded } = useScreen();
  const navigate = useNavigate();

  const { user, company } = useSelector(({ user }) => user);
  const { errorNonification } = useNotification();
  const params = useParams();

  const [responseData, setResponseData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPackage, setIsLoadingPackage] = useState(!!params.id);
  const [slug, setSlug] = useState('');

  const [templateList, setTemplateList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState({});
  const [templateId, setTemplateId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const getAllCompanies = () => {
    CompanyService.getCompaniesList()
      .then((res) => {
        const data = res.data;
        setCompanyList(data);
      })
      .catch(() => errorNonification('Companies not found'));
  };

  const getAllTemplates = () => {
    TemplateService.getTemplatesList()
      .then((res) => {
        const data = res?.data;
        let array = [];
        data &&
          data.length > 0 &&
          data.map((item) => {
            const obj = { label: item.title, value: item.id };
            array.push(obj);
          });
        setTemplateList(array);
      })
      .catch(() => errorNonification('Templates not found'));
  };

  const getTemplateById = (id) => {
    TemplateService.getTemplateById(id)
      .then((res) => {
        setCardData(res?.data);
      })
      .catch(() => errorNonification('Selected template not found'));
  };

  const getEmployeesByCompany = (company_id) => {
    EmployeeService.getEmployeesList(company_id)
      .then((res) => {
        if (res.success) {
          const data = res.data;
          if (data.length === 0) {
            errorNonification('No employee found in this company.');
            return navigate(routesName.createEmployee);
          } else setEmployeeList(data);
        }
      })
      .catch(() => errorNonification('Epmloyees not found'));
  };

  const getEmployeeDetails = (id) => {
    EmployeeService.getEmployeeDetails(id)
      .then((res) => {
        const data = res.data;
        setEmployeeDetail(data?.personal);
      })
      .catch(() => errorNonification('This employee detail not found'));
  };

  const hasCompany = () => {
    if (user.role_id === 3) {
      setCompanyId(company?.user_id);
    } else if (user.role_id === 2) {
      CompanyService.hasCompanyRegistered(user.id)
        .then((res) => {
          setCompanyId(res?.data?.user_id);
          if (!res.data) {
            if (!company) {
              errorNonification('No company registered yet.');
              return navigate(routesName.createCompany);
            }
          }
        })
        .catch((error) => {
          errorNonification('Something went wrong while getting your company');
        });
    } else if (user.role_id === 1) {
      getAllCompanies();
    }
  };

  const getCardDetail = () => {
    CardService.getCardDetails(params.id)
      .then((res) => {
        const data = res.data;
        setCardData(data?.template);
        setEmployeeDetail(data?.employee);
        setSlug(data?.business_card?.slug);
        setTemplateId(data?.business_card?.template_id);
        setCompanyId(data?.business_card?.company_id);
        setEmployeeId(data?.business_card?.employee_id);
        setIsLoadingPackage(false);
      })
      .catch(() => navigate(routesName[404]));
  };

  const submitCard = () => {
    setIsLoading(true);

    const data = {
      company_id: companyId,
      employee_id: employeeId,
      template_id: templateId,
      slug: slug,
    };

    if (params.id) {
      CardService.updateCard(params.id, data)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Card has been updated successfully',
            });
            // navigate('/package', { replace: true });
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    } else {
      console.log(companyId);
      CardService.saveCard(companyId, data)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setResponseData({
              status: true,
              message: 'Card has been created successfully',
            });
            //navigate('/package', { replace: true });
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (activeTab === 1) {
      submitCard();
    }
  }, [activeTab]);

  useEffect(() => {
    if (isPageLoaded) {
      getAllTemplates();
      hasCompany();
    }
    if (isPageLoaded && params.id) {
      getCardDetail();
    }
  }, [isPageLoaded]);
  useEffect(() => {
    if (companyId) {
      getEmployeesByCompany(companyId);
    }
  }, [companyId]);

  return (
    <div>
      <div className="text-2xl font-bold">
        {params.id ? 'Card Altering' : 'Adding new card'}
      </div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>

        {!isLoadingPackage ? (
          <div>
            {activeTab === 0 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    slug: slug,
                    cardId: templateId,
                    //companyId: companyId,
                    employeeId: employeeId,
                  }}
                  validationSchema={yup.object().shape({
                    slug: validationSchema.required('Slug'),
                    cardId: validationSchema.required('Template'),
                    //companyId: validationSchema.required('Company'),
                    employeeId: validationSchema.required('Emplyee'),
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setSlug(values.slug);
                      setTemplateId(values.cardId);
                      // setCompanyId(values.companyId);
                      setEmployeeId(values.employeeId);

                      setActiveTab(1);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    validateField,
                    setFieldError,
                  }) => {
                    return (
                      <div>
                        <Grid container spacing={4}>
                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Slug <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="slug"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.slug}
                                helperText={touched.slug && errors.slug}
                                error={!!touched.slug && !!errors.slug}
                              />
                            </div>
                          </Grid>

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Card Template{' '}
                                <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                select
                                name="cardId"
                                onChange={(e) => {
                                  handleChange(e);
                                  getTemplateById(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.cardId}
                                helperText={touched.cardId && errors.cardId}
                                error={!!touched.cardId && !!errors.cardId}
                              >
                                {/* <MenuItem value={''}>Select</MenuItem> */}
                                {templateList &&
                                  Array.isArray(templateList) &&
                                  templateList.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                              </TextFieldWrapper>
                            </div>
                          </Grid>

                          {/*  {user?.role_id === 1 && (
                            <Grid item md={4} sm={6} xs={12}>
                              <div className="flex flex-col gap-2">
                                <label className="text-xs">
                                  Company{' '}
                                  <span className="text-red-500">*</span>
                                </label>
                                <TextFieldWrapper
                                  placeholder="Placeholder"
                                  select
                                  name="companyId"
                                  onChange={(e) => {
                                    handleChange(e);
                                    getEmployeesByCompany(e.target.value);
                                    setEmployeeDetail({});
                                    setFieldValue('employeeId', '');
                                    setFieldError('employeeId', false);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.companyId}
                                  helperText={
                                    touched.companyId && errors.companyId
                                  }
                                  error={
                                    !!touched.companyId && !!errors.companyId
                                  }
                                >
                                  {companyList &&
                                    Array.isArray(companyList) &&
                                    companyList.map((item, index) => (
                                      <MenuItem key={index} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                </TextFieldWrapper>
                              </div>
                            </Grid>
                          )} */}

                          <Grid item md={4} sm={6} xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Employee <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                select
                                name="employeeId"
                                onChange={(e) => {
                                  handleChange(e);
                                  getEmployeeDetails(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.employeeId}
                                helperText={
                                  touched.employeeId && errors.employeeId
                                }
                                error={
                                  !!touched.employeeId && !!errors.employeeId
                                }
                              >
                                {employeeList &&
                                  Array.isArray(employeeList) &&
                                  employeeList.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                              </TextFieldWrapper>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="flex justify-center gap-2 overflow-x-auto">
                              {cardData && (
                                <CardPreview
                                  userData={employeeDetail}
                                  cardBackground={cardData.card_background}
                                  backgroundImage={cardData.background_image}
                                  cardComponent={cardData.card_component}
                                  cardData={cardData}
                                  className="border shadow"
                                  orientation={cardData?.orientation}
                                />
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <div className="flex items-center justify-end mt-8">
                          <button
                            onClick={handleSubmit}
                            className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}

            {activeTab === 1 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                  {isLoading ? (
                    <CircularProgress />
                  ) : responseData?.status ? (
                    <Fragment>
                      <TaskAltOutlined
                        className="text-[var(--yellow)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  ) : (
                    !responseData?.status &&
                    responseData?.message && (
                      <Fragment>
                        <GppBadOutlined
                          className="text-[var(--pink)]"
                          sx={{ fontSize: '5rem' }}
                        />
                        <div className="text-[var(--pink)] font-bold text-2xl text-center">
                          {responseData?.message}
                        </div>
                      </Fragment>
                    )
                  )}
                </div>

                {!isLoading && !responseData?.status && (
                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={() => {
                        setResponseData(null);
                        setActiveTab(0);
                      }}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
