import React, { useEffect, useState } from 'react';
import { CardTab, CompanyDetailsTab } from '../components';
import { LinearProgress } from '@mui/material';
import CompanyService from '../services/company.service';
import useNotification from '../hooks/useNotification';
import useScreen from '../hooks/useScreen';
import { useParams } from 'react-router-dom';
import CardService from '../services/card.service';

function ViewCard() {
  const params = useParams();
  const { isPageLoaded } = useScreen();
  const { errorNonification } = useNotification();

  const [templateData, setTemplateData] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getCardDetails = () => {
    if (params?.id !== NaN) {
      setIsLoading(true);

      CardService.getPublicCardDetail(params.id)
        .then((res) => {
          debugger;
          setIsLoading(false);
          if (res.success) {
            setTemplateData(res.data?.template);
            setEmployeeData(res.data?.employee);
            setCompanyData(res.data?.company);
          } else {
            errorNonification('Something went wrong');
          }
        })
        .catch((error) => {
          errorNonification(
            error?.response?.data?.message || 'Something went wrong'
          );
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      getCardDetails();
    }
  }, [isPageLoaded]);

  return (
    <div className="container mx-auto px-4 flex flex-col gap-8">
      <div className="py-12 flex flex-col md:gap-12 gap-6">
        <div className="text-center">
          <div className="text-3xl font-bold">Card Detail</div>
        </div>
        <div className="bg-white lg:px-12 md:px-6 px-4 lg:py-12 md:py-6 py-4 rounded-lg">
          {!isLoading ? (
            <CardTab
              template={templateData}
              personal={employeeData}
              company={companyData}
            />
          ) : (
            <div className="my-24 mx-auto max-w-[500px]">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewCard;
