import * as React from 'react';
import {
  cardTemplateH1,
  cardTemplateH2,
  cardTemplateH3,
  cardTemplateH4,
  cardTemplateH5,
  cardTemplateV1,
  cardTemplateV2,
  cardTemplateV3,
  cardTemplateV4,
} from '../../assets';
import { CardPreview } from '../../components';

export default function DefaultCardsGallery({
  verticalTemplates,
  landscapeTemplates,
}) {
  return (
    <div className="py-12 flex flex-col md:gap-12 gap-6">
      {landscapeTemplates &&
        Array.isArray(landscapeTemplates) &&
        landscapeTemplates.length > 0 && (
          <React.Fragment>
            <div className="text-center">
              <div className="text-3xl font-bold">Landscape Templates</div>
            </div>

            <div className="  bg-white rounded-md md:px-10 px-3 md:py-12 py-6">
              <div className="flex gap-4 flex-wrap justify-center items-center">
                {landscapeTemplates.map((template, index) => (
                  <div className="overflow-x-auto" key={index}>
                    <CardPreview
                      setIsLoadPreview={() => {}}
                      isLoadPreview={false}
                      cardBackground={template.card_background}
                      backgroundImage={template.background_image}
                      cardComponent={template.card_component}
                      cardData={template}
                      className="border shadow"
                      orientation={template.orientation}
                    />
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}

      {verticalTemplates &&
        Array.isArray(verticalTemplates) &&
        verticalTemplates.length > 0 && (
          <React.Fragment>
            <div className="text-center">
              <div className="text-3xl font-bold">Portrait Templates</div>
            </div>
            <div className="  bg-white rounded-md md:px-10 px-3 md:py-12 py-6">
              <div className="flex gap-4 flex-wrap justify-center items-center">
                {verticalTemplates.map((template, index) => (
                  <div className="overflow-x-auto" key={index}>
                    <CardPreview
                      setIsLoadPreview={() => {}}
                      isLoadPreview={false}
                      cardBackground={template.card_background}
                      backgroundImage={template.background_image}
                      cardComponent={template.card_component}
                      cardData={template}
                      className="border shadow"
                      orientation={template.orientation}
                    />
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}
    </div>
  );
}
