export const routesName = {
  login: '/auth/sign-in',
  signup: '/auth/sign-up',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/resetPassword/:token',
  verifyAccount: '/verifyAccount/:email/:verificationCode',
  404: '/404',
  allRoutes: '/*',
  home: '/',
  dashboard: '/dashboard',

  appSetting: '/system/settings',
  paymentSetting: '/system/payments',
  faqsSetting: '/system/frequently-ask-questions',
  addFAQs: '/system/frequently-ask-questions/add',
  editFAQs: '/system/frequently-ask-questions/edit/:id',
  editFAQsPath: '/system/frequently-ask-questions/edit/',

  createCompany: '/create-company',
  editCompany: '/company/edit/:id',
  editCompanyPath: '/company/edit/',
  companyDetail: '/company/detail/:id',
  companyDetailPath: '/company/detail/',

  createEmployee: '/create-employee',
  editEmployee: '/employee/edit/:id',
  editEmployeePath: '/employee/edit/',
  employeeDetail: '/employee/detail/:id',
  employeeDetailPath: '/employee/detail/',

  usersListing: '/users',
  usersDetail: '/users/detail/:id',
  usersDetailPath: '/users/detail/',

  companyListing: '/companies',
  employessListing: '/employess',
  employessListingPage: '/employess/:id',

  cardsListing: '/cards',
  createCard: '/create-card',
  cardDetail: '/cards/detail/:id',
  cardDetailPath: '/cards/detail/',
  editCard: '/cards/edit/:id',
  editCardPath: '/cards/edit/',

  templates: '/cards/templates',
  createTemplate: '/cards/create-template',
  editTemplate: '/cards/edit-template/:id',
  editTemplatePath: '/cards/edit-template/',

  package: '/package',
  createPackage: '/create-package',
  packageDetail: '/package/detail/:id',
  packageDetailPath: '/package/detail/',
  editPackage: '/package/edit/:id',
  editPackagePath: '/package/edit/',

  billing: '/billing',
  pricing: '/pricing',

  profile: '/profile',
  editProfile: '/profile/edit',

  viewCompany: '/view/company/detail/:id',
  viewCompanyPath: '/view/company/detail/',

  viewCard: '/view/card/detail/:id',
  viewCardPath: '/view/card/detail/',

  faqs: '/frequently-ask-questions',
};
