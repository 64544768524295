import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Modal,
  Tooltip,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Archive, Delete, Details, Edit, Unarchive } from '@mui/icons-material';
import { toAbsoluteURL, useQuery } from '../../constants/shared-methods';
import CompanyService from '../../services/company.service';
import useScreen from '../../hooks/useScreen';
import { initialPagination } from '../../constants/constant-values';
import QRCode from 'react-qr-code';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import useNotification from '../../hooks/useNotification';
import { routesName } from '../../constants/routes-name';
import { PaginationWrapper } from '../../components';
import { useSelector } from 'react-redux';

function CompanyTableView({ loading = false }) {
  const { successNonification, errorNonification } = useNotification();
  const { isCompany, isUser } = useSelector(({ user }) => user);

  const navigate = useNavigate();
  const query = useQuery();
  const { isPageLoaded } = useScreen();
  const page = query.get('page');
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(initialPagination);
  const [qrData, setQrData] = useState('');
  const [showQR, setShowQR] = useState(false);

  const toggleShowQR = () => {
    setShowQR(!showQR);
  };

  const getAllCompanies = () => {
    CompanyService.getAllCompanies(page || 1)
      .then((res) => {
        setIsLoading(false);
        if (res?.data) {
          const data = res.data;
          setCompanies(data.data);
          pagination.from = data.from;
          pagination.to = data.to;
          pagination.total = data.total;
          pagination.perPage = data.per_page;
          pagination.currentPage = data.current_page;
          pagination.lastPage = data.last_page;
          setPagination({ ...pagination });
        }
      })
      .catch((error) => {
        setCompanies([]);
        setPagination(initialPagination);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getAllCompanies();
    }
  }, [isPageLoaded, page]);

  const handleChangeArchive = (id) => {
    CompanyService.changeCompanyArchiveStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Archive status has been changed.');
          getAllCompanies();
        }
      })
      .catch(() => errorNonification('Something went wrong'));
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        CompanyService.deleteCompany(id)
          .then((res) => {
            if (res.success) {
              getAllCompanies();
              successNonification('Company has been deleted.');
            }
          })
          .catch(() => errorNonification('Something went wrong'));
      }
    });
  };

  const handleStatus = (id) => {
    CompanyService.changeStatus(id)
      .then((res) => {
        if (res.success) {
          successNonification('Status changed.');
          getAllCompanies();
        }
      })
      .catch((error) => errorNonification('Something went wrong'));
  };

  const columns = [
    {
      field: 'photo',
      headerName: 'LIGHT LOGO',
      flex: 0.5,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center text-[var(--gray)]">
          <Avatar alt="light_logo" src={toAbsoluteURL(params?.row?.photo)} />
        </div>
      ),
    },
    {
      field: 'dark_logo',
      sortable: false,
      headerName: 'DARK LOGO',
      minWidth: 110,
      flex: 0.5,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center text-[var(--gray)]">
          <Avatar alt="dark_logo" src={toAbsoluteURL(params?.row?.dark_logo)} />
        </div>
      ),
    },
    {
      field: 'name',
      sortable: false,
      headerName: 'NAME',
      minWidth: 200,
      flex: 0.8,
    },
    {
      field: 'email',
      sortable: false,
      headerName: 'EMAIL',
      minWidth: 210,
      flex: 1,
    },
    /* {
      field: 'website_url',
      sortable: false,
      headerName: 'WEBSITE',
      minWidth: 100,
      flex: 0.4,
    }, */
    {
      field: 'status',
      headerName: 'STATUS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full w-full gap-2 items-center justify-center text-[var(--gray)]">
          {params.row.status ? (
            <span
              onClick={() => handleStatus(params.row.companyID)}
              className="cursor-pointer bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Active
            </span>
          ) : (
            <span
              onClick={() => handleStatus(params.row.companyID)}
              className="cursor-pointer bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
            >
              Inactive
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'qr_code',
      headerName: 'QR Code',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: (params) => (
        <div className="flex h-full gap-2 items-center text-[var(--gray)]">
          <QRCode
            className="cursor-pointer"
            size={40}
            value={
              window.location.origin +
              routesName.viewCompanyPath +
              params?.row?.guid
            }
            viewBox={`0 0 256 256`}
            onClick={() => {
              setQrData(
                window.location.origin +
                routesName.viewCompanyPath +
                params?.row?.guid
              );
              toggleShowQR();
            }}
          />
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'ACTIONS',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      minWidth: 180,
      flex: 0.8,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex h-full  items-center justify-center text-[var(--gray)]">
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Detail">
              <IconButton
                size="small"
                onClick={() => {
                  navigate(
                    `${routesName.companyDetailPath}${params?.row?.companyID}`
                  );
                }}
              >
                <Details />
              </IconButton>
            </Tooltip>
            {(isCompany || isUser) && (
              <Tooltip slotProps={tooltipSlotStyle} arrow title="Edit">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(
                      `${routesName.editCompanyPath}${params?.row?.companyID}`
                    );
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              slotProps={tooltipSlotStyle}
              arrow
              title={params?.row?.archived ? 'Unarchive' : 'Archive'}
            >
              <IconButton
                size="small"
                onClick={() => {
                  handleChangeArchive(params?.row?.companyID, params.id);
                }}
              >
                {params?.row?.archived ? <Unarchive /> : <Archive />}
              </IconButton>
            </Tooltip>
            <Tooltip slotProps={tooltipSlotStyle} arrow title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(params?.row?.companyID, params.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const rows =
    companies &&
    Array.isArray(companies) &&
    companies.length > 0 &&
    companies.map((item, index) => ({
      archived: item?.archived,
      city_id: item?.city_id,
      companyID: item?.companyID,
      country_id: item?.country_id,
      created_at: item?.created_at,
      dark_logo: item?.dark_logo,
      deleted_at: item?.deleted_at,
      email: item?.email,
      email_verified_at: item?.email_verified_at,
      guid: item?.guid,
      id: item?.id,
      is_email_verified: item?.is_email_verified,
      name: item?.name,
      phone: item?.phone,
      photo: item?.photo,
      registered_by: item?.registered_by,
      role_id: item?.role_id,
      slogan: item?.slogan,
      slug: item?.slug,
      state_id: item?.state_id,
      status: item?.status,
      street_address: item?.street_address,
      updated_at: item?.updated_at,
      user_id: item?.user_id,
      website_url: item?.website_url,
      zip_code: item?.zip_code,
    }));

  return (
    <div>
      {isLoading && (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      )}
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
      <PaginationWrapper
        page={pagination?.currentPage || 1}
        component="div"
        onChange={(event, newPage) => {
          navigate(`${routesName.companyListing}?page=${newPage}`);
        }}
        count={pagination?.lastPage || 1}
        className="flex-shrink-0 border-t-1 flex justify-center mt-2"
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal open={showQR} onClose={toggleShowQR}>
        <Box sx={style}>
          {qrData ? (
            <div className='flex flex-col gap-3 justify-center items-center'>
              <QRCode
                value={qrData}
                viewBox={`0 0 256 256`}
                style={{ width: '100%' }}
              />
              <Link
                to={qrData}
                target='_blank'
                state={null}
                className="text-xs bg-second text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                View Company
              </Link>
            </div>
          ) : (
            <h4>No QR code found</h4>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default memo(CompanyTableView);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  bgcolor: 'var(--blue)',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const tooltipSlotStyle = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};
