import React, { useEffect, useState } from 'react';
import { CurrentPackageTab, OverviewComponent } from '../../components';
import { Tab, Tabs } from '@mui/material';
import UpgradePlan from './UpgradePlan';
import useScreen from '../../hooks/useScreen';
import { useSelector } from 'react-redux';
import CompanyService from '../../services/company.service';
import PackageService from '../../services/package.service';
import { Navigate } from 'react-router-dom';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (isUser) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const currentPackageTab = 'current-package';
  const upgradePlanTab = 'upgrade-plan';
  const renewPlanTab = 'renew-plan';
  const { user, company } = useSelector(({ user }) => user);
  const { isPageLoaded } = useScreen();
  const [selectedTab, setSelectedTab] = useState(currentPackageTab);
  const [currentPackage, setCurrentPackage] = useState({});
  const [packages, setPackages] = useState([]);

  const getCompanyDetail = () => {
    CompanyService.getCompanyDetail(company.companyID || company.id).then(
      (res) => {
        if (res.success) {
          setCurrentPackage(res.data.currentPackage);
        }
      }
    );
  };

  const getPackagesList = () => {
    PackageService.getPackages().then((res) => {
      if (res.success) {
        setPackages(res.data);
      }
    });
  };

  useEffect(() => {
    if (isPageLoaded && company) {
      getCompanyDetail();
      getPackagesList();
    }
  }, [isPageLoaded]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <div className="flex flex-col gap-8">
      <OverviewComponent />
      <div className="flex flex-col gap-8">
        <div>
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">Pricing</div>
          </div>
        </div>
      </div>

      <div className="bg-white md:px-12 px-4 md:py-8 py-4">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab value={currentPackageTab} label="Current Plan" />
          <Tab value={upgradePlanTab} label="Upgrade Plan" />
          <Tab value={renewPlanTab} label="Renew Plan" />
        </Tabs>
        <div className="md:mt-12 mt-6">
          {selectedTab === currentPackageTab && (
            <div>
              <CurrentPackageTab currentPackage={currentPackage} />
            </div>
          )}
          {selectedTab === upgradePlanTab && (
            <div>
              <UpgradePlan
                packagesList={packages}
                formType={'upgrade'}
                currentPackage={currentPackage}
              />
            </div>
          )}
          {selectedTab === renewPlanTab && (
            <div>
              <UpgradePlan
                packagesList={packages}
                formType={'renew'}
                currentPackage={currentPackage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Component;
