import React, { useEffect, useState } from 'react';
import CompanyService from '../../services/company.service';
import useScreen from '../../hooks/useScreen';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { LinearProgress, Tab, Tabs } from '@mui/material';
import {
  CompanyDetailsTab,
  CurrentPackageTab,
  EmployeeDetailTab,
  TransectionsTab,
  UserDetalTab,
} from '../../components';
import { routesName } from '../../constants/routes-name';
import UserService from '../../services/user.service';
import { useSelector } from 'react-redux';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { isPageLoaded } = useScreen();

  const params = useParams();
  const navigate = useNavigate();

  const userProfileTab = 'user-profile-tab';
  const companyDetailsTab = 'company-details-tab';
  const currentPackageTab = 'current-package-tab';
  const transactionsTab = 'transactions-tab';
  const [selectedTab, setSelectedTab] = useState(userProfileTab);

  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [transections, setTransections] = useState(null);
  const [userDetail, setUserDetail] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getUserProfile = () => {
    UserService.getUserInfo(params.id)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          setUserDetail(res.data);
        }
      })
      .catch(() => {
        navigate(routesName[404]);
      });
  };

  const userHasCompany = () => {
    CompanyService.hasCompanyRegistered(params.id)
      .then((res) => {
        if (res.success) {
          if (res.data !== null) {
            getCompanyDetail(res.data.id);
          } else {
          }
        }
      })
      .catch(() => {});
  };

  const getCompanyDetail = (company_id) => {
    CompanyService.getCompanyDetail(company_id)
      .then((res) => {
        if (res.success) {
          setCompany(res.data.company);
          setEmployees(res.data.employees);
          setCurrentPackage(res.data.currentPackage);
          setTransections(res.data.transactions);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (isPageLoaded) {
      getUserProfile();
      userHasCompany();
    }
  }, [isPageLoaded]);

  return (
    <div>
      <div className="text-2xl">User Detail</div>

      <div className="mt-12 mx-auto">
        {!isLoading ? (
          <div className="overflow-x-auto mx-auto max-w-[1000px] bg-white rounded-lg md:px-12 px-4 md:py-8 py-4">
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab value={userProfileTab} label="User Profile" />
              <Tab value={companyDetailsTab} label="Company Detail" />
              <Tab value={currentPackageTab} label="Current Package" />
              <Tab value={transactionsTab} label="Transections" />
            </Tabs>
            <div className="md:mt-12 mt-6">
              {selectedTab === userProfileTab && (
                <UserDetalTab userData={userDetail} />
              )}
              {company && selectedTab === companyDetailsTab && (
                <CompanyDetailsTab
                  companyData={company}
                  userDetail={userDetail}
                />
              )}
              {company && selectedTab === currentPackageTab && (
                <CurrentPackageTab currentPackage={currentPackage} />
              )}
              {company && selectedTab === transactionsTab && (
                <TransectionsTab transectionsData={transections} />
              )}

              {!company && selectedTab !== userProfileTab && (
                <div className="text-xl font-bold text-center">
                  You did't have a company yet
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
