import React, { useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import './css/drag-drop-image.css';
import { ImageCropper } from '.';

function Component({
  onChangeImage = () => {},
  title = 'Upload',
  preview = '',
  error = '',
  onBlur = () => {},
  name = '',
  cropable = false,
}) {
  const inputRef = useRef();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [file, setFile] = useState(null);

  const fileHandler = (files) => {
    if (!cropable) {
      onChangeImage(files[0]);
    } else {
      setFile(files[0]);
      setIsOpenModal(true);
    }
  };

  const handleCroppedFile = (files) => {
    onChangeImage(files);
  };

  const filePicker = () => {
    inputRef.current.click();
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="text-md font-bold">{title}</div>
      <div className="text-xs text-main">*use .svg or .png files</div>
      <div className="bg-white px-12 py-8 flex items-center justify-center">
        {!preview && (
          <FileDrop
            onTargetClick={filePicker}
            onDrop={(f) => fileHandler(f)}
            className={`border border-2 border-dashed rounded-xl border-[var(--blue)] h-64 w-full`}
          >
            <div className="h-full flex items-center justify-center w-auto h-64 text-main">
              <p className="placeholder text-center">
                Add logo <br /> or <br /> <span>drag n drop here</span>
              </p>
            </div>
          </FileDrop>
        )}

        {preview && (
          <div className="flex flex-col items-center justify-center">
            <img
              alt="uploaded-image-here"
              src={preview}
              className="max-h-[200px]"
            />

            <label
              htmlFor={name}
              className="bg-[var(--yellow)] text-sm font-bold px-4 py-2 rounded-md mt-6"
            >
              Change
            </label>
          </div>
        )}
      </div>
      {error && (
        <div className="text-sm font-bold text-red-600 bg-red-200 py-1 px-2">
          {error}
        </div>
      )}

      <input
        className="hidden"
        accept=".png, .svg"
        value=""
        style={{ visibility: 'hidden', opacity: 0 }}
        ref={inputRef}
        type="file"
        onChange={(e) => fileHandler(e.target.files)}
        name={name}
        id={name}
        onBlur={onBlur}
      />

      {cropable && (
        <ImageCropper
          src={preview}
          file={file}
          isOpen={isOpenModal}
          toggle={() => setIsOpenModal(!isOpenModal)}
          setFile={handleCroppedFile}
        />
      )}
    </div>
  );
}

export default Component;
