import React, { Fragment, useEffect, useState } from 'react';
import { StepperWrapper, TextFieldWrapper } from '../../../components';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import { GppBadOutlined, TaskAltOutlined } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/navigation';
import { Formik } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../../constants/validationSchema';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useScreen from '../../../hooks/useScreen';
import { routesName } from '../../../constants/routes-name';
import FaqService from '../../../services/faq.service';

function Component() {
  const { user } = useSelector(({ user }) => user);

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const FaqTypeList = [
    { label: 'Company', value: 'company' },
    { label: 'Employee', value: 'employee' },
    { label: 'Card Template', value: 'card template' },
    { label: 'Payment Gateway', value: 'payment gateway' },
    { label: 'Authentication', value: 'authentication' },
    { label: 'Other', value: 'other' },
  ];
  const steps = ['FAQ info', 'Summary'];
  const [activeTab, setActiveTab] = useState(0);
  const { isPageLoaded } = useScreen();
  const navigate = useNavigate();

  const params = useParams();

  const [responseData, setResponseData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFAQ, setIsLoadingFAQ] = useState(!!params.id);
  const [faqType, setFaqType] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const submitFAQ = () => {
    setIsLoading(true);
    const data = {
      faq_type: faqType,
      question: question,
      answer: answer,
    };

    if (params.id) {
      FaqService.updateFaq(params.id, data)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setResponseData({
              status: true,
              message: 'FAQ has been updated successfully',
            });
            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    } else {
      FaqService.saveFaq(data)
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            setResponseData({
              status: true,
              message: 'FAQ has been added successfully',
            });
            setTimeout(() => navigate(-1), 1000);
          } else {
            setResponseData({ status: false, message: 'Something went wrong' });
          }
        })
        .catch((error) => {
          setResponseData({
            status: false,
            message: error?.response?.data?.message || 'Something went wrong',
          });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (activeTab === 1) {
      submitFAQ();
    }
  }, [activeTab]);

  const getFaq = () => {
    FaqService.getFaq(params.id)
      .then((res) => {
        if (res.success) {
          const data = res.data;
          setFaqType(data?.faq_type);
          setQuestion(data?.question);
          setAnswer(data?.answer);
        }
        setIsLoadingFAQ(false);
      })
      .catch((error) => {
        navigate(routesName['404'], { replace: true });
      });
  };

  useEffect(() => {
    if (isPageLoaded && params.id) {
      getFaq();
    }
  }, [isPageLoaded]);

  return (
    <div>
      <div className="text-2xl">
        {params.id ? 'FAQ Altering' : 'Adding New FAQ'}
      </div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px]">
          <StepperWrapper activeStep={activeTab} steps={steps} />
        </div>

        {!isLoadingFAQ ? (
          <div>
            {activeTab === 0 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <Formik
                  initialValues={{
                    faqType: faqType,
                    question: question,
                    answer: answer,
                  }}
                  validationSchema={yup.object().shape({
                    faqType: validationSchema.required('FAQ type'),
                    question: validationSchema.required('Question'),
                    answer: validationSchema.required('Answer'),
                  })}
                  onSubmit={(values, actions) => {
                    try {
                      setFaqType(values.faqType);
                      setQuestion(values.question);
                      setAnswer(values.answer);

                      setActiveTab(1);
                    } catch (error) {}
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => {
                    return (
                      <div>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                FAQ Type <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                select
                                placeholder="Placeholder"
                                name="faqType"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.faqType}
                                helperText={touched.faqType && errors.faqType}
                                error={!!touched.faqType && !!errors.faqType}
                              >
                                {FaqTypeList.map((item, index) => (
                                  <MenuItem key={index} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </TextFieldWrapper>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Question <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                placeholder="Placeholder"
                                name="question"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.question}
                                helperText={touched.question && errors.question}
                                error={!!touched.question && !!errors.question}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="flex flex-col gap-2">
                              <label className="text-xs">
                                Answer <span className="text-red-500">*</span>
                              </label>
                              <TextFieldWrapper
                                multiline
                                minRows={4}
                                placeholder="Placeholder"
                                name="answer"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.answer}
                                helperText={touched.answer && errors.answer}
                                error={!!touched.answer && !!errors.answer}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="flex items-center justify-end mt-8">
                          <button
                            onClick={handleSubmit}
                            className="text-xs bg-main text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}

            {activeTab === 1 && (
              <div className="mt-12 mx-auto max-w-[800px]">
                <div className="min-h-96 flex items-center justify-center flex-col gap-5">
                  {isLoading ? (
                    <CircularProgress />
                  ) : responseData?.status ? (
                    <Fragment>
                      <TaskAltOutlined
                        className="text-[var(--yellow)]"
                        sx={{ fontSize: '5rem' }}
                      />
                      <div className="text-[var(--yellow)] font-bold text-2xl text-center">
                        {responseData?.message}
                      </div>
                    </Fragment>
                  ) : (
                    !responseData?.status &&
                    responseData?.message && (
                      <Fragment>
                        <GppBadOutlined
                          className="text-[var(--pink)]"
                          sx={{ fontSize: '5rem' }}
                        />
                        <div className="text-[var(--pink)] font-bold text-2xl text-center">
                          {responseData?.message}
                        </div>
                      </Fragment>
                    )
                  )}
                </div>

                {!isLoading && !responseData?.status && (
                  <div className="flex items-center justify-between mt-8">
                    <button
                      onClick={() => {
                        setResponseData(null);
                        setActiveTab(0);
                      }}
                      className="text-xs border border-1 border-[var(--blue)] text-center px-4 py-1 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
                    >
                      Back
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="mt-36 mx-auto max-w-[500px]">
            <LinearProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default Component;
