import React, { useEffect, useState } from 'react';
import CompanyService from '../../services/company.service';
import useScreen from '../../hooks/useScreen';
import { Navigate, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  CompanyDetailsTab,
  CurrentPackageTab,
  TransectionsTab,
} from '../../components';
import { useSelector } from 'react-redux';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (user?.role_id === 1 || isUser) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { isPageLoaded } = useScreen();

  const params = useParams();

  const companyDetailsTab = 'company-details-tab';
  const currentPackageTab = 'current-package-tab';
  const transactionsTab = 'transactions-tab';
  const [selectedTab, setSelectedTab] = useState(companyDetailsTab);

  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(false);
  const [employees, setEmployees] = useState(false);
  const [transections, setTransections] = useState(false);
  const [userDetail, setUserDetail] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getCompany = () => {
    setIsLoading(true);
    CompanyService.getCompanyDetail(params.id)
      .then((res) => {
        if (res.success && res.data) {
          setCompany(res?.data?.company);
          setCurrentPackage(res?.data?.currentPackage);
          setEmployees(res?.data?.employees);
          setTransections(res?.data?.transactions);
          setUserDetail(res?.data?.user);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isPageLoaded) {
      getCompany();
    }
  }, [isPageLoaded]);

  return (
    <div>
      <div className="text-2xl">Account Detail</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[1000px] bg-white rounded-lg md:px-12 px-4 md:py-8 py-4">
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab value={companyDetailsTab} label="Company Detail" />
            <Tab value={currentPackageTab} label="Current Package" />
            <Tab value={transactionsTab} label="Transections" />
          </Tabs>
          <div className="md:mt-12 mt-6">
            {selectedTab === companyDetailsTab && (
              <CompanyDetailsTab
                companyData={company}
                userDetail={userDetail}
              />
            )}
            {selectedTab === currentPackageTab && (
              <CurrentPackageTab currentPackage={currentPackage} />
            )}
            {selectedTab === transactionsTab && (
              <TransectionsTab transectionsData={transections} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Component;
