import { useDrag } from 'react-dnd';
import {
  ItemTypes,
  getMaterialIconForCard,
} from '../../../constants/card-data';

const styles = {
  position: 'absolute',
  border: '1px dashed gray',
  backgroundColor: '#71717129',
  cursor: 'move',
};
export const MovableComponent = ({
  id,
  left,
  top,
  style,
  children,
  name,
  icon = '',
  iconStyle = {},
  showBackground = false,
}) => {
  console.log('Moveable style =>', style);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );
  if (isDragging) {
    return <div ref={drag} />;
  }
  if (name === 'items_icon') {
    return (
      <div
        className={name === 'profile_picture' ? 'text-center box' : 'box'}
        ref={drag}
        style={{
          ...styles,
          left,
          top,
          ...style,
          height: `${style.height}px`,
          width: `${style.width}px`,
          borderBottomLeftRadius: `${style.borderBottomLeftRadius}px`,
          borderBottomRightRadius: `${style.borderBottomRightRadius}px`,
          borderTopLeftRadius: `${style.borderTopLeftRadius}px`,
          borderTopRightRadius: `${style.borderTopRightRadius}px`,
          backgroundColor: showBackground ? `${style.backgroundColor}` : 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        data-testid="box"
      >
        {getMaterialIconForCard(icon, iconStyle)}
      </div>
    );
  }

  return (
    <div
      className={name === 'profile_picture' ? 'text-center box' : 'box'}
      ref={drag}
      style={{
        ...styles,
        left,
        top,
        ...style,
        height:
          name === 'profile_picture' || name === 'qr_code'
            ? `${style.height}px`
            : name === 'items_icon'
            ? `${style.height}px`
            : 'auto', //`${style.height}px`,
        width:
          name === 'profile_picture' || name === 'qr_code'
            ? `${style.width}px`
            : name === 'items_icon'
            ? `${style.width}px`
            : `${style.width}%`,
        fontSize: `${style.fontSize}px`,
        rotate: `${style.rotate}deg`,
      }}
      data-testid="box"
    >
      {name === 'items_icon' ? '⚫' : children}
    </div>
  );
};
