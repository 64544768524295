import React, { Fragment, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { QrCode } from '@mui/icons-material';
import { Box, LinearProgress, Modal } from '@mui/material';
import FileService from '../services/file.service';
import { defaultAvatar } from '../assets';
import {
  getMaterialIconForCard,
  landscapeCardSize,
  verticalCardSize,
} from '../constants/card-data';
import { routesName } from '../constants/routes-name';
import useScreen from '../hooks/useScreen';

const styles = {
  position: 'relative',
  borderRadius: '10px',
  overflow: 'hidden',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
};

const Component = ({
  isLoadPreview,
  setIsLoadPreview,
  localBackground,
  cardBackground,
  backgroundImage,
  cardComponent,
  userData,
  cardData,
  className,
  canDownload = false,
  orientation = 'l',
  id = 'employee-card',
}) => {
  const { isPageLoaded } = useScreen();
  const [backgroundData, setBackgroundData] = useState({});
  const [frontData, setFrontData] = useState([]);
  const [profile, setProfile] = useState({});
  const [profilePic, setProfilePic] = useState(defaultAvatar);
  const [card, setCard] = useState({});
  const [cardStyle, setCardStyle] = useState({ background: '#fff' });
  const [bgPreview, setBgPreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [qrData, setQrData] = useState('');
  const cardDimentions =
    orientation === 'l' ? landscapeCardSize : verticalCardSize;

  useEffect(() => {
    if (isPageLoaded) {
      cardComponent && setFrontData(JSON.parse(cardComponent));
      cardData && setCard(cardData);
      cardBackground && setBackgroundData(JSON.parse(cardBackground));
      userData && setProfile(userData);
    }
  }, [
    cardComponent,
    cardData,
    backgroundImage,
    cardBackground,
    userData,
    isPageLoaded,
  ]);

  const loadBackground = async (src) => {
    try {
      isLoadPreview !== undefined && setIsLoadPreview(true);
      setIsLoading(true);
      const res = await FileService.getImageFile(src);
      if (res?.status === true) {
        setBgPreview(`data:image/png;base64,${res?.image}`);
        setIsLoading(false);
        isLoadPreview !== undefined && setIsLoadPreview(false);
      } else {
        setIsLoading(false);
        isLoadPreview !== undefined && setIsLoadPreview(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (backgroundImage && isPageLoaded) {
      localBackground
        ? setBgPreview(backgroundImage)
        : !canDownload
        ? setBgPreview(`${process.env.REACT_APP_ASSET_URL}/${backgroundImage}`)
        : loadBackground(backgroundImage);
    }
  }, [backgroundImage, isPageLoaded]);

  const handleCardStyle = () => {
    if (Object.keys(backgroundData).length > 0) {
      setCardStyle({
        background: bgPreview
          ? `url(${bgPreview})`
          : backgroundData.style.backgroundColor,
      });
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      handleCardStyle();
    }
  }, [bgPreview, backgroundData, isPageLoaded]);

  const loadImg = async (src) => {
    try {
      const res = await FileService.getImageFile(src);
      if (res?.status === true) {
        setProfilePic(`data:image/png;base64,${res?.image}`);
      } else {
        setProfilePic(defaultAvatar);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isPageLoaded) {
      if (userData?.photo) {
        if (canDownload) {
          userData &&
            Object.keys(userData).length > 0 &&
            loadImg(userData?.photo);
        } else {
          setProfilePic(
            `${process.env.REACT_APP_ASSET_URL}/${userData?.photo}`
          );
        }
      } else {
        setProfilePic(defaultAvatar);
      }
    }
  }, [userData, isPageLoaded]);

  return (
    <div className="overflow-x-auto">
      {isLoading ? (
        <div className="mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <div
            style={{
              position: 'relative',
              borderRadius: '10px',
              overflow: 'hidden',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: cardDimentions?.height,
              width: cardDimentions?.width,
              background: cardStyle.background,
            }}
            className={className}
            id={id}
          >
            {frontData.length > 0 &&
              frontData.map(
                (
                  {
                    id,
                    top,
                    left,
                    style,
                    title,
                    name,
                    icon = '',
                    iconStyle = {},
                    showBackground = false,
                  },
                  index
                ) => {
                  return (
                    <Fragment key={index}>
                      {name === 'profile_picture' ? (
                        <img
                          alt="D.P"
                          src={profilePic}
                          style={{
                            position: 'absolute',
                            left,
                            top,
                            ...style,
                            height:
                              name === 'profile_picture'
                                ? `${style.height}px`
                                : 'auto',
                            width:
                              name === 'profile_picture'
                                ? `${style.width}px`
                                : `${style.width}%`,
                            fontSize: `${style.fontSize}px`,
                          }}
                          key={id}
                          id={id}
                        />
                      ) : name === 'qr_code' ? (
                        card && Object.keys(card).length > 0 && card.guid ? (
                          <QRCode
                            size={50}
                            style={{
                              position: 'absolute',
                              left,
                              top,
                              ...style,
                              height:
                                name === 'qr_code'
                                  ? `${style.height}px`
                                  : 'auto',
                              width:
                                name === 'qr_code'
                                  ? `${style.width}px`
                                  : `${style.width}%`,
                              fontSize: `${style.fontSize}px`,
                            }}
                            key={id}
                            id={id}
                            value={
                              window.location.origin +
                              routesName.viewCardPath +
                              card.guid
                            }
                            onClick={() => {
                              setQrData(
                                window.location.origin +
                                  routesName.viewCardPath +
                                  card.guid
                              );
                              setShowQR(true);
                            }}
                            viewBox={`0 0 256 256`}
                            className="hoverZoomIn"
                          />
                        ) : (
                          <QrCode
                            style={{
                              position: 'absolute',
                              left,
                              top,
                              ...style,
                              height:
                                name === 'qr_code'
                                  ? `${style.height}px`
                                  : 'auto',
                              width:
                                name === 'qr_code'
                                  ? `${style.width}px`
                                  : `${style.width}%`,
                              fontSize: `${style.fontSize}px`,
                            }}
                            key={id}
                            id={id}
                          />
                        )
                      ) : name === 'items_icon' ? (
                        <div
                          className="px-2"
                          style={{
                            position: 'absolute',
                            left,
                            top,
                            ...style,
                            height: `${style.height}px`,
                            width: `${style.width}px`,
                            borderBottomLeftRadius: `${style.borderBottomLeftRadius}px`,
                            borderBottomRightRadius: `${style.borderBottomRightRadius}px`,
                            borderTopLeftRadius: `${style.borderTopLeftRadius}px`,
                            borderTopRightRadius: `${style.borderTopRightRadius}px`,
                            padding: `${style.padding}px`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: showBackground
                              ? `${style.backgroundColor}`
                              : 'none',
                          }}
                          key={id}
                          id={id}
                        >
                          {getMaterialIconForCard(icon, iconStyle)}
                        </div>
                      ) : (
                        <div
                          className="px-2"
                          style={{
                            position: 'absolute',
                            left,
                            top,
                            ...style,
                            height:
                              name === 'profile_picture'
                                ? `${style.height}px`
                                : 'auto',
                            width:
                              name === 'profile_picture'
                                ? `${style.width}px`
                                : `${style.width}%`,
                            fontSize: `${style.fontSize}px`,
                            rotate: `${style.rotate}deg`,
                          }}
                          key={id}
                          id={id}
                        >
                          {(name === 'user_name' && profile?.name) ||
                            (name === 'user_desigination' &&
                              profile?.designation) ||
                            (name === 'position' && profile?.slug) ||
                            (name === 'phone_number' && profile?.phone) ||
                            (name === 'email' && profile?.email) ||
                            (name === 'reference' && profile?.zip_code) ||
                            (name === 'address' && profile?.street_address) ||
                            title}
                        </div>
                      )}
                    </Fragment>
                  );
                }
              )}
          </div>
        </div>
      )}

      <Modal open={showQR} onClose={() => setShowQR(!showQR)}>
        <Box sx={style}>
          {qrData ? (
            <div>
              <QRCode
                value={qrData}
                viewBox={`0 0 256 256`}
                style={{ width: '100%' }}
              />
            </div>
          ) : (
            <h4>No QR code found</h4>
          )}
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  bgcolor: 'var(--blue)',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default Component;
