import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { routesName } from '../../../constants/routes-name';
import { OverviewComponent } from '../../../components';
import { Add } from '@mui/icons-material';
import FAQsTableView from './FAQsTableView';

function Component() {
  const { user } = useSelector(({ user }) => user);

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { isCompany, isUser } = useSelector(({ user }) => user);
  return (
    <div className="flex flex-col gap-8">
      <OverviewComponent />
      <div className="flex flex-col gap-8">
        <div>
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">FAQs details</div>

            <Link
              to={routesName.addFAQs}
              className="text-xs bg-main text-center px-3 py-1 rounded-full font-bold flex items-center justify-center gap-1"
            >
              <Add className="text-white" fontSize="small" />
              <span>Add FAQ</span>
            </Link>
          </div>
          <FAQsTableView />
        </div>
      </div>
    </div>
  );
}

export default Component;
