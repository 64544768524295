import axios from 'axios';

const token = localStorage.getItem('token');

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: token
    ? {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      }
    : {
        Accept: 'application/json',
      },
});
export default axiosInstance;
