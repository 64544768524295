import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { LinearProgress } from '@mui/material';
import Swal from 'sweetalert2';
import CompanyService from '../../services/company.service';
import CardEditor from './components/CardEditor';
import useScreen from '../../hooks/useScreen';
import { useSelector } from 'react-redux';
import { routesName } from '../../constants/routes-name';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (!isEmployee) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

const Page = () => {
  const { width } = useScreen();
  const navigate = useNavigate();
  const { user } = useSelector(({ user }) => user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user.role_id !== 1 && user.role_id !== 2 && user.role_id !== 3) {
      return navigate('/dashboard');
    }
  }, [user]);

  const hasCompany = () => {
    if (user.role_id === 2) {
      setIsLoading(true);
      CompanyService.hasCompanyRegistered(user.id)
        .then((res) => {
          setIsLoading(false);
          if (!res.data) {
            if (localStorage.getItem('company') === 'null') {
              Swal.fire({
                title: 'Oops!',
                html: 'No company registered yet.',
                icon: 'error',
                allowOutsideClick: false,
              }).then((result) => {
                return navigate('/company/add');
              });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (user) {
      hasCompany();
    }
  }, []);

  return (
    <div
      className={`${
        width < 1600 ? 'container-xxl' : 'container-fluid'
      } flex-grow-1 container-p-y`}
    >
      {isLoading ? (
        <div className="flex-1 mt-36 mx-auto max-w-[500px]">
          <LinearProgress />
        </div>
      ) : (
        <div className="row">
          <div className="card sm-px-0">
            <div className="card-body sm-px-0">
              <div className="d-flex justify-content-between align-items-center border-bottom pb-3 sm-flex-col ">
                <div className="text-2xl font-bold">
                  Create New Card Template
                </div>
              </div>

              <DndProvider backend={HTML5Backend}>
                <CardEditor />
              </DndProvider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Component;
