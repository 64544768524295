import { Avatar, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { toAbsoluteURL } from '../../constants/shared-methods';
import {
  CalendarTodayOutlined,
  CheckCircleOutline,
  Group,
  LocationOnOutlined,
  MailOutline,
  PhoneOutlined,
  SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import moment from 'moment';

function Component() {
  const { user } = useSelector(({ user }) => user);

  console.log(user);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-xl font-bold">Profile</div>
      </div>

      <div className="mt-12">
        <div className="bg-white rounded-lg md:px-12 px-4 md:py-12 py-6 max-w-[800px] mx-auto w-full">
          <div className="flex flex-col items-center justify-center gap-4 mb-6">
            <Avatar
              sx={{ height: '170px', width: '170px' }}
              src={toAbsoluteURL(user?.photo)}
            />

            <div className="md:text-2xl sm:text-xl text-lg font-bold">
              {user?.name}
            </div>
          </div>
          <div className="font-bold text-lg mb-4">About</div>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <CalendarTodayOutlined />{' '}
                  <span className="font-bold text-nowrap">Joined on : </span>
                </div>
                <span> {moment(user?.created_at).format('DD/MM/YYYY')}</span>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <SupervisedUserCircleOutlined />
                  <span className="font-bold text-nowrap">Role : </span>
                </div>
                <span>
                  {user.role_id === 1
                    ? 'Super Admin'
                    : user.role_id === 2
                    ? 'User'
                    : user.role_id === 3
                    ? 'Company'
                    : 'Employee'}
                </span>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <Group />{' '}
                  <span className="font-bold text-nowrap">Name : </span>
                </div>
                <span>{user?.name}</span>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <CheckCircleOutline />{' '}
                  <span className="font-bold text-nowrap">Status : </span>
                </div>
                <span>{user?.status ? 'Active' : 'Inactive'}</span>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <PhoneOutlined /> <span className="font-bold">Phone : </span>
                </div>
                <span>{user?.phone || 'Not set'}</span>
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <MailOutline />{' '}
                  <span className="font-bold text-nowrap">Email : </span>
                </div>
                <span>{user?.email}</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex gap-2 text-sm">
                <div className="flex items-center gap-1">
                  <LocationOnOutlined />
                  <span className="font-bold text-nowrap">Address : </span>
                </div>
                <span>
                  {user?.street_address || ' '}
                  {user?.city_name || ' '}
                  {user?.country_name || ' '}

                  {!user?.street_address &&
                    !user?.city_name &&
                    !user?.country_name &&
                    'Not set'}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Component;
