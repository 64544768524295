import React, { useEffect, useState } from 'react';
import useScreen from '../../hooks/useScreen';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import EmployeeService from '../../services/employee.service';
import PackageService from '../../services/package.service';
import { Grid, LinearProgress } from '@mui/material';
import { PricingComponentSelect } from '../../components';
import { routesName } from '../../constants/routes-name';
import { useSelector } from 'react-redux';

function Component() {
  const { user, isCompany, isEmployee, isUser, company } = useSelector(
    ({ user }) => user
  );

  if (user?.role_id === 1) {
    return <Page />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Page() {
  const { isPageLoaded } = useScreen();
  const navigate = useNavigate();

  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [packageData, setPackageData] = useState();

  const getPackage = () => {
    PackageService.getPackage(params.id)
      .then((res) => {
        if (res.success) {
          const data = res.data;
          setPackageData(data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        navigate(routesName['404']);
      });
  };

  useEffect(() => {
    if (isPageLoaded && params.id) {
      getPackage();
    }
  }, [isPageLoaded]);
  return (
    <div>
      <div className="text-2xl">Package Detail</div>

      <div className="mt-12 mx-auto">
        <div className="overflow-x-auto mx-auto max-w-[800px] rounded-lg md:px-12 px-4 md:py-8 py-4">
          {!isLoading ? (
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <div className="text-lg text-[var(--blue)] font-bold text-center mb-2">
                  Monthaly
                </div>
                <PricingComponentSelect
                  index={0}
                  data={packageData}
                  checked={true}
                  onClick={() => {}}
                  interval={1}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <div className="text-lg text-[var(--blue)] font-bold text-center mb-2">
                  Yearly
                </div>
                <PricingComponentSelect
                  index={0}
                  data={packageData}
                  checked={true}
                  onClick={() => {}}
                  interval={12}
                />
              </Grid>
            </Grid>
          ) : (
            <div className="mt-36 mx-auto max-w-[500px]">
              <LinearProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Component;
