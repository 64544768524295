import { Avatar, Box, Grid } from '@mui/material';
import React from 'react';
import { toAbsoluteURL } from '../constants/shared-methods';

function Component({ userData = {} }) {
  console.log(userData);
  return (
    <div className="">
      <Grid container spacing={4}>
        <Grid item md={3} xs={12}>
          <Box
            sx={{ flexDirection: { md: 'column', xs: 'row' } }}
            className="flex gap-4 flex-wrap items-center justify-center"
          >
            <Avatar
              width={100}
              sx={{ height: '100px', width: '100px' }}
              alt="company light logo"
              src={toAbsoluteURL(userData?.photo)}
            />
          </Box>
        </Grid>
        <Grid item md={9} xs={12} container spacing={3}>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              Name
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {userData?.name}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              EMAIL
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {userData?.email}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              PHONE
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {userData?.phone}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="border-b border-b-4"
          >
            <Grid item sm={4} xs={12} className="font-semibold text-sm">
              ADDRESS
            </Grid>
            <Grid item sm={8} xs={12} className="text-sm">
              {(userData?.street_address ? userData?.street_address : '') +
                (userData?.city_name ? ', ' + userData?.city_name : '') +
                (userData?.country_name ? ', ' + userData?.country_name : '')}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Component;
