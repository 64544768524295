const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user))
}

const setCompany = (company) => {
    localStorage.setItem('company', JSON.stringify(company))
}

const setEmployee = (employee) => {
    localStorage.setItem('employee', JSON.stringify(employee))
}

const setToken = (token) => {
    localStorage.setItem('token', token)
}

const getToken = () => {
    return localStorage.getItem('token')
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'))
}

const getCompany = () => {
    // console.log(localStorage.getItem('company'), typeof localStorage.getItem('company'))
    if (localStorage.getItem('company') !== 'undefined') {
        return JSON.parse(localStorage.getItem('company'))
    } else {
        return null
    }

}

const getEmployee = () => {
    if (localStorage.getItem('employee') !== 'undefined') {
        return JSON.parse(localStorage.getItem('employee'))
    } else {
        return null
    }

}

const storeSystemSetting = (settings) => {
    localStorage.setItem('setting', JSON.stringify(settings))
}

const getSetting = () => {
    return JSON.parse(localStorage.getItem('setting'))
}

const logout = ()=>{
    localStorage.removeItem('user');
    localStorage.removeItem('company');
    localStorage.removeItem('employee');
    localStorage.removeItem('token');
}

const StorageService = {
    logout,
    setEmployee,
    getEmployee,
    setUser,
    setToken,
    getToken,
    getCurrentUser,
    setCompany,
    getCompany,
    storeSystemSetting,
    getSetting
}

export default StorageService
