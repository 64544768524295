import React, { useState, createRef, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Box, IconButton, Modal } from '@mui/material';
import { Close, Check } from '@mui/icons-material';

const Component = ({
  src,
  file,
  isOpen = false,
  toggle = () => {},
  setFile = () => {},
}) => {
  const [image, setImage] = useState(src);
  const cropperRef = createRef();
  const onChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (file && typeof file === 'object') {
      onChange(file);
    }
  }, [file]);

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      var newFile = await getCroppedImageFile();
      var dataImage = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setFile(newFile);
      toggle();
    }
  };

  const getCroppedImageFile = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas) {
        return new Promise((resolve, reject) => {
          croppedCanvas.toBlob((blob) => {
            if (blob) {
              // Create a File object from the Blob
              resolve(
                new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' })
              );
            } else {
              reject(new Error('Failed to convert canvas to blob'));
            }
          }, 'image/jpeg');
        });
      }
    }
    return Promise.reject(
      new Error('Cropper not initialized or canvas not available')
    );
  };

  const disposeChanges = () => {
    setFile(null);
    toggle();
  };

  return (
    <Modal open={isOpen} onClose={() => {}}>
      <Box sx={style}>
        <div>
          <div>
            <Cropper
              ref={cropperRef}
              /*  style={{ height: 400, width: '100%' }} */
              initialAspectRatio={1}
              aspectRatio={1}
              src={image}
              viewMode={1}
              minCropBoxHeight={150}
              minCropBoxWidth={150}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              guides={false}
              zoom={false}
            />
          </div>
          <div>
            <div className="flex items-center justify-center gap-4 mt-2">
              <IconButton onClick={getCropData} color="success">
                <Check fontSize="large" />
              </IconButton>
              <IconButton onClick={disposeChanges} color="secondary">
                <Close fontSize="large" />
              </IconButton>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Component;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 500,
  bgcolor: 'var(--blue)',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};
