import React, { useEffect, useState } from 'react';
import SlideMenu from './components/SlideMenu';
import MainContainer from './components/MainContainer';
import useScreen from '../hooks/useScreen';
import { Backdrop } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { routesName } from '../constants/routes-name';
import DashboardService from '../services/dashboard.service';
import { saveOverallCounts } from '../store/slices/appDataSlice';

function Component() {
  const { user } = useSelector(({ user }) => user);

  if (user) {
    return <Layout />;
  }
  return <Navigate to={routesName[404]} replace={true} />;
}

function Layout() {
  const { user, isEmployee } = useSelector(({ user }) => user);
  const { isMenuOverly, isPageLoaded } = useScreen();
  const [isMenuOpen, setIsMenuOpen] = useState(!isMenuOverly);
  const dispatch = useDispatch();

  const getOverviewCounts = () => {
    DashboardService.getOverviewCounts()
      .then((res) => {
        if (res?.success && res?.data) {
          dispatch(saveOverallCounts(res?.data));
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isPageLoaded) {
      getOverviewCounts();
    }
  }, [isPageLoaded]);

  return (
    <div className="flex flex-column bg-gray-200">
      {!isEmployee && (
        <div style={{ position: isMenuOverly ? 'absolute' : 'inherit' }}>
          <SlideMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>
      )}
      <div className="flex-1 overflow-y-auto h-screen">
        <MainContainer />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isMenuOverly && isMenuOpen}
          onClick={() => setIsMenuOpen(false)}
        />
      </div>
    </div>
  );
}

export default Component;
