import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { TextFieldWrapper } from '../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { routesName } from '../constants/routes-name';
import { Formik } from 'formik';
import { validationSchema } from '../constants/validationSchema';
import AuthService from '../services/auth.service';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import useNotification from '../hooks/useNotification';
import * as yup from 'yup';

function Component() {
  const navigate = useNavigate();
  const { successNonification, errorNonification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  if (!params.token) {
    navigate(routesName.login);
  }

  const register = (data) => {
    setIsLoading(true);

    AuthService.resettingPassword(data)
      .then((res) => {
        setIsLoading(false);
        if (res.success) {
          navigate(routesName.login);
          successNonification('Password reset successfully');
        } else {
          errorNonification(res?.message || 'Something went wrong');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        errorNonification(
          error?.response?.data?.message || 'Something went wrong'
        );
      });
  };

  return (
    <div className="mt-12">
      <Formik
        initialValues={{
          password: '',
          showPassword: false,
          confirmPassword: '',
          showConfirmPassword: false,
        }}
        validationSchema={yup.object().shape({
          password: validationSchema.password,
          confirmPassword: validationSchema.confirmPassword,
        })}
        onSubmit={(values, actions) => {
          try {
            register({
              password: values.password,
              confirm_password: values.confirmPassword,
              token: params.token,
            });
          } catch (error) {}
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="text-3xl font-bold text-center">
                Create New Password
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <label className="text-xs">
                  Password <span className="text-red-500">*</span>
                </label>
                <TextFieldWrapper
                  placeholder="Placeholder"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={!!touched.password && !!errors.password}
                  type={values.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setFieldValue('showPassword', !values.showPassword)
                        }
                      >
                        {values.showPassword ? (
                          <VisibilityOutlined fontSize="small" />
                        ) : (
                          <VisibilityOffOutlined fontSize="small" />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="flex flex-col gap-2">
                <label className="text-xs">
                  Confirm Password <span className="text-red-500">*</span>
                </label>
                <TextFieldWrapper
                  placeholder="Placeholder"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={!!touched.confirmPassword && !!errors.confirmPassword}
                  type={values.showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setFieldValue(
                            'showConfirmPassword',
                            !values.showConfirmPassword
                          )
                        }
                      >
                        {values.showConfirmPassword ? (
                          <VisibilityOutlined fontSize="small" />
                        ) : (
                          <VisibilityOffOutlined fontSize="small" />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <button
                onClick={handleSubmit}
                className="w-full text-md border border-white hover:bg-white text-white hover:text-black border border-1 border-[var(--blue)] text-center px-4 py-2 rounded-full font-bold flex items-center justify-center gap-1 cursor-pointer"
              >
                {!isLoading ? 'Update Password' : 'Updating Password'}
              </button>
            </Grid>

            <Grid item xs={12}>
              <div className="text-xs">
                <span>Remembered password?</span>{' '}
                <Link className="text-[var(--yellow)]" to={routesName.login}>
                  Login to account
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
}

export default Component;
