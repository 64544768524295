import React from 'react';
import TopHeaderMenu from './TopHeaderMenu';
import { Outlet } from 'react-router-dom';

function MainContainer() {
  return (
    <div className=" ">
      <TopHeaderMenu />
      <div className="md:px-12 px-4 md:py-4 py-3">
        <Outlet />
      </div>
    </div>
  );
}

export default MainContainer;
