import { Pagination, PaginationItem } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

const Component = (props) => {
  return (
    <StyledPagination
      {...props}
      sx={{
        '.Mui-selected': {
          backgroundColor: '#007bff',
          border: 'none',
          color: 'white',
        },
      }}
      variant="outlined"
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem slots={{ previous: '', next: '' }} {...item} />
      )}
    />
  );
};

export default Component;

const StyledPagination = styled(Pagination)({
  '& .Mui-selected': {
    backgroundColor: '#007bff',
    border: 'none',
    color: 'white',
  },
  '& .Mui-selected:hover': {
    backgroundColor: '#6358ed',
  },
  '& .MuiPagination-ul li:last-child button::before': {
    content: "'Next'",
    marginRight: '8px',
  },
  '& .MuiPaginationItem-root': {
    color: '#007bff',
  },
  '& .MuiPagination-ul li:first-child button::after': {
    content: "'Previous'",
    marginLeft: '8px',
  },
});
