import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlices';
import appDataReducer from './slices/appDataSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    appData: appDataReducer,
  },
});
