import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';

function Component({ transectionsData }) {
  const columns = [
    {
      field: 'title',
      sortable: false,
      headerName: 'PACKAGE TITLE',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'amount',
      sortable: false,
      headerName: 'AMOUNT',
      minWidth: 100,
      flex: 0.75,
    },
    {
      field: 'allow_no_of_cards',
      sortable: false,
      headerName: 'ALLOWED CARDS',
      minWidth: 150,
      flex: 0.75,
    },
    {
      field: 'allow_no_of_templates',
      sortable: false,
      headerName: 'ALLOWED TEMPLATES',
      minWidth: 200,
      flex: 0.75,
    },
    {
      field: 'no_of_designed_cards',
      sortable: false,
      headerName: 'DESIGNED CARDS',
      minWidth: 150,
      flex: 0.75,
    },
    {
      field: 'no_of_designed_templates',
      sortable: false,
      headerName: 'DESIGNED TEMPLATES',
      minWidth: 200,
      flex: 0.75,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'SUBSCRIPTION STATUS',
      minWidth: 200,
      flex: 0.75,
    },
    {
      field: 'start_date',
      sortable: false,
      headerName: 'SUBSCRIPTION DATE',
      minWidth: 200,
      flex: 0.75,
    },
  ];

  const rows =
    transectionsData &&
    Array.isArray(transectionsData) &&
    transectionsData.length > 0 &&
    transectionsData.map((item, index) => ({
      allow_no_of_cards: item.allow_no_of_cards,
      allow_no_of_templates: item.allow_no_of_templates,
      amount: item.amount,
      annually_price: item.annually_price,
      card_details_id: item.card_details_id,
      company_id: item.company_id,
      created_at: item.created_at,
      currency: item.currency,
      current_subscription: item.current_subscription,
      deleted_at: item.deleted_at,
      discount_type: item.discount_type,
      expiry_date: item.expiry_date,
      featured: item.featured,
      icon: item.icon,
      icon_logo: item.icon_logo,
      id: item.id,
      interval: item.interval,
      intervals: item.intervals,
      is_free_plan: item.is_free_plan,
      is_notification_sent: item.is_notification_sent,
      monthly_price: item.monthly_price,
      no_of_cards: item.no_of_cards,
      no_of_designed_cards: item.no_of_designed_cards,
      no_of_designed_templates: item.no_of_designed_templates,
      no_of_templates: item.no_of_templates,
      package_id: item.package_id,
      percent_discount: item.percent_discount,
      price_discount: item.price_discount,
      start_date: moment(item.start_date).format('DD-MM-YYYY'),
      status: item.status === 1 ? 'Active' : 'Inactive',
      stripe_token: item.stripe_token,
      title: item.title,
      updated_at: item.updated_at,
      user_id: item.user_id,
    }));

  return (
    <div>
      {rows && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaders div': {
              backgroundColor: 'inherit !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              backgroundColor: 'white !important',
            },
          }}
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      )}
    </div>
  );
}

export default Component;
