import React from 'react';
import { Check } from '@mui/icons-material';
import { Grid } from '@mui/material';

function AmazingFeatures() {
  return (
    <div className="py-12 flex flex-col md:gap-12 gap-6">
      <div className="text-center">
        <div className="text-3xl font-bold">Amazing Features</div>
        <div className="text-sm">
          Salient features that make Digital Business Card stands out 🤩
        </div>
      </div>

      <div className="  bg-white rounded-md md:px-10 px-3 md:py-12 py-6">
        <Grid container spacing={{ md: 6, xs: 4 }}>
          <Grid item lg={4} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <div className="flex items-center justify-center h-full">
                  <Check className="text-main" sx={{ fontSize: '4rem' }} />
                </div>
              </Grid>
              <Grid item md={9} xs={12}>
                <h5 className="text-lg font-bold">Card Template Customizer</h5>
                <p className="text-sm mt-3">
                  Card Template Customizer module allows you and your company to
                  customize card themes, card options and look &amp; feel in
                  real-time.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <div className="flex items-center justify-center h-full">
                  <Check className="text-main" sx={{ fontSize: '4rem' }} />
                </div>
              </Grid>
              <Grid item md={9} xs={12}>
                <h5 className="text-lg font-bold">Card/vCard generator</h5>
                <p className="text-sm mt-3">
                  Digital Business Card provides an online tool to generate your
                  preferred layout easily. For a better experience, open the
                  generator in a new tab.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <div className="flex items-center justify-center h-full">
                  <Check className="text-main" sx={{ fontSize: '4rem' }} />
                </div>
              </Grid>
              <Grid item md={9} xs={12}>
                <h5 className="text-lg font-bold">Live Demo</h5>
                <p className="text-sm mt-3">
                  A live demo is a set of pre-purchase checking that you can
                  operate and generate digital business cards and vCard only for
                  demo purposes.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <div className="flex items-center justify-center h-full">
                  <Check className="text-main" sx={{ fontSize: '4rem' }} />
                </div>
              </Grid>
              <Grid item md={9} xs={12}>
                <h5 className="text-lg font-bold">Eye-Catching Design</h5>
                <p className="text-sm mt-3">
                  Beautifully crafted, clean & Eye-Catching digital business
                  card designs.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <div className="flex items-center justify-center h-full">
                  <Check className="text-main" sx={{ fontSize: '4rem' }} />
                </div>
              </Grid>
              <Grid item md={9} xs={12}>
                <h5 className="text-lg font-bold">Easy Navigation</h5>
                <p className="text-sm mt-3">
                  Carefully crafted, clean, smart & easy dashboard navigation
                  with collapsible options.
                </p>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={4} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <div className="flex items-center justify-center h-full">
                  <Check className="text-main" sx={{ fontSize: '4rem' }} />
                </div>
              </Grid>
              <Grid item md={9} xs={12}>
                <h5 className="text-lg font-bold">Quick Search</h5>
                <p className="text-sm mt-3">
                  Easy & smart fuzzy search functionality which enables users to
                  search quickly.
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AmazingFeatures;
